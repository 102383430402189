import { useNavigate, useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AggregationType,
  NumberConditionType,
  OrganizationIdentityProvider,
  PlayerCondition,
  PlayerConditionDataSource,
  Property,
  PropertyCondition,
  RegistrationConditions,
  StringConditionType,
  Tournament,
} from "../../../../gql/graphql";
import { CreationForm } from "../../components/form/CreationForm";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { map } from "lodash";
import { AutoDataTable } from "../../components/AutoDataTable";
import {
  Accordion,
  ActionIcon,
  Alert,
  Button,
  List,
  Loader,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import {
  IconChartBar,
  IconInfoCircle,
  IconTrash,
  IconUsers,
} from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { constructCustomFieldsForm } from "../../components/form/CustomFieldsForm";

export const UpdateTournamentCustomFields = () => {
  const tournamentId = useParams().tournamentId;
  const { loading, data } = useQuery<{ tournament: Tournament }>(
    gql`
      query ($id: ID!) {
        tournament(id: $id) {
          configuration {
            customFields {
              name
              type
              property
              order
              unique
              required
              public
              editability
              visibility
            }
          }
        }
      }
    `,
    { variables: { id: tournamentId }, skip: !tournamentId },
  );
  const [updateTournament] = useMutation(gql`
    mutation (
      $id: ID!
      $configuration: UpdateTournamentConfigurationOrImportFromIdInput!
    ) {
      updateTournament(id: $id, input: { configuration: $configuration }) {
        id
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title="Update custom fields"
      schemaDefinition={{
        customFields: constructCustomFieldsForm({
          defaultValue: data?.tournament?.configuration?.customFields,
          label: "Custom fields",
        }),
      }}
      onSubmit={async (data) => {
        await updateTournament({
          variables: {
            id: tournamentId,
            configuration: {
              configuration: { customFields: data.customFields },
            },
          },
        }).then((data) => {
          if (data?.errors && data.errors.length > 0) {
            throw new Error(data.errors[0].message);
          }
        });
        notifications.show({
          title: "Tournament updated",
          message: "The tournament has been updated",
          color: "green",
          autoClose: 3000,
        });
      }}
    />
  );
};

export const UpdateTournamentRegistrationRules = () => {
  const tournamentId = useParams().tournamentId;
  const { loading: idpLoading, data: idpData } = useQuery<{
    identityProviders: OrganizationIdentityProvider[];
  }>(gql`
    query {
      identityProviders {
        id
        name
        configuration {
          ... on OAuthClientConfiguration {
            dataRetrievers {
              mappingConfiguration {
                mappings {
                  mappedTo
                }
              }
            }
          }
        }
      }
    }
  `);
  const { loading, data } = useQuery<{ tournament: Tournament }>(
    gql`
      query ($id: ID!) {
        tournament(id: $id) {
          configuration {
            registrationConditions {
              memberConditions {
                condition {
                  numericCondition {
                    conditionType
                    value
                  }
                  propertyCondition
                  stringCondition {
                    value
                    conditionType
                  }
                  property
                }
                propertySource
                propertySourceId
              }
              teamConditions {
                stringCondition {
                  value
                  conditionType
                }
                propertyCondition
                property
                numericCondition {
                  value
                  conditionType
                  aggregationType
                  propertySource
                  propertySourceId
                }
              }
            }
          }
        }
      }
    `,
    { variables: { id: tournamentId }, skip: !tournamentId },
  );
  const [updateTournament] = useMutation(gql`
    mutation (
      $id: ID!
      $configuration: UpdateTournamentConfigurationOrImportFromIdInput!
    ) {
      updateTournament(id: $id, input: { configuration: $configuration }) {
        id
      }
    }
  `);

  if (loading || idpLoading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title="Update registration rules"
      schemaDefinition={{
        registrationConditions: {
          type: "DYNAMIC",
          label: "Registration conditions",
          yupConfig: yup.object().shape({
            memberConditions: yup.array().of(
              yup.object().shape({
                condition: yup.object().shape({
                  numericCondition: yup
                    .object()
                    .shape({
                      conditionType: yup
                        .string()
                        .oneOf(Object.values(NumberConditionType))
                        .required(),
                      value: yup.number().required(),
                    })
                    .default(undefined),
                  propertyCondition: yup
                    .string()
                    .oneOf(Object.values(PropertyCondition))
                    .required(),
                  stringCondition: yup
                    .object()
                    .shape({
                      value: yup.string().default(""),
                      conditionType: yup
                        .string()
                        .oneOf(Object.values(StringConditionType))
                        .required(),
                    })
                    .default(undefined),
                  property: yup.string().required(),
                }),
                propertySource: yup
                  .string()
                  .oneOf(Object.values(PlayerConditionDataSource))
                  .required(),
                propertySourceId: yup.string().required(),
              }),
            ),
            teamConditions: yup.array().of(
              yup.object().shape({
                condition: yup.object().shape({
                  numericCondition: yup
                    .object()
                    .shape({
                      conditionType: yup
                        .string()
                        .oneOf(Object.values(NumberConditionType))
                        .required(),
                      value: yup.number().required(),
                      aggregationType: yup
                        .string()
                        .oneOf(Object.values(AggregationType))
                        .required(),
                      propertySource: yup
                        .string()
                        .oneOf(Object.values(PlayerConditionDataSource))
                        .required(),
                      propertySourceId: yup.string().required(),
                    })
                    .default(undefined),
                  stringCondition: yup
                    .object()
                    .shape({
                      value: yup.string().default(""),
                      conditionType: yup
                        .string()
                        .oneOf(Object.values(StringConditionType))
                        .required(),
                    })
                    .default(undefined),
                  propertyCondition: yup.object().shape({
                    conditionType: yup
                      .string()
                      .oneOf(Object.values(PropertyCondition))
                      .required(),
                  }),
                  property: yup.string().required(),
                }),
              }),
            ),
          }),
          defaultValue: data?.tournament?.configuration?.registrationConditions,

          element: (onChange) => {
            const [registrationConditions, setData] =
              useState<RegistrationConditions>(
                Object.assign(
                  {
                    memberConditions: [],
                    teamConditions: [],
                  },
                  data?.tournament.configuration?.registrationConditions
                    ? {
                        memberConditions:
                          data?.tournament.configuration?.registrationConditions
                            .memberConditions,
                        teamConditions:
                          data?.tournament.configuration?.registrationConditions
                            .teamConditions,
                      }
                    : {},
                ),
              );

            useEffect(() => {
              onChange(registrationConditions);
            }, [registrationConditions]);

            return (
              <>
                <Accordion>
                  <Accordion.Item value={"data"}>
                    <Accordion.Control>Member conditions</Accordion.Control>
                    <Accordion.Panel>
                      <Button
                        onClick={() => {
                          setData({
                            ...registrationConditions,
                            memberConditions: ([] as PlayerCondition[]).concat(
                              registrationConditions.memberConditions,
                              [
                                {
                                  condition: {
                                    property: "",
                                    propertyCondition: PropertyCondition.Exists,
                                  },
                                  propertySource:
                                    PlayerConditionDataSource.Player,
                                },
                              ],
                            ),
                          });
                        }}
                      >
                        Add member condition
                      </Button>
                      <DataTable
                        columns={[
                          {
                            accessor: "",
                            title: "Property",
                            render: (item: PlayerCondition, idx) =>
                              item.propertySource ===
                              PlayerConditionDataSource.Player ? (
                                <TextInput
                                  value={item.condition.property}
                                  placeholder="Property identifier"
                                  onChange={(e) => {
                                    setData({
                                      ...registrationConditions,
                                      memberConditions:
                                        registrationConditions.memberConditions.map(
                                          (c, i) =>
                                            i === idx
                                              ? {
                                                  ...c,
                                                  condition: {
                                                    ...c.condition,
                                                    property: e.target.value,
                                                  },
                                                }
                                              : c,
                                        ),
                                    });
                                  }}
                                />
                              ) : (
                                <Select
                                  data={
                                    idpData?.identityProviders
                                      .find(
                                        (idp) =>
                                          idp.id === item.propertySourceId,
                                      )
                                      ?.configuration?.dataRetrievers.flatMap(
                                        (dr) =>
                                          dr.mappingConfiguration.mappings.map(
                                            (m) => m.mappedTo,
                                          ),
                                      ) ?? []
                                  }
                                  value={item.condition.property}
                                  onChange={(value) => {
                                    if (!value) return;
                                    setData({
                                      ...registrationConditions,
                                      memberConditions:
                                        registrationConditions.memberConditions.map(
                                          (c, i) =>
                                            i === idx
                                              ? {
                                                  ...c,
                                                  condition: {
                                                    ...c.condition,
                                                    property: value,
                                                  },
                                                }
                                              : c,
                                        ),
                                    });
                                  }}
                                />
                              ),
                          },
                          {
                            accessor: "",
                            title: "Property Source",
                            render: (item, idx) => (
                              <Select
                                value={
                                  item.propertySourceId ?? item.propertySource
                                }
                                onChange={(value) => {
                                  setData({
                                    ...registrationConditions,
                                    memberConditions:
                                      registrationConditions.memberConditions.map(
                                        (c, i) =>
                                          i === idx
                                            ? {
                                                ...c,
                                                propertySource:
                                                  value === "PLAYER"
                                                    ? PlayerConditionDataSource.Player
                                                    : PlayerConditionDataSource.IdentityProvider,
                                                propertySourceId:
                                                  value === "PLAYER"
                                                    ? undefined
                                                    : value,
                                              }
                                            : c,
                                      ),
                                  });
                                }}
                                data={[
                                  {
                                    label: "Player",
                                    value: "PLAYER",
                                  },
                                ].concat(
                                  idpData?.identityProviders.map((idp) => ({
                                    label: idp.name,
                                    value: idp.id,
                                  })) ?? [],
                                )}
                              />
                            ),
                          },
                          {
                            accessor: "",
                            title: "Configuration",
                            render: (condition, index) => (
                              <>
                                Property state:
                                <br />
                                <Select
                                  value={condition.condition.propertyCondition}
                                  onChange={(value) => {
                                    setData({
                                      ...registrationConditions,
                                      memberConditions:
                                        registrationConditions.memberConditions.map(
                                          (c, i) =>
                                            i === index
                                              ? {
                                                  ...c,
                                                  condition: {
                                                    ...c.condition,
                                                    propertyCondition:
                                                      value === "EXISTS"
                                                        ? PropertyCondition.Exists
                                                        : PropertyCondition.DontExist,
                                                  },
                                                }
                                              : c,
                                        ),
                                    });
                                  }}
                                  data={map(PropertyCondition, (v, k) => ({
                                    label: k,
                                    value: v,
                                  }))}
                                />
                                <br />
                                <br />
                                {condition.condition.propertyCondition ===
                                  PropertyCondition.Exists && (
                                  <>
                                    Condition type:
                                    <br />
                                    <select
                                      value={
                                        condition.condition.numericCondition
                                          ?.conditionType
                                          ? `NUM_${condition.condition.numericCondition.conditionType}`
                                          : condition.condition.stringCondition
                                              ?.conditionType
                                          ? `STR_${condition.condition.stringCondition.conditionType}`
                                          : ""
                                      }
                                      onChange={(e) => {
                                        setData({
                                          ...registrationConditions,
                                          memberConditions:
                                            registrationConditions.memberConditions.map(
                                              (c, i) =>
                                                i === index
                                                  ? {
                                                      ...c,
                                                      condition: {
                                                        ...c.condition,
                                                        numericCondition:
                                                          e.target.value.startsWith(
                                                            "NUM_",
                                                          )
                                                            ? {
                                                                conditionType:
                                                                  e.target.value.substring(
                                                                    4,
                                                                  ) as NumberConditionType,
                                                                value: 0,
                                                              }
                                                            : undefined,
                                                        stringCondition:
                                                          e.target.value.startsWith(
                                                            "STR_",
                                                          )
                                                            ? {
                                                                conditionType:
                                                                  e.target.value.substring(
                                                                    4,
                                                                  ) as StringConditionType,
                                                                value: "",
                                                              }
                                                            : undefined,
                                                      },
                                                    }
                                                  : c,
                                            ),
                                        });
                                      }}
                                    >
                                      <option disabled>
                                        Number conditions
                                      </option>
                                      {map(NumberConditionType, (v, k) => (
                                        <option value={"NUM_" + v}>
                                          {
                                            {
                                              Bt: "Bigger than",
                                              Bte: "Bigger than or equal",
                                              Eq: "Equal",
                                              Lt: "Less than",
                                              Lte: "Less than or equal",
                                              Neq: "Not equal",
                                            }[k]
                                          }
                                        </option>
                                      ))}
                                      <option disabled>
                                        String conditions
                                      </option>
                                      {map(StringConditionType, (v, k) => (
                                        <option value={"STR_" + v}>
                                          {
                                            {
                                              Neq: "Not equal",
                                              Eq: "Equal",
                                            }[k]
                                          }
                                        </option>
                                      ))}
                                    </select>
                                    <br />
                                    Value: <br />
                                    <input
                                      value={
                                        condition.condition.numericCondition
                                          ?.value ??
                                        condition.condition.stringCondition
                                          ?.value
                                      }
                                      type={
                                        condition.condition.stringCondition
                                          ? "text"
                                          : "number"
                                      }
                                      placeholder="Value to check against"
                                      onChange={(e) => {
                                        setData({
                                          ...registrationConditions,
                                          memberConditions:
                                            registrationConditions.memberConditions.map(
                                              (c, i) =>
                                                i === index
                                                  ? {
                                                      ...c,
                                                      condition: {
                                                        ...c.condition,
                                                        numericCondition: c
                                                          .condition
                                                          .numericCondition
                                                          ? {
                                                              ...c.condition
                                                                .numericCondition,
                                                              value: parseFloat(
                                                                e.target.value,
                                                              ),
                                                            }
                                                          : undefined,
                                                        stringCondition: c
                                                          .condition
                                                          .stringCondition
                                                          ? {
                                                              ...c.condition
                                                                .stringCondition,
                                                              value:
                                                                e.target.value,
                                                            }
                                                          : undefined,
                                                      },
                                                    }
                                                  : c,
                                            ),
                                        });
                                      }}
                                    />
                                  </>
                                )}
                              </>
                            ),
                          },
                          {
                            accessor: "",
                            title: "",
                            render: (_, idx) => (
                              <ActionIcon
                                onClick={() => {
                                  if (!confirm("Are you sure?")) return;
                                  setData({
                                    ...registrationConditions,
                                    memberConditions:
                                      registrationConditions.memberConditions.filter(
                                        (_, i) => i !== idx,
                                      ),
                                  });
                                }}
                              >
                                <IconTrash />
                              </ActionIcon>
                            ),
                          },
                        ]}
                        records={registrationConditions.memberConditions}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item value={"default"}>
                    <Accordion.Control>Team conditions</Accordion.Control>
                    <Accordion.Panel>
                      <Button
                        onClick={() => {
                          setData({
                            ...registrationConditions,
                            teamConditions: [
                              ...registrationConditions.teamConditions,
                              {
                                property: "",
                                propertyCondition: PropertyCondition.Exists,
                              },
                            ],
                          });
                        }}
                      >
                        Add team condition
                      </Button>
                      <DataTable
                        columns={[
                          {
                            accessor: "",
                            title: "Property",
                            render: (condition, index) => (
                              <input
                                value={condition.property}
                                onChange={(e) => {
                                  setData({
                                    ...registrationConditions,
                                    teamConditions:
                                      registrationConditions.teamConditions.map(
                                        (c, i) =>
                                          i === index
                                            ? {
                                                ...c,
                                                property: e.target.value,
                                              }
                                            : c,
                                      ),
                                  });
                                }}
                                placeholder="Property name"
                              />
                            ),
                          },
                          {
                            accessor: "",
                            title: "Configuraiton",
                            render: (condition, index) => (
                              <>
                                Condition type:
                                <br />
                                <select
                                  value={condition.propertyCondition}
                                  onChange={(e) => {
                                    setData({
                                      ...registrationConditions,
                                      teamConditions:
                                        registrationConditions.teamConditions.map(
                                          (c, i) =>
                                            i === index
                                              ? {
                                                  ...c,
                                                  propertyCondition:
                                                    e.target.value === "EXISTS"
                                                      ? PropertyCondition.Exists
                                                      : PropertyCondition.DontExist,
                                                }
                                              : c,
                                        ),
                                    });
                                  }}
                                >
                                  {map(PropertyCondition, (v, k) => (
                                    <option value={v}>{k}</option>
                                  ))}
                                </select>
                                {condition.propertyCondition ===
                                  PropertyCondition.Exists && (
                                  <>
                                    <br />
                                    Condition:
                                    <br />
                                    <select
                                      value={
                                        condition.numericCondition
                                          ?.conditionType ??
                                        condition.stringCondition?.conditionType
                                      }
                                      onChange={(e) => {
                                        setData({
                                          ...registrationConditions,
                                          teamConditions:
                                            registrationConditions.teamConditions.map(
                                              (c, i) =>
                                                i === index
                                                  ? {
                                                      ...c,
                                                      numericCondition:
                                                        e.target.value.startsWith(
                                                          "NUM_",
                                                        )
                                                          ? {
                                                              conditionType:
                                                                e.target.value.substring(
                                                                  4,
                                                                ) as NumberConditionType,
                                                              value: 0,
                                                            }
                                                          : undefined,
                                                      stringCondition:
                                                        e.target.value.startsWith(
                                                          "STR_",
                                                        )
                                                          ? {
                                                              conditionType:
                                                                e.target.value.substring(
                                                                  4,
                                                                ) as StringConditionType,
                                                              value: "",
                                                            }
                                                          : undefined,
                                                    }
                                                  : c,
                                            ),
                                        });
                                      }}
                                    >
                                      <option disabled>
                                        Number conditions
                                      </option>
                                      {map(NumberConditionType, (v, k) => (
                                        <option value={"NUM_" + v}>{k}</option>
                                      ))}
                                      <option disabled>
                                        String conditions
                                      </option>
                                      {map(StringConditionType, (v, k) => (
                                        <option value={"STR_" + v}>{k}</option>
                                      ))}
                                    </select>
                                    <br />
                                    {condition.numericCondition && (
                                      <>
                                        Aggregation type:
                                        <br />
                                        <select
                                          value={
                                            condition.numericCondition
                                              ?.aggregationType ?? "NONE"
                                          }
                                          onChange={(e) => {
                                            setData({
                                              ...registrationConditions,
                                              teamConditions:
                                                registrationConditions.teamConditions.map(
                                                  (c, i) =>
                                                    i === index
                                                      ? {
                                                          ...c,
                                                          numericCondition:
                                                            c.numericCondition
                                                              ? {
                                                                  ...c.numericCondition,
                                                                  aggregationType:
                                                                    e.target
                                                                      .value ===
                                                                    "NONE"
                                                                      ? undefined
                                                                      : (e
                                                                          .target
                                                                          .value as
                                                                          | AggregationType
                                                                          | undefined),
                                                                }
                                                              : undefined,
                                                          stringCondition:
                                                            c.stringCondition
                                                              ? {
                                                                  ...c.stringCondition,
                                                                  aggregationType:
                                                                    e.target
                                                                      .value ===
                                                                    "NONE"
                                                                      ? undefined
                                                                      : (e
                                                                          .target
                                                                          .value as
                                                                          | AggregationType
                                                                          | undefined),
                                                                }
                                                              : undefined,
                                                        }
                                                      : c,
                                                ),
                                            });
                                          }}
                                        >
                                          <option value="NONE">None</option>
                                          {map(AggregationType, (v, k) => (
                                            <option value={v}>{k}</option>
                                          ))}
                                        </select>
                                        {condition.numericCondition
                                          .aggregationType && (
                                          <>
                                            <br />
                                            Aggregation property source:
                                            <br />
                                            <select
                                              value={
                                                condition.numericCondition
                                                  ?.propertySourceId ??
                                                condition.numericCondition
                                                  ?.propertySource ??
                                                "PLAYER"
                                              }
                                              onChange={(e) => {
                                                setData({
                                                  ...registrationConditions,
                                                  teamConditions:
                                                    registrationConditions.teamConditions.map(
                                                      (c, i) =>
                                                        i === index
                                                          ? {
                                                              ...c,
                                                              numericCondition:
                                                                c.numericCondition
                                                                  ? {
                                                                      ...c.numericCondition,
                                                                      propertySource:
                                                                        e.target
                                                                          .value ===
                                                                        "PLAYER"
                                                                          ? PlayerConditionDataSource.Player
                                                                          : PlayerConditionDataSource.IdentityProvider,
                                                                      propertySourceId:
                                                                        e.target
                                                                          .value ===
                                                                        "PLAYER"
                                                                          ? undefined
                                                                          : e
                                                                              .target
                                                                              .value,
                                                                    }
                                                                  : undefined,
                                                            }
                                                          : c,
                                                    ),
                                                });
                                              }}
                                            >
                                              <option value="PLAYER">
                                                Player
                                              </option>
                                              {idpData?.identityProviders.map(
                                                (idp) => (
                                                  <option value={idp.id}>
                                                    IDP: {idp.name}
                                                  </option>
                                                ),
                                              )}
                                            </select>
                                          </>
                                        )}
                                      </>
                                    )}
                                    <br />
                                    Value: <br />
                                    <input
                                      value={
                                        condition.numericCondition?.value ??
                                        condition.stringCondition?.value
                                      }
                                      type={
                                        condition.stringCondition
                                          ? "text"
                                          : "number"
                                      }
                                      onChange={(e) => {
                                        setData({
                                          ...registrationConditions,
                                          teamConditions:
                                            registrationConditions.teamConditions.map(
                                              (c, i) =>
                                                i === index
                                                  ? {
                                                      ...c,
                                                      numericCondition:
                                                        c.numericCondition
                                                          ? {
                                                              ...c.numericCondition,
                                                              value: parseFloat(
                                                                e.target.value,
                                                              ),
                                                            }
                                                          : undefined,
                                                      stringCondition:
                                                        c.stringCondition
                                                          ? {
                                                              ...c.stringCondition,
                                                              value:
                                                                e.target.value,
                                                            }
                                                          : undefined,
                                                    }
                                                  : c,
                                            ),
                                        });
                                      }}
                                      placeholder="Value to check against"
                                    />
                                  </>
                                )}
                              </>
                            ),
                          },
                          {
                            title: "",
                            accessor: "",
                            render: (condition, index) => (
                              <ActionIcon
                                onClick={() => {
                                  if (!confirm("Are you sure?")) return;
                                  setData({
                                    ...registrationConditions,
                                    teamConditions:
                                      registrationConditions.teamConditions.filter(
                                        (_, i) => i !== index,
                                      ),
                                  });
                                }}
                              >
                                <IconTrash />
                              </ActionIcon>
                            ),
                          },
                        ]}
                        records={registrationConditions.teamConditions}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </>
            );
          },
        },
      }}
      onSubmit={async (values) => {
        await updateTournament({
          variables: {
            id: tournamentId,
            configuration: {
              configuration: {
                registrationConditions: values.registrationConditions,
              },
            },
          },
        }).then((data) => {
          if (data?.errors && data.errors.length > 0) {
            throw new Error(data.errors[0].message);
          }
        });
        notifications.show({
          title: "Tournament updated",
          message: "The tournament has been updated",
          color: "green",
          autoClose: 3000,
        });
      }}
    />
  );
};

export const UpdateTournamentRegistrations = () => {
  const tournamentId = useParams().tournamentId;
  const { loading, data } = useQuery<{ tournament: Tournament }>(
    gql`
      query ($id: ID!) {
        tournament(id: $id) {
          startRegistrationsAt
          endRegistrationsAt
          configuration {
            teamMinSize
            teamMaxSize
            teamsCount
            teamStatusAfterRegistration
          }
        }
      }
    `,
    { variables: { id: tournamentId }, skip: !tournamentId },
  );
  const [updateTournament] = useMutation(gql`
    mutation (
      $id: ID!
      $configuration: UpdateTournamentConfigurationOrImportFromIdInput!
      $startRegistrationsAt: DateTime!
      $endRegistrationsAt: DateTime!
    ) {
      updateTournament(
        id: $id
        input: {
          startRegistrationsAt: $startRegistrationsAt
          endRegistrationsAt: $endRegistrationsAt
          configuration: $configuration
        }
      ) {
        id
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title="Update tournament registrations settings"
      schemaDefinition={{
        startRegistrationsAt: {
          type: "INPUT",
          label: "Registrations open at",
          yupConfig: yup.date().required(),
          inputType: "date",
          placeholder: "Enter the tournament registrations open date",
          defaultValue: data?.tournament?.startRegistrationsAt,
        },
        endRegistrationsAt: {
          type: "INPUT",
          label: "Registrations closes at",
          yupConfig: yup.date().required(),
          inputType: "date",
          placeholder: "Enter the tournament registrations ending date",
          defaultValue: data?.tournament?.endRegistrationsAt,
        },
        minTeamSize: {
          type: "INPUT",
          label: "Min team size",
          yupConfig: yup.number().required(),
          inputType: "number",
          placeholder: "Enter the tournament min team size",
          defaultValue: data?.tournament?.configuration?.teamMinSize,
        },
        maxTeamSize: {
          type: "INPUT",
          label: "Max team size",
          yupConfig: yup.number().required(),
          inputType: "number",
          placeholder: "Enter the tournament max team size",
          defaultValue: data?.tournament?.configuration?.teamMaxSize,
        },
        teamsCount: {
          type: "INPUT",
          label: "Teams count",
          yupConfig: yup.number().required(),
          inputType: "number",
          placeholder: "Enter the tournament teams count",
          defaultValue: data?.tournament?.configuration?.teamsCount,
        },
        teamStatusAfterRegistration: {
          type: "SELECT",
          label: "Team status after registration",
          yupConfig: yup.string().required(),
          options: [
            { label: "Registered", value: "REGISTERED" },
            {
              label: "Awaiting for presence confirmation",
              value: "AWAITING_FOR_PRESENCE_CONFIRMATION",
            },
          ],
          defaultValue:
            data?.tournament?.configuration?.teamStatusAfterRegistration,
        },
      }}
      onSubmit={async (values) => {
        await updateTournament({
          variables: {
            id: tournamentId,
            startRegistrationsAt: values.startRegistrationsAt,
            endRegistrationsAt: values.endRegistrationsAt,
            configuration: {
              configuration: {
                teamMinSize: values.minTeamSize,
                teamMaxSize: values.maxTeamSize,
                teamsCount: values.teamsCount,
                teamStatusAfterRegistration: values.teamStatusAfterRegistration,
              },
            },
          },
        }).then((data) => {
          if (data?.errors && data.errors.length > 0) {
            throw new Error(data.errors[0].message);
          }
        });
        notifications.show({
          title: "Tournament updated",
          message: "The tournament has been updated",
          color: "green",
          autoClose: 3000,
        });
      }}
    />
  );
};

export const UpdateTournament = () => {
  const navigate = useNavigate();
  let tournamentId = useParams().tournamentId;
  const { loading, data } = useQuery<{ tournament: Tournament }>(
    gql`
      query ($id: ID!) {
        tournament(id: $id) {
          id
          title
          description
          startAt
          endAt
          visibleAt
        }
      }
    `,
    { variables: { id: tournamentId }, skip: !tournamentId },
  );
  const [createTournament] = useMutation<{
    createTournament: { id: string };
  }>(gql`
    mutation (
      $title: String!
      $description: String!
      $startAt: DateTime!
      $endAt: DateTime!
    ) {
      createTournament(
        input: {
          title: $title
          description: $description
          startAt: $startAt
          endAt: $endAt
        }
      ) {
        id
      }
    }
  `);
  const [updateTournament] = useMutation(gql`
    mutation (
      $id: ID!
      $title: String!
      $description: String!
      $startAt: DateTime!
      $endAt: DateTime!
      $visibleAt: DateTime
    ) {
      updateTournament(
        id: $id
        input: {
          title: $title
          description: $description
          startAt: $startAt
          endAt: $endAt
          visibleAt: $visibleAt
        }
      ) {
        id
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title={tournamentId ? "Update tournament" : "Create tournament"}
      schemaDefinition={{
        title: {
          type: "INPUT",
          label: "Title",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.tournament?.title,
          placeholder: "Enter the tournament title",
        },
        description: {
          type: "RICH_TEXT_EDITOR",
          label: "Description contents",
          yupConfig: yup.string().required(),
          defaultValue: data?.tournament?.description,
        },
        startAt: {
          type: "INPUT",
          label: "Start at",
          yupConfig: yup.date().required(),
          inputType: "date",
          placeholder: "Enter the tournament start date",
          defaultValue: data?.tournament?.startAt,
        },
        endAt: {
          type: "INPUT",
          label: "End at",
          yupConfig: yup.date().required(),
          inputType: "date",
          placeholder: "Enter the tournament end date",
          defaultValue: data?.tournament?.endAt,
        },
        ...(tournamentId && {
          visibleAt: {
            type: "INPUT",
            label: "Visible at",
            yupConfig: yup.date(),
            inputType: "date",
            placeholder:
              "Enter the date when the tournament will be visible to the public",
            defaultValue: data?.tournament?.visibleAt,
          },
        }),
      }}
      onSubmit={async (values) => {
        if (tournamentId) {
          await updateTournament({
            variables: {
              id: tournamentId,
              title: values.title,
              description: values.description,
              startAt: values.startAt,
              endAt: values.endAt,
              visibleAt: values.visibleAt,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
          notifications.show({
            title: "Tournament updated",
            message: "The tournament has been updated",
            color: "green",
            autoClose: 3000,
          });
        } else {
          tournamentId = await createTournament({
            variables: {
              title: values.title,
              description: values.description,
              startAt: values.startAt,
              endAt: values.endAt,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
            return data.data?.createTournament.id;
          });
          navigate("/tournaments/" + tournamentId);
        }
      }}
    />
  );
};

export const Tournaments = () => {
  const navigate = useNavigate();
  // TODO Add filters

  return (
    <AutoDataTable
      itemPath=".."
      deleteMutation={gql`
        mutation ($id: ID!) {
          deleteTournament(id: $id)
        }
      `}
      query={gql`
        query ($cursor: String, $count: Float) {
          tournaments(
            page: { first: $count, after: $cursor }
            query: {
              orderBy: REGISTRATIONS_START_AT
              orderDirection: ASC
              status: ALL
              showNotVisible: true
            }
          ) {
            nodes {
              id
              title
              startAt
              startRegistrationsAt
            }
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      `}
      columns={[
        {
          accessor: "title",
          title: "Title",
        },
        {
          accessor: "startAt",
          title: "Start date",
          description: "The date when the tournament starts",
          rawElement: (tournament: Tournament) => (
            <>{new Date(tournament.startAt).toLocaleString()}</>
          ),
        },
        {
          accessor: "startRegistrationsAt",
          title: "Open registrations date",
          description: "The date when the registrations will be open",
          rawElement: (tournament: Tournament) => (
            <>{new Date(tournament.startRegistrationsAt).toLocaleString()}</>
          ),
        },
        {
          accessor: "",
          title: "",
          rawElement: (tournament) => (
            <>
              <Tooltip label="Manage teams">
                <ActionIcon
                  onClick={() => navigate("../" + tournament.id + "/teams")}
                >
                  <IconUsers />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Edit tournament steps">
                <ActionIcon
                  onClick={() => navigate("../" + tournament.id + "/steps")}
                >
                  <IconChartBar />
                </ActionIcon>
              </Tooltip>
            </>
          ),
        },
      ]}
    />
  );
};

export const TournamentHome = () => {
  return (
    <Stack>
      <Title>Tournaments</Title>
      <Text>
        The tournament feature allows anyone to create and manage Tournaments,
        from registration process to scoring and tournament Tree systems.
      </Text>
      <Text>
        While configuring their tournaments, users are able to set the
        registrations start and end date, the tournament's start and end date,
        and more configurations depending on their needs (teams minimum and
        maximum size to register, registrations rules, what kind of tournament
        it is, is it public, ...).
        <br />
        After setting these configurations, the tournament can begin.
        <br />
        First, the tournament will open registrations when the start
        registrations date is reached.
        <br />
        Registrations will stay open until the end registrations date is
        reached. At this point, the tournament will be in a registration closed
        state.
        <br />
        Then, when the start tournament date is reached, the tournament will
        begin by setting the first step as active.
        <br />
        <br />
        <Alert icon={<IconInfoCircle />} color="indigo" variant="outline">
          If automation settings are set, the step will be automatically
          generated and seeded if needed.
        </Alert>
        <br />
        Tournament creators can also choose to generate and seed their steps
        themselves.
        <br />
        Tournament admins can now update game matches to set the current score
        for each team participating in the tournament.
        <br />
        When all steps groups have finished their matches, the step will be
        closed and the next step is opened.
        <br />
        <br />
        <Alert icon={<IconInfoCircle />} color="indigo" variant="outline">
          By using automation rules, the Tournament creator, can set options to
          automatically generate and seed the next step with the current winning
          (or loosing) teams.
        </Alert>
      </Text>
      <Text>
        <Title order={2}>Player Registrations</Title>
        The player registrations process starts when reaching the start
        registrations date.
        <br />
        At this point, any player will be able to create his team to register to
        the Tournament.
        <br />
        If it has a valid team (depending on the tournament configurations) and
        all the team members met the registration rules, the team will be
        automatically set to either awaiting for manual validation or validated
        as participant to the tournament (depending on the tournament
        configuration).
        <br />
        <br />
        <Alert icon={<IconInfoCircle />} color="indigo" variant="outline">
          The manual validation state requires a manual validation of the team
          by a Tournament admin.
        </Alert>
      </Text>
      <Text>
        <Title order={2}>Registration rules</Title>
        Registration rules are a specific configuration part that can be enabled
        to provide very flexible rules that users must meet to register to the
        tournament.
        <br />
        The requirements can range from player profile properties values set on
        a player profile to having a specific external account linked to their
        player profile.
        <br />
        For example, it is possible to create a registration rule that requires
        that the player has a League Of Legends account linked with, at least, a
        Diamond rank and/or a Twitch channel with a follower count of 1,000.
        <br />
        The goal here is to provide the most flexible way to easily set
        registration requirements without having to validate each teams
        manually.
      </Text>
      <Text>
        <Title order={2}>Steps</Title>
        A Step represents each steps of a tournament.
        <br />
        For example, you can create a Round robin step for your qualifications,
        then create an Elimination Tree step for your Playoffs.
        <br />
        Steps can be linked together using automation or can be manually seeded.
        <br />
        It is important to remember that each Step is independent of each other
        and only automation rules, or manual seeding will result in updating the
        Step participating teams.
        <br />
        There are currently 4 steps types available:
        <List>
          <List.Item>Round-Robin groups</List.Item>
          <List.Item>Scoring</List.Item>
          <List.Item>
            FFA Tournament Trees (Single and double elimination)
          </List.Item>
          <List.Item>Custom Custom steps</List.Item>
        </List>
        Custom steps allows you to create your own custom configuration for a
        step.
        <br />
        This means that you will be able to create your own games and matches,
        and provide automation steps to define how winning and losing teams are
        managed.
        <br />
        This is longer to create and harder to maintain, but allows you to
        completely decide how your step is executed.
      </Text>
      <Text>
        <Title order={2}>Groups</Title>
        Group defines multiple team groups that will be able to match against
        each others.
        <br />
        For example, in a Round-Robin system, it will define each pool of teams
        that will match against each others.
        <br />
        In a Tree setting, each group represent a tournament tree, for example,
        for a double elimination tree, there are two distinct groups, the
        initial tree and the loosing tree, players that loses games are
        redirected through the loosing tree.
        <br />
        Automation rules can be added to groups to define what to do if a team
        loses or win its games.
        <br />
        Groups also defines how many teams are declared winners, and if they
        need to be seeded to another group/step.
        <br />
        The same can be made with loosing teams.
      </Text>
      <Text>
        <Title order={2}>Rounds</Title>
        Each round represents an internal step for groups.
        <br />
        It is typically the next games set for a team or defines an important
        moment during the step (for example, switching to another game).
        <br />
        Typically, Rounds are used to generate the tournament tree, by defining
        where are currently the players in the Group (semi finals, finals, ...).
      </Text>
      <Text>
        <Title order={2}>Games</Title>
        A game is composed of multiple Matches and defines which teams are
        getting against each others.
        <br />
        The game is typically defined by the maximum number of winning matches
        needed to define a winning team.
        <br />
        When the threshold of wined matches is reached, and the number of
        winning teams required to end the game is reached, the game is set as
        ENDED and teams are transferred to other games depending on their
        winning status.
      </Text>
      <Text>
        <Title order={2}>Matches</Title>
        A match represents the result of a in-game confrontation.
        <br />
        It typically contains the current teams scores and some variables
        (either to help for score calculations, or to show on the tournament
        page, ex: kill count, golds won, ...).
        <br />A match is considered ended when all the teams that are
        participating in this match have a match status set (either winner or
        loser).
      </Text>
    </Stack>
  );
};
