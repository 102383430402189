import { DocumentNode, useLazyQuery } from "@apollo/client";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type { LazyQueryHookOptions } from "@apollo/client/react/types/types";
import { useEffect, useState } from "react";
import { Maybe, PageInfo, ResponsePageInfo, Scalars } from "../gql/graphql";
import { NoInfer } from "react-spring";

export const usePaginatedLoadAll = <
  TData extends {
    edges: Array<TNodeChild>;
    nodes: Array<TNodeChild>;
    pageInfo: ResponsePageInfo;
    totalCount?: Maybe<Scalars["Float"]["output"]>;
  },
  TVariables extends {
    page: Maybe<PageInfo>;
  },
  TNodeChild = any,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<
    NoInfer<TData>,
    NoInfer<Omit<TVariables, "page">>
  > & { skip?: boolean; onResults?: (results: TNodeChild[]) => void },
) => {
  const [loading, setLoading] = useState(!options?.skip);
  const [results, setResults] = useState<TNodeChild[]>([]);
  const [fetchResults] = useLazyQuery(query, options as any);

  const fetchAll = async (variables?: NoInfer<Omit<TVariables, "page">>) => {
    const elemName = (query.definitions[0] as any).selectionSet.selections[0]
      .name.value;
    setLoading(true);
    let hasNextPage: boolean | undefined = true;
    let cursor = null;
    const nodes: TNodeChild[] = [];
    while (hasNextPage) {
      const { data } = await fetchResults({
        variables: {
          ...options?.variables,
          ...variables,
          page: {
            first: 100,
            after: cursor,
          },
        } as TVariables,
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const dataFromElem = (data as any)?.[elemName!];
      nodes.push(...(dataFromElem?.nodes ?? []));
      hasNextPage = dataFromElem?.pageInfo.hasNextPage;
      cursor = dataFromElem?.pageInfo.endCursor;
    }
    setResults(nodes);
    options?.onResults?.(nodes);
    setLoading(false);
    return nodes;
  };

  useEffect(() => {
    if (options?.skip) {
      return;
    }
    fetchAll();
  }, [options?.skip]);

  return { loading, results, refetch: fetchAll };
};
