import { abbreviateNumber } from "../../../utils/numbers";
import { Text, Tooltip } from "@mantine/core";

export const AbbrevitatedNumber = ({
  value,
  decimals,
}: {
  value: number;
  decimals?: number;
}) => (
  <div style={{ display: "inline-block" }}>
    <Tooltip label={value}>
      <Text>{abbreviateNumber(value, decimals)}</Text>
    </Tooltip>
  </div>
);
