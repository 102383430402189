import { Badge, Group, Stack, Text, Title } from "@mantine/core";

export const PlatformHome = () => {
  return (
    <Stack>
      <Title>Platforms</Title>
      <Group spacing={3}>
        <Badge color="orange">PREVIEW</Badge>
        <Badge color="red">UNSTABLE</Badge>
      </Group>
      <Text>
        Platforms allow you to create your own website through the Well Played
        platform. You can create a custom website for your community, let your
        users create and share their own tournaments, and use your own domain
        for your platform.
      </Text>
      <Text>
        By leveraging the Liquid templating engine, you can create custom pages
        using data from your WellPlayed data and players to create unique pages
        for your community. You can also easily create and share your own custom
        theme, or sell it on the marketplace<Badge>Coming soon!</Badge>.
      </Text>
    </Stack>
  );
};
