import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Anchor,
  Box,
  Button,
  Code,
  Divider,
  Flex,
  Group,
  List,
  Loader,
  ScrollArea,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { CreationForm } from "../../../components/form/CreationForm";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";
import { graphql } from "gql.tada";
import { PlatformFileQuery } from "./PlatformFiles";
import { entries, find, findKey, forEach, map } from "lodash";
import { modals } from "@mantine/modals";
import { useState } from "react";
import { FormDefinitions } from "./available-forms";
import { Prism } from "@mantine/prism";
import { IconInfo } from "@aws-amplify/ui-react/internal";
import { TagDefinitions } from "./available-tags";
import { ResultOf } from "@graphql-typed-document-node/core";
import { PAGE_TEMPLATES } from "./page-templates";

const PlatformFileUpdateMutation = graphql(`
  mutation platformFileUpdateData($id: ID!, $input: PlatformFileUpdateInput!) {
    platformFileUpdate(id: $id, input: $input) {
      id
    }
  }
`);

const PlatformFilePublishMutation = graphql(`
  mutation platformFilePublish($id: ID!) {
    platformFilePublish(id: $id)
  }
`);

const uploadFileToS3 = async (
  { url, fields }: { url: string; fields: { key: string; value: string }[] },
  contents: Blob,
) => {
  const formData = new FormData();
  formData.append("Content-Type", "text/html");
  forEach(fields, ({ key, value }) => {
    formData.append(key, value);
  });
  formData.append("file", contents);
  return fetch(url, {
    method: "POST",
    body: formData,
  });
};

interface Schema {
  type: string;
  description: string;
  required: boolean;
  isArray?: boolean;
  items?: Record<string, Schema>;
}

const createPayloadExampleFromSchema = (schema: Record<string, Schema>) => {
  const payload = map(schema ?? {}, (value, key) => ({
    name: key,
    value,
  })).reduce(
    (acc, { value, name }) => {
      if ((value as { isArray: boolean }).isArray) {
        acc[name] = [createPayloadExampleFromSchema(value.items ?? {})];
        return acc;
      }

      switch (value.type) {
        case "string":
          acc[name] = "example";
          return acc;
        case "number":
          acc[name] = 4242;
          return acc;
        case "boolean":
          acc[name] = true;
          return acc;
        case "object":
          acc[name] = createPayloadExampleFromSchema(value.items ?? {});
          return acc;
        default:
          throw new Error(`Unknown type ${value.type}`);
      }
    },
    {} as Record<string, any>,
  );
  return payload;
};

const fileExtensionToLanguageMap: Record<string, string> = {
  js: "javascript",
  ts: "typescript",
  json: "json",
  html: "html",
  css: "css",
  scss: "scss",
  liquid: "liquid",
  md: "markdown",
  yml: "yaml",
  yaml: "yaml",
};

export const PlatformFileContent = () => {
  const fileId = useParams().fileId as string;
  const [publishing, setPublishing] = useState(false);
  const [fileUpdated, setFileUpdated] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [publishFile] = useMutation(PlatformFilePublishMutation);
  const { data, loading } = useQuery(PlatformFileQuery, {
    variables: {
      id: fileId,
    },
  });
  const [fileUpdate] = useMutation(PlatformFileUpdateMutation);

  if (loading) {
    return <Loader />;
  }

  const fileExtension = data?.platformFile?.name.split(".")[1];
  const fileLanguage = fileExtension
    ? fileExtensionToLanguageMap[fileExtension]
    : undefined;
  return (
    <>
      {data?.platformFile?.type === "PAGE" && (
        <Select
          label={"Select starter template"}
          data={PAGE_TEMPLATES.map((template) => ({
            label: template.name,
            value: template.template,
          }))}
          onChange={(value) => {
            setSelectedTemplate(value ?? undefined);
          }}
        />
      )}
      <Flex>
        <Box
          key={selectedTemplate}
          style={{
            flex: "0 0 80%",
          }}
        >
          <CreationForm
            title={"Update file contents"}
            schemaDefinition={{
              template: {
                type: "CODE_EDITOR",
                label: "File contents",
                language:
                  data?.platformFile?.type === "PAGE" ||
                  data?.platformFile?.type === "COMPONENT"
                    ? "liquid"
                    : fileLanguage,
                yupConfig: yup.string().required(),
                inputType: "text",
                defaultValue: selectedTemplate ?? data?.platformFile?.contents,
              },
            }}
            onSubmit={async (values) => {
              // Convert textarea content to a Blob
              const fileBlob = new Blob([values.template], {
                type: "text/plain",
              });

              await fileUpdate({
                variables: {
                  id: fileId,
                  input: {
                    contents: values.template,
                    config: {
                      pageConfig: data?.platformFile.config?.pageConfig && {
                        path: data?.platformFile.config?.pageConfig?.path,
                      },
                    },
                  },
                },
              }).then((data) => {
                if (data?.errors && data.errors.length > 0) {
                  throw new Error(data.errors[0].message);
                }

                return data;
              });

              setFileUpdated(true);
              notifications.show({
                title: "File updated",
                message: "The file has been updated successfully",
                color: "green",
                autoClose: 3000,
              });
            }}
          />
        </Box>
        <ScrollArea
          style={{
            width: "20%",
            height: "90vh",
            flex: "0 0 20%",
          }}
        >
          <Stack>
            <Button
              w={200}
              color={"green"}
              onClick={async () => {
                setPublishing(true);
                try {
                  await publishFile({ variables: { id: fileId } }).then(
                    (data) => {
                      if (data?.errors && data.errors.length > 0) {
                        throw new Error(data.errors[0].message);
                      }
                    },
                  );
                } catch (e) {
                  notifications.show({
                    title: "Cannot publish file",
                    message: (e as any).message,
                    color: "red",
                    autoClose: 3000,
                  });
                  return;
                } finally {
                  setPublishing(false);
                }
                setFileUpdated(false);
                notifications.show({
                  title: "File published",
                  message: "The file has been published successfully",
                  color: "green",
                  autoClose: 3000,
                });
              }}
              loading={publishing}
              disabled={!fileUpdated}
            >
              Publish file to CDN
            </Button>
            {(data?.platformFile?.type === "PAGE" ||
              data?.platformFile?.type === "COMPONENT") && (
              <>
                <Title order={2}>Introduction to Liquid</Title>
                <Text>
                  Liquid is a simple, expressive and safe Shopify / Github Pages
                  compatible template engine.
                  <br />
                  Liquid syntax is relatively simple. There’re 2 types of
                  markups in Liquid:
                  <br />
                  <Text span fw={700}>
                    Tags.
                  </Text>{" "}
                  A tag consists of a tag name and optional arguments wrapped
                  between {`{ % and %}`}.<br />
                  <Text span fw={700}>
                    Outputs.
                  </Text>{" "}
                  An output consists of a value and a list of filters, which is
                  optional, wrapped between {`{{ and }}`}.
                  <Title order={4} mt={10}>
                    Outputs
                  </Title>
                  Outputs are used to output variables, which can be transformed
                  by filters, into HTML.
                  <br />
                  The following template will insert the value of username into
                  the input’s value:
                  <Prism language={"html" as any}>
                    {`<input type="text" name="user" value="{{username}}">`}
                  </Prism>
                  Values in output can be transformed by filters before output.
                  <br />
                  To append a string after the variable:
                  <Prism language={"html" as any}>
                    {`{{ username | append: ", welcome to Liquid!" }}`}
                  </Prism>
                  Filters can be chained:
                  <Prism language={"html" as any}>
                    {`{{ username | append: ", welcome to Liquid!" | capitalize }}`}
                  </Prism>
                  <Alert
                    variant="light"
                    color="blue"
                    title="Info"
                    icon={<IconInfo />}
                  >
                    The following outputs are already available and can be used
                    in your templates:
                    <br />
                    <List>
                      <List.Item>
                        <Code>params</Code>: the URL parameters defined using{" "}
                        <Code>:myParam</Code> format
                        <br />
                        Usage example: <Code>{`{{ params.myParam }}`}</Code>
                      </List.Item>
                    </List>
                  </Alert>
                  <Anchor
                    target="_blank"
                    href="https://liquidjs.com/filters/overview.html"
                  >
                    Default filters can be found here
                  </Anchor>
                  <Title order={4} mt={10}>
                    Tags
                  </Title>
                  Tags are used to control the template rendering process,
                  manipulating template variables, inter-op with other
                  templates, etc.
                  <br />
                  For example assign can be used to define a variable which can
                  be later used in the template:
                  <Prism
                    language={"html" as any}
                  >{`{% assign foo = "FOO" %}`}</Prism>
                  Typically tags appear in pairs with a start tag and a
                  corresponding end tag. For example:
                  <Prism language={"html" as any}>
                    {`{% if foo == "FOO" %}
Variable "foo" equals "FOO"
{% else %}
Variable "foo" not equals "FOO"
{% endif %}`}
                  </Prism>
                  <Anchor
                    target="_blank"
                    href="https://liquidjs.com/tags/overview.html"
                  >
                    Default tags can be found here
                  </Anchor>
                </Text>
                <Title>Tags</Title>
                Usage example:
                <br />
                <Prism language={"html" as any}>
                  {`{% favicon "favicon.svg" %}
{% title "Home" %}

{% list-tournaments %}
<ul>
  {% for tournament in tournaments.nodes %}
    <li>{{ tournament.title }} - {{ tournament.description }}</li>
  {% endfor %}
</ul>
{% end-list-tournaments %}`}
                </Prism>
                <Stack>
                  {TagDefinitions.map((tag, idx) => (
                    <Stack spacing={5} key={idx}>
                      <Stack spacing={0}>
                        <Title order={4}>{tag.tag}</Title>
                        {tag.description}
                        <Text fw={700}>Example:</Text>
                        <Prism language={"html" as any}>
                          {`{% ${tag.tag} ${
                            (tag.paramValue &&
                              `"param-value"${tag.hashInputs && " "}`) ??
                            ""
                          }${map(
                            tag.hashInputs,
                            (value) => `${value.name}:"test"`,
                          ).join(" ")} %}${
                            tag.scopeOutputs
                              ? `\n    ${map(
                                  tag.scopeOutputs,
                                  (schema: Schema, key) =>
                                    (schema.items as Schema["items"])?.nodes
                                      ? `{% for item in ${key}.nodes %}
    <li>{{ item.${findKey(
      schema.items?.nodes,
      (value) => (value as any).type === "string",
    )} }}</li>
  {% endfor %}`
                                      : `{{ ${key}${
                                          schema?.items &&
                                          `.${findKey(
                                            schema.items,
                                            (value) =>
                                              (value as any).type === "string",
                                          )}`
                                        } }}`,
                                ).join("\n    ")}\n{% end-${tag.tag} %}`
                              : ``
                          }`}
                        </Prism>
                      </Stack>
                      <Stack spacing={0}>
                        {tag.paramValue && (
                          <>
                            <Text fw={700}>Param value:</Text>

                            <Text key={idx}>
                              <Text fw={700} span>
                                {tag.paramValue.description}
                                {tag.paramValue.required && "*"}
                              </Text>
                            </Text>
                          </>
                        )}
                        {tag.hashInputs && (
                          <>
                            <Text fw={700}>Hash inputs:</Text>
                            {map(tag.hashInputs, (value, idx) => (
                              <Text key={idx}>
                                <Text fw={700} span>
                                  {value.name} -
                                </Text>{" "}
                                {value.type}:{" "}
                                {
                                  (value as unknown as { description: string })
                                    .description
                                }
                              </Text>
                            ))}
                          </>
                        )}
                        {tag.scopeOutputs && (
                          <>
                            <Text fw={700}>Scope outputs:</Text>
                            <Prism language={"json"}>
                              {JSON.stringify(
                                createPayloadExampleFromSchema(
                                  tag.scopeOutputs as any,
                                ),
                                null,
                                2,
                              )}
                            </Prism>
                          </>
                        )}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
                <Title>Forms</Title>
                Forms allow you to interact with your data on the platform. They
                can be used as a way to create, update or delete data.
                <br />
                By using forms, you can ask for user inputs, use hidden inputs
                or simply create a button to register a team to a tournament.
                <br />
                Usage example:
                <br />
                <Prism language={"html" as any}>
                  {`{% form "update-local-player-profile" %}
<input type="text" name="username" placeholder="Username">
<input type="number"  name="custom-field-age" pattern="[0-9]*" placeholder="Your age">
<input type="hidden" name="description" value="" />
<button type="submit">Submit</button>
{% endform %}`}
                </Prism>
                <Alert
                  variant="light"
                  color="blue"
                  title="Info"
                  icon={<IconInfo />}
                >
                  In the last example, you can also see how custom fields are
                  managed through forms
                  <br />
                  These fields are not listed bellow and are dependant on your
                  custom fields configurations
                  <br />
                  The name format should be set as:
                  <Code>custom-field-(your_custom_field_property_here)</Code>
                </Alert>
                Below are the available forms and their fields.
                <br />
                <Stack spacing={20}>
                  {FormDefinitions.map((form, key) => (
                    <Stack spacing={5} key={key}>
                      <Stack spacing={0}>
                        <Title order={4}>{form.tag}</Title>
                        {form.description}
                      </Stack>
                      <Stack spacing={0}>
                        <Text fw={700}>Inputs:</Text>
                        {map(form.fields, (value, key) => (
                          <Text key={key}>
                            <Text fw={700} span>
                              {key}:
                            </Text>{" "}
                            {value?.type}
                          </Text>
                        ))}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </>
            )}
          </Stack>
        </ScrollArea>
      </Flex>
    </>
  );
};
