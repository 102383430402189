export const FormDefinitions = [
  {
    tag: "update-local-player-profile",
    fields: {
      username: {
        type: "String",
        required: true,
      },
      description: {
        type: "String",
        required: true,
      },
    },
    description: "update the logged in player profile",
  },
  {
    tag: "register-tournament-team",
    fields: {
      name: {
        type: "String",
        required: true,
      },
      tag: {
        type: "String",
        required: true,
      },
    },
    description: "register a tournament team",
  },
  {
    tag: "confirm-tournament-team",
    fields: {
      status: {
        type: "String",
        required: true,
      },
      "team-id": {
        type: "String",
        required: true,
      },
    },
    description: "confirm a tournament team",
  },
  {
    tag: "confirm-tournament-attendance",
    fields: {
      attendance: {
        type: "String",
        required: true,
      },
      "tournament-id": {
        type: "String",
        required: true,
      },
    },
    description: "confirm a tournament team attendance",
  },
  {
    tag: "delete-tournament-team",
    fields: {
      "tournament-id": {
        type: "String",
        required: true,
      },
    },
    description: "delete a tournament team",
  },
];
