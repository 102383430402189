import {
  Badge,
  Center,
  createStyles,
  Navbar,
  NavLink as MantineNavLink,
  rem,
  Title,
} from "@mantine/core";
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  TablerIconsProps,
} from "@tabler/icons-react";
import { NavLink, useParams } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  navbar: {
    paddingTop: 0,
  },

  section: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    marginBottom: theme.spacing.md,

    "&:not(:last-of-type)": {
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[3]
      }`,
    },
  },

  searchCode: {
    fontWeight: 700,
    fontSize: rem(10),
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
  },

  mainLinks: {
    paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingBottom: theme.spacing.md,
  },

  mainLink: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.xs,
    padding: `${rem(8)} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  mainLinkActive: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.xs,
    padding: `${rem(8)} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  mainLinkInner: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },

  mainLinkIcon: {
    marginRight: theme.spacing.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
  },

  mainLinkBadge: {
    padding: 0,
    width: rem(20),
    height: rem(20),
    pointerEvents: "none",
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },
}));

type NavbarItem = {
  icon?: (props: TablerIconsProps) => JSX.Element;
  label: string;
  notifications?: number;
  path?: string;
  disabled?: boolean;
  children?: NavbarItem[];
};

export interface NavbarConfiguration {
  title: string;
  items: NavbarItem[];
}

const VARS_REGEXP = /:([a-zA-Z0-9_-]+)/g;

export function NavbarSearch({
  configuration: { items, title },
}: {
  configuration: NavbarConfiguration;
}) {
  const params = useParams();
  const { classes } = useStyles();

  const mainLinks = items.map((link) => (
    <NavLink
      to={
        link.path?.replaceAll(VARS_REGEXP, (m, i) =>
          i in params ? (params[i] as string) : m
        ) ?? ""
      }
      key={link.path}
      onClick={(e) => link.disabled && e.preventDefault()}
      end
    >
      {({ isActive }) => (
        <MantineNavLink
          disabled={link.disabled}
          className={classes.mainLink}
          active={isActive && !link.children?.length}
          label={link.label}
          icon={
            link.icon && (
              <link.icon
                size={20}
                className={classes.mainLinkIcon}
                stroke={1.5}
              />
            )
          }
          rightSection={
            <>
              {link.notifications && (
                <Badge
                  size="sm"
                  variant="filled"
                  className={classes.mainLinkBadge}
                >
                  {link.notifications}
                </Badge>
              )}{" "}
              {link.children && <IconChevronRight />}
            </>
          }
        >
          {link.children &&
            link.children.map((link) => (
              <NavLink
                to={
                  link.path?.replaceAll(VARS_REGEXP, (m, i) =>
                    i in params ? (params[i] as string) : m
                  ) ?? ""
                }
                key={link.path}
                onClick={(e) =>
                  (link.disabled || link.children?.length) && e.preventDefault()
                }
                end
              >
                {({ isActive }) => (
                  <MantineNavLink
                    disabled={link.disabled}
                    className={classes.mainLink}
                    active={isActive}
                    label={link.label}
                    icon={
                      link.icon && (
                        <link.icon
                          size={20}
                          className={classes.mainLinkIcon}
                          stroke={1.5}
                        />
                      )
                    }
                    rightSection={
                      <>
                        {link.notifications && (
                          <Badge
                            size="sm"
                            variant="filled"
                            className={classes.mainLinkBadge}
                          >
                            {link.notifications}
                          </Badge>
                        )}
                      </>
                    }
                  />
                )}
              </NavLink>
            ))}
        </MantineNavLink>
      )}
    </NavLink>
  ));

  return (
    <Navbar height={700} width={{ sm: 300 }} p="md" className={classes.navbar}>
      <Navbar.Section className={classes.section}>
        <Center>
          <Title>{title}</Title>
        </Center>
      </Navbar.Section>
      <Navbar.Section className={classes.section}>
        <div className={classes.mainLinks}>{mainLinks}</div>
      </Navbar.Section>
    </Navbar>
  );
}
