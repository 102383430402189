import { Box, Group } from "@mantine/core";
import { NavbarConfiguration, NavbarSearch } from "./navbar/Navbar";
import { Outlet } from "react-router-dom";

export interface ServiceWithMenuLayerProps {
  navbarConfiguration: NavbarConfiguration;
}

export const ServiceWithMenuLayer = ({
  navbarConfiguration,
}: ServiceWithMenuLayerProps) => {
  return (
    <Group style={{ zIndex: 0, position: "relative" }}>
      <NavbarSearch configuration={navbarConfiguration} />
      <Box
        sx={{
          minWidth: "100%",
        }}
      >
        <Outlet />
      </Box>
    </Group>
  );
};
