import { ReactElement, useState } from "react";
import { ActionIcon, Group, Loader } from "@mantine/core";
import { IconCheck, IconPencil, IconX } from "@tabler/icons-react";

export const EditableField = ({
  editingElement,
  readingElement,
  defaultValue,
  noValueText,
  onSubmit,
  loading,
}: {
  editingElement: (props: {
    onChange: (value: any) => void;
    value: any;
    disabled: boolean;
  }) => ReactElement;
  readingElement: (props: { value: any }) => ReactElement;
  onSubmit: (value: any) => void;
  defaultValue?: any;
  noValueText?: string;
  loading?: boolean;
}) => {
  const [value, setValue] = useState(defaultValue);
  const [editing, setEditing] = useState(false);

  return !editing ? (
    <Group spacing={5}>
      {readingElement({
        value: value || noValueText || "N/A",
      })}
      {(loading && <Loader size={"xs"} />) || (
        <ActionIcon
          variant={"transparent"}
          onClick={() => setEditing(true)}
          disabled={loading}
        >
          <IconPencil />
        </ActionIcon>
      )}
    </Group>
  ) : (
    <Group spacing={0}>
      {editingElement({
        value,
        onChange: (newValue) => {
          setValue(newValue);
        },
        disabled: !!loading,
      })}
      {(loading && <Loader size={"xs"} />) || (
        <>
          <ActionIcon
            color={"green"}
            variant={"transparent"}
            onClick={() => {
              setEditing(false);
              onSubmit(value);
            }}
          >
            <IconCheck />
          </ActionIcon>
          <ActionIcon
            color={"red"}
            variant={"transparent"}
            onClick={() => {
              setEditing(false);
              setValue(defaultValue);
            }}
          >
            <IconX />
          </ActionIcon>
        </>
      )}
    </Group>
  );
};
