import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Group,
  Stack,
  Title,
  Tooltip,
} from "@mantine/core";
import { Webhook } from "../../../../../gql/graphql";
import { gql, useMutation, useQuery } from "@apollo/client";
import { NavLink, useNavigate } from "react-router-dom";
import { useClipboard } from "@mantine/hooks";
import { DataTable } from "mantine-datatable";
import { IconCopy, IconTrash } from "@tabler/icons-react";

export const Webhooks = () => {
  const navigate = useNavigate();
  const clipboard = useClipboard();
  const { loading, data, refetch } = useQuery<{
    webhooks: Webhook[];
  }>(gql`
    query {
      webhooks {
        id
        events
        active
        url
        secret
        description
      }
    }
  `);
  const [deleteWebhook] = useMutation(gql`
    mutation ($id: ID!) {
      deleteWebhook(id: $id) {
        id
      }
    }
  `);

  const deleteItem = async (id: string) => {
    if (confirm("Are you sure you want to delete this app?")) {
      await deleteWebhook({ variables: { id } });
      await refetch();
    }
  };

  return (
    <>
      <Group spacing={10}>
        <Title>Webhooks</Title>
        <Group spacing={5} mt={5}>
          <Badge color="orange">PREVIEW</Badge>
          <Badge color="red">UNSTABLE</Badge>
        </Group>
      </Group>
      <Button onClick={() => navigate("new")}>Create new webhook</Button>
      <DataTable
        columns={[
          {
            title: "ID",
            accessor: "id",
            render: (item) => (
              <Anchor component={NavLink} to={`${item.id}`}>
                {item.id}
              </Anchor>
            ),
          },
          {
            title: "Description",
            accessor: "description",
          },
          {
            title: "Target URL",
            accessor: "url",
          },
          {
            title: "Status",
            accessor: "status",
            render: (item) => (
              <Badge color={item.active ? "green" : "red"}>
                {item.active ? "Enabled" : "Disabled"}
              </Badge>
            ),
          },
          {
            title: "Events",
            accessor: "events",
            render: (item) => (
              <Stack spacing={2}>
                {item.events.map((event) => (
                  <Badge color={"grey"}>{event}</Badge>
                ))}
              </Stack>
            ),
          },
          {
            title: "",
            accessor: "actions",
            render: (item) => (
              <Stack>
                <Tooltip label="Delete">
                  <ActionIcon onClick={() => deleteItem(item.id)}>
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Copy Secret">
                  <ActionIcon
                    onClick={() => {
                      clipboard.copy(item.secret);
                    }}
                  >
                    <IconCopy />
                  </ActionIcon>
                </Tooltip>
              </Stack>
            ),
          },
        ]}
        fetching={loading}
        records={data?.webhooks}
      />
    </>
  );
};
