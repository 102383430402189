import { gql, useMutation, useQuery } from "@apollo/client";
import {
  OrganizationGroups,
  OrganizationMembers,
  Player,
} from "../../../../../../gql/graphql";
import { CreationForm } from "../../../../components/form/CreationForm";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { ActionIcon, Button, Loader, Text, Title } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { IconTrash } from "@tabler/icons-react";
import { forwardRef } from "react";

const EMAIL_REGEX = new RegExp(
  /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/,
  "gm",
);

const SearchResult = forwardRef<
  HTMLDivElement,
  { value: string; username: string }
>(({ value, username, ...props }, ref) => (
  <div ref={ref} {...props}>
    {EMAIL_REGEX.test(value) ? (
      <Text>EMAIL: {value}</Text>
    ) : (
      <Text>
        {username} ({value})
      </Text>
    )}
  </div>
));

export const CreateMember = () => {
  const navigate = useNavigate();
  const [setMemberStatus] = useMutation(gql`
    mutation ($groupId: ID!, $userId: ID, $email: EmailAddress) {
      setOrganizationMembership(
        input: { groupId: $groupId, userId: $userId, email: $email }
      ) {
        memberId
      }
    }
  `);
  const { refetch } = useQuery<{ searchPlayer: Player[] }>(
    gql`
      query ($username: String!) {
        searchPlayer(username: $username) {
          id
          username
          ownerId
        }
      }
    `,
    {
      skip: true,
    },
  );
  const { data, loading } = useQuery<{
    organizationGroups: OrganizationGroups;
  }>(gql`
    query {
      organizationGroups(page: { first: 100 }) {
        nodes {
          id
          name
        }
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title="Add a member to your organization"
      schemaDefinition={{
        userId: {
          type: "AUTOCOMPLETE",
          label: "Username (or email if root account)",
          itemComponent: SearchResult,
          yupConfig: yup.string().required(),
          transformResult: (result: any) => result.email ?? result.id,
          populate: async (value) => {
            if (EMAIL_REGEX.test(value)) {
              return [
                {
                  value,
                  email: value,
                },
              ];
            }

            const result = await refetch({
              username: value,
            });
            return result.data.searchPlayer.map((player) => ({
              username: player.username,
              value: player.username,
              id: player.ownerId,
            }));
          },
        },
        groupId: {
          type: "SELECT",
          label: "Group",
          yupConfig: yup.string().required(),
          options:
            data?.organizationGroups.nodes.map((group) => ({
              label: group.name,
              value: group.id,
            })) ?? [],
        },
      }}
      onSubmit={async (datas) => {
        const isEmail = EMAIL_REGEX.test(datas.userId);
        await setMemberStatus({
          variables: {
            groupId: datas.groupId,
            userId: isEmail ? undefined : datas.userId,
            email: isEmail ? datas.userId : undefined,
          },
        }).then((data) => {
          if (data?.errors && data.errors.length > 0) {
            throw new Error(data.errors[0].message);
          }
        });
        navigate("..");
      }}
    />
  );
};

export const Members = () => {
  const navigate = useNavigate();
  const { loading, data, refetch } = useQuery<{
    organizationMembers: OrganizationMembers;
  }>(gql`
    query {
      organizationMembers(page: { first: 100 }) {
        nodes {
          group {
            name
          }
          account {
            id
            email
            profiles {
              username
            }
          }
        }
      }
    }
  `);
  const [deleteMember] = useMutation(gql`
    mutation ($memberId: ID!) {
      deleteOrganizationMembership(input: { userId: $memberId })
    }
  `);

  const deleteItem = async (id: string) => {
    if (!confirm("Are you sure you want to delete this member?")) return;
    await deleteMember({
      variables: {
        memberId: id,
      },
    });
    await refetch();
  };

  return (
    <>
      <Title>Members</Title>
      <Text>
        You can add members to your organization. Members can be assigned to
        groups and have different permissions based on their group.
      </Text>
      <Button onClick={() => navigate("new")}>Add a member</Button>
      <DataTable
        columns={[
          {
            title: "Name/ID",
            accessor: "",
            render: (row) =>
              row.account.profiles[0]?.username ??
              row.account.email ??
              row.account.id,
          },
          {
            title: "Group",
            accessor: "group.name",
          },
          {
            title: "",
            accessor: "",
            render: (item) => (
              <ActionIcon onClick={() => deleteItem(item.memberId)}>
                <IconTrash />
              </ActionIcon>
            ),
          },
        ]}
        fetching={loading}
        records={data?.organizationMembers.nodes}
      />
    </>
  );
};
