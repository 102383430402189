import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PlatformCdnFile } from "../gql/graphql";

export const usePages = () => {
  const platformId = useParams().platformId;
  const [pages, setPages] = useState<Record<string, PlatformCdnFile>>();

  const { loading, error } = useQuery<{ platformPages: PlatformCdnFile[] }>(
    gql`
      query ($platformId: ID!) {
        platformFiles(platformId: $platformId, type: PAGE) {
          id
          pageConfig {
            path
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        const pages: Record<string, PlatformCdnFile> = {};
        data.platformPages.forEach((page) => {
          pages[page.id] = page;
        });
        setPages(pages);
      },
      variables: {
        platformId,
      },
    },
  );

  return {
    pages,
    loading,
    error,
  };
};

export const usePage = (pageId: string) => {
  const { pages, loading, error } = usePages();
  return {
    page: pages?.[pageId],
    loading,
    error,
  };
};

export const useEditorConfig = (pageId: string) => {
  const { data, loading, error } = useQuery<{ platformPage: PlatformCdnFile }>(
    gql`
      query ($id: ID!) {
        platformFile(id: $id) {
          pageConfig {
            path
          }
        }
      }
    `,
    {
      variables: {
        id: pageId,
      },
    },
  );

  return {
    page: data?.platformPage,
    loading,
    error,
  };
};
