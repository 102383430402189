import { Center, Stack, Text, Title } from "@mantine/core";
import { Prism } from "@mantine/prism";
import { useSelectedOrganizationId } from "../../../../business/OrganizationId";
import { useParams } from "react-router-dom";

export const LeaderboardWidgets = () => {
  const organizationId = useSelectedOrganizationId();
  const leaderboardId = useParams().leaderboardId;

  return (
    <Stack>
      <Title>Leaderboard widgets</Title>
      <Text>
        You can use the following widgets to display your leaderboard on your
        website.
      </Text>
      <Title order={2} size={"h2"}>
        Current leaderboard
      </Title>
      <Center>
        <iframe
          title="CustomIframe"
          srcDoc={`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Leaderboard Widget Example</title>
    <style>
        .leaderboard {
            font-family: Arial, sans-serif;
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            background-color: #f2f2f2;
            border: 1px solid #ddd;
            border-radius: 8px;
        }

        .bucket {
            background-color: #fff;
            margin-bottom: 15px;
            padding: 15px;
            border-radius: 6px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .bucket h3 {
            margin: 0 0 10px 0;
            color: #333;
            font-size: 1.2em;
        }

        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            padding: 8px 0;
            border-bottom: 1px solid #ddd;
        }

        li:last-child {
            border-bottom: none;
        }

        li span {
            display: inline-block;
            min-width: 100px;
        }

        .rank {
            font-weight: bold;
            color: #007bff;
        }

        .score {
            font-weight: bold;
            color: #28a745;
        }
    </style>
</head>
<body>
<div id="leaderboard-container"></div>

<script src="${
            window.location.origin
          }/widgets/leaderboard/leaderboard-full.js"></script>
<script>
    LeaderboardWidget.init({
        containerId: 'leaderboard-container', // The container to use to render the widget
        leaderboardId: '${leaderboardId}',  // Replace with the actual leaderboard ID
        organizationId: '${organizationId}', // Replace with the actual organization ID
        staging: ${window.location.hostname !== "console.well-played.gg"},
        leaderboardElementFactory: (buckets) => { // Optional: specify a custom leaderboard element factory
            const leaderboardEl = document.createElement('div');
            leaderboardEl.className = 'leaderboard';

            buckets.forEach(bucket => {
                const bucketEl = document.createElement('div');
                bucketEl.className = 'bucket';
                bucketEl.innerHTML = \`<h3>\${bucket.name}</h3>\`;

                const scoresList = document.createElement('ul');
                bucket.scores.nodes.forEach(score => {
                    const scoreEl = document.createElement('li');
                    scoreEl.innerHTML = \`#\${score.rank} - \${score.player.username} (\${score.score})\`;
                    scoresList.appendChild(scoreEl);
                });

                bucketEl.appendChild(scoresList);
                leaderboardEl.appendChild(bucketEl);
            });

            return leaderboardEl;
        }
    });
</script>
</body>
</html>`}
          width="60%"
          height="400"
          style={{ overflow: "auto" }}
        />
      </Center>
      <Prism language={"html" as any}>{`<div id="leaderboard-container"></div>

<script src="${window.location.origin}/widgets/leaderboard/leaderboard-full.js"></script>
<style>
    .leaderboard {
        font-family: Arial, sans-serif;
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f2f2f2;
        border: 1px solid #ddd;
        border-radius: 8px;
    }

    .bucket {
        background-color: #fff;
        margin-bottom: 15px;
        padding: 15px;
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .bucket h3 {
        margin: 0 0 10px 0;
        color: #333;
        font-size: 1.2em;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        padding: 8px 0;
        border-bottom: 1px solid #ddd;
    }

    li:last-child {
        border-bottom: none;
    }

    li span {
        display: inline-block;
        min-width: 100px;
    }

    .rank {
        font-weight: bold;
        color: #007bff;
    }

    .score {
        font-weight: bold;
        color: #28a745;
    }
</style>
<script>
    LeaderboardWidget.init({
        containerId: 'leaderboard-container', // The container to use to render the widget
        leaderboardId: '${leaderboardId}',  // Replace with the actual leaderboard ID
        organizationId: '${organizationId}', // Replace with the actual organization ID
        // bucketId: 'dnC9qyuy7XG4pkhA4krY5X', Optional: specify if you want to display a specific bucket
        leaderboardElementFactory: (buckets) => { // Optional: specify a custom leaderboard element factory
            const leaderboardEl = document.createElement('div');
            leaderboardEl.className = 'leaderboard';

            buckets.forEach(bucket => {
                const bucketEl = document.createElement('div');
                bucketEl.className = 'bucket';
                bucketEl.innerHTML = \`<h3>\${bucket.name}</h3>\`;

                const scoresList = document.createElement('ul');
                bucket.scores.nodes.forEach(score => {
                    const scoreEl = document.createElement('li');
                    scoreEl.innerHTML = \`#\${score.rank} - \${score.player.username} (\${score.score})\`;
                    scoresList.appendChild(scoreEl);
                });

                bucketEl.appendChild(scoresList);
                leaderboardEl.appendChild(bucketEl);
            });

            return leaderboardEl;
        }
    });
</script>`}</Prism>
    </Stack>
  );
};
