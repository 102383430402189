import { usePage } from "../../business/Page";

export const GeneratedPage = ({ pageId }: { pageId: string }) => {
  const { page, loading, error } = usePage(pageId);

  if (loading) {
    return <>Loading...</>;
  }

  if (!page) {
    return <>Page config not found</>;
  }

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: (page?.config?.pageConfig as any).html,
        }}
      />
      <style>{(page?.config?.pageConfig as any).css}</style>
    </>
  );
};
