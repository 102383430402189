import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { graphql } from "gql.tada";
import { useOrganization } from "./Organization";

const GET_PLAYERS_QUERY = graphql(`
  query players(
    $ids: [ID!]!
    $page: PageInfo!
    $identityProviders: [ID!]!
    $identityProviderProperties: [String!]!
    $customFields: [String!]!
  ) {
    players(
      ids: $ids
      page: $page
      identityProviders: $identityProviders
      identityProviderProperties: $identityProviderProperties
    ) {
      nodes {
        id
        username
        ownerId
        customFields(properties: $customFields) {
          property
          value
          name
          order
        }
        identities {
          properties {
            property
            value
          }
          organizationIdentityProvider {
            name
          }
        }
      }
    }
  }
`);

export const usePlayers = ({
  playerIds,
  skip,
  identityProvidersProperties,
  customFields = [],
}: {
  playerIds?: string[];
  identityProvidersProperties: string[];
  customFields?: string[];
  skip?: boolean;
}) => {
  const { loading: organizationLoading, data: organization } =
    useOrganization();
  const [players, setPlayers] = useState<
    {
      id: unknown;
      username: string;
      ownerId: unknown;
      customFields: {
        property: string;
        value: string;
        name: string;
        order: number;
      }[];
      identities: {
        organizationIdentityProvider: {
          name: string;
        } | null;
        properties: {
          property: string;
          value: string;
        }[];
      }[];
    }[]
  >([]);
  const [loading, setLoading] = useState(!skip);

  const [fetch] = useLazyQuery(GET_PLAYERS_QUERY);

  const refetch = async (playerIds: string[]) => {
    const result: {
      id: unknown;
      username: string;
      ownerId: unknown;
      customFields: {
        property: string;
        value: string;
        name: string;
        order: number;
      }[];
      identities: {
        organizationIdentityProvider: {
          name: string;
        } | null;
        properties: {
          property: string;
          value: string;
        }[];
      }[];
    }[] = [];
    while (playerIds.length > 0) {
      result.push(
        ...((
          await fetch({
            variables: {
              page: { first: 100 },
              ids: playerIds.splice(0, 100) as never,
              identityProviders:
                organization?.organization.identityProviders.map(
                  (provider) => provider.id as never,
                ) || [],
              identityProviderProperties: identityProvidersProperties,
              customFields,
            },
          })
        ).data?.players?.nodes.map((player) => ({
          ...player,
          identities: player.identities ?? [],
        })) ?? []),
      );
    }
    setPlayers(result);
  };

  useEffect(() => {
    if (!skip && playerIds && organization) {
      setLoading(true);
      refetch(playerIds).finally(() => setLoading(false));
    }
  }, [skip, playerIds, organization]);

  return {
    results: players,
    refetch,
    loading: loading || organizationLoading,
  };
};
