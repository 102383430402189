export function constructWithoutUndefined(
  obj: any,
  clearEmpty = true,
  cleanArrays = false
): any {
  const ret = {};
  if (typeof obj === "object" && !(obj instanceof Date)) {
    const allEntries = [
      ...Object.keys(obj),
      ...Object.getOwnPropertySymbols(obj),
    ];
    allEntries.forEach((key) => {
      const value = obj[key];
      if (value === undefined) return;
      if (typeof value === "object") {
        let obj = value as any;
        try {
          if (Array.isArray(obj)) {
            if (cleanArrays) {
              obj = obj
                .filter((e) => e)
                .map((e) =>
                  constructWithoutUndefined(e, clearEmpty, cleanArrays)
                );
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ret[key] = obj;
          } else if (value instanceof Date) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ret[key] = value;
          } else if (obj.property && obj.value !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ret[obj.property] = obj.value;
          } else {
            const cleaned = constructWithoutUndefined(
              value,
              clearEmpty,
              cleanArrays
            );
            if (
              !clearEmpty ||
              Object.keys(cleaned).length > 0 ||
              Object.getOwnPropertySymbols(cleaned).length > 0
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ret[key] = cleaned;
            }
          }
        } catch (e) {
          console.error(e);
          throw e;
        }
      } else if (typeof value !== "object") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ret[key] = value;
      }
    });
  } else {
    return obj;
  }
  return ret;
}
