import {
  Anchor,
  Box,
  Burger,
  Center,
  Collapse,
  createStyles,
  Divider,
  Drawer,
  Group,
  Header,
  HoverCard,
  Image,
  Menu,
  rem,
  ScrollArea,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronUp,
  IconCirclePlus,
  IconLogout,
  IconSearch,
  TablerIconsProps,
} from "@tabler/icons-react";
import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useOidc } from "@axa-fr/react-oidc";
import {
  setSelectedOrganizationId,
  useSelectedOrganizationId,
} from "../../../../../business/OrganizationId";
import { gql, useQuery } from "@apollo/client";
import { Organization } from "../../../../../gql/graphql";
import { useAuthenticator } from "@aws-amplify/ui-react";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    },

    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("xs")]: {
      display: "none",
    },
  },

  userActive: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
}));

export interface HeaderLink {
  title: string;
  path?: string;
  childrens?: {
    icon: (props: TablerIconsProps) => JSX.Element;
    title: string;
    description: string;
    path: string;
    disabled?: boolean;
  }[];
}

const UserMenu = () => {
  const { logout } = useOidc("wp");
  const { signOut } = useAuthenticator();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { classes, cx } = useStyles();

  return (
    <Menu
      width={260}
      position="bottom-end"
      transitionProps={{ transition: "pop-top-right" }}
      onClose={() => setUserMenuOpened(false)}
      onOpen={() => setUserMenuOpened(true)}
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={cx(classes.user, {
            [classes.userActive]: userMenuOpened,
          })}
        >
          <Group spacing={7}>
            <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
              Account
            </Text>
            <IconChevronDown size={rem(12)} stroke={1.5} />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        {/*<Menu.Item
          icon={
            <IconHeart size="0.9rem" color={theme.colors.red[6]} stroke={1.5} />
          }
        >
          Liked posts
        </Menu.Item>
        <Menu.Item
          icon={
            <IconStar
              size="0.9rem"
              color={theme.colors.yellow[6]}
              stroke={1.5}
            />
          }
        >
          Saved posts
        </Menu.Item>
        <Menu.Item
          icon={
            <IconMessage
              size="0.9rem"
              color={theme.colors.blue[6]}
              stroke={1.5}
            />
          }
        >
          Your comments
        </Menu.Item>

        <Menu.Label>Settings</Menu.Label>
        <Menu.Item icon={<IconSettings size="0.9rem" stroke={1.5} />}>
          Account settings
        </Menu.Item>
        <Menu.Item icon={<IconSwitchHorizontal size="0.9rem" stroke={1.5} />}>
          Change account
        </Menu.Item>*/}
        <Menu.Item
          icon={<IconLogout size="0.9rem" stroke={1.5} />}
          onClick={() => logout().then(() => signOut())}
        >
          Logout
        </Menu.Item>

        {/*<Menu.Divider />

        <Menu.Label>Danger zone</Menu.Label>
        <Menu.Item icon={<IconPlayerPause size="0.9rem" stroke={1.5} />}>
          Pause subscription
        </Menu.Item>
        <Menu.Item color="red" icon={<IconTrash size="0.9rem" stroke={1.5} />}>
          Delete account
        </Menu.Item>*/}
      </Menu.Dropdown>
    </Menu>
  );
};

export function HeaderMegaMenu({ links }: { links: HeaderLink[] }) {
  const selectedOrganizationId = useSelectedOrganizationId();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [selectedOrganization, setSelectedOrganization] = useState<{
    name: string;
    id: string;
  }>();
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const { data } = useQuery<{
    userOrganizations: Organization[];
  }>(
    gql`
      query {
        userOrganizations {
          id
          name
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (!selectedOrganizationId && data.userOrganizations.length > 0) {
          setSelectedOrganizationId(data.userOrganizations[0].id);
          return;
        }

        if (data.userOrganizations.length === 0) {
          navigate("/organizations/new");
          return;
        }

        const selectedOrganization = data.userOrganizations.find(
          (org) => org.id === selectedOrganizationId,
        );
        if (!selectedOrganization) {
          setSelectedOrganizationId(null);
        } else {
          setSelectedOrganization(selectedOrganization);
        }
      },
    },
  );

  const selectOrganization = (id: string) => {
    setSelectedOrganizationId(id);
  };

  return (
    <>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <Group>
            <Image src="/favicon.svg" width={30} height={30} />
            <Group ml={3} spacing={4}>
              <Tooltip label="Select organization">
                <Select
                  data={
                    data?.userOrganizations.map((organization) => ({
                      value: organization.id,
                      label: organization.name,
                      selected: organization.id === selectedOrganizationId,
                    })) || []
                  }
                  value={selectedOrganizationId}
                  zIndex={9999999}
                  onChange={(value) => selectOrganization(value ?? "")}
                />
              </Tooltip>
              <Tooltip label="Create organization">
                <Anchor mt={5} component={NavLink} to="/organizations/new">
                  <IconCirclePlus />
                </Anchor>
              </Tooltip>
              <Group
                sx={{ height: "100%" }}
                spacing={0}
                className={classes.hiddenMobile}
              >
                {links.map(
                  (item, idx) =>
                    (item.path && (
                      <Anchor
                        className={classes.link}
                        component={NavLink}
                        to={item.path}
                        key={idx}
                      >
                        {item.title}
                      </Anchor>
                    )) ||
                    (item.childrens && (
                      <HoverCard
                        width={600}
                        position="bottom"
                        radius="md"
                        shadow="md"
                        withinPortal
                        key={idx}
                      >
                        <HoverCard.Target>
                          <Anchor className={classes.link}>
                            <Center inline>
                              <Box component="span" mr={5}>
                                {item.title}
                              </Box>
                              <IconChevronDown
                                size={16}
                                color={theme.fn.primaryColor()}
                              />
                            </Center>
                          </Anchor>
                        </HoverCard.Target>

                        <HoverCard.Dropdown sx={{ overflow: "hidden" }}>
                          <Group position="apart" px="md">
                            <Text fw={500}>{item.title}</Text>
                            <TextInput
                              placeholder="Search"
                              icon={<IconSearch />}
                              onChange={(value) =>
                                setSearchQuery(value.target.value.toLowerCase())
                              }
                            />
                          </Group>

                          <Divider
                            my="sm"
                            mx="-md"
                            color={
                              theme.colorScheme === "dark" ? "dark.5" : "gray.1"
                            }
                          />

                          <ScrollArea h={200} mx="-md">
                            <SimpleGrid cols={2} spacing={0}>
                              {item.childrens
                                .filter(
                                  (item) =>
                                    item.title
                                      .toLowerCase()
                                      .includes(searchQuery) ||
                                    item.description
                                      .toLowerCase()
                                      .includes(searchQuery),
                                )
                                .map((item, idx) => (
                                  <UnstyledButton
                                    className={classes.subLink}
                                    key={idx}
                                    component={Link}
                                    to={item.path}
                                    onClick={(e) =>
                                      item.disabled && e.preventDefault()
                                    }
                                  >
                                    <Group noWrap align="flex-start">
                                      <ThemeIcon
                                        size={34}
                                        variant="default"
                                        radius="md"
                                      >
                                        <item.icon
                                          size={rem(22)}
                                          color={
                                            item.disabled
                                              ? theme.colors.gray[7]
                                              : theme.fn.primaryColor()
                                          }
                                        />
                                      </ThemeIcon>
                                      <div>
                                        <Text size="sm" fw={500}>
                                          {item.title}
                                        </Text>
                                        <Text size="xs" color="dimmed">
                                          {item.description}
                                        </Text>
                                      </div>
                                    </Group>
                                  </UnstyledButton>
                                ))}
                            </SimpleGrid>
                          </ScrollArea>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    )),
                )}
              </Group>
            </Group>
          </Group>

          <div className={classes.hiddenMobile}>
            <UserMenu />
          </div>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          {links.map(
            (item, idx) =>
              (item.path && (
                <Anchor
                  className={classes.link}
                  key={idx}
                  component={NavLink}
                  to={item.path}
                >
                  {item.title}
                </Anchor>
              )) ||
              (item.childrens && (
                <>
                  <UnstyledButton
                    className={classes.link}
                    onClick={toggleLinks}
                    key={idx}
                  >
                    <Center inline>
                      <Box component="span" mr={5}>
                        {item.title}
                      </Box>
                      {(linksOpened && (
                        <IconChevronUp
                          size={16}
                          color={theme.fn.primaryColor()}
                        />
                      )) || (
                        <IconChevronDown
                          size={16}
                          color={theme.fn.primaryColor()}
                        />
                      )}
                    </Center>
                  </UnstyledButton>
                  <Collapse in={linksOpened}>
                    {item.childrens.map((item, idx) => (
                      <UnstyledButton
                        className={classes.subLink}
                        key={idx}
                        component={NavLink}
                        to={item.path}
                      >
                        <Group noWrap align="flex-start">
                          <ThemeIcon size={34} variant="default" radius="md">
                            <item.icon
                              size={rem(22)}
                              color={theme.fn.primaryColor()}
                            />
                          </ThemeIcon>
                          <div>
                            <Text size="sm" fw={500}>
                              {item.title}
                            </Text>
                            <Text size="xs" color="dimmed">
                              {item.description}
                            </Text>
                          </div>
                        </Group>
                      </UnstyledButton>
                    ))}
                  </Collapse>
                </>
              )),
          )}

          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <UserMenu />
        </ScrollArea>
      </Drawer>
    </>
  );
}
