import {
  Badge,
  Card,
  Center,
  Flex,
  Grid,
  Group,
  Loader,
  RingProgress,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconTournament, IconUserPlus } from "@tabler/icons-react";
import { AbbrevitatedNumber } from "../../components/AbbrevitatedNumber";
import { gql, useQuery } from "@apollo/client";
import { Tournament, TournamentTeams } from "../../../../gql/graphql";
import { useParams } from "react-router-dom";

export const TournamentDashboard = () => {
  const tournamentId = useParams().tournamentId;
  const { data, loading } = useQuery<{
    tournament: Tournament;
  }>(
    gql`
      query ($id: ID!) {
        tournament(id: $id) {
          title
          visibleAt
          startRegistrationsAt
          endRegistrationsAt
          startAt
          endAt
          configuration {
            teamsCount
          }
          teams(status: CONFIRMED, page: { first: 1 }) {
            totalCount
          }
        }
      }
    `,
    { variables: { id: tournamentId } },
  );
  const { data: registeredTeams, loading: registeredTeamsLoading } = useQuery<{
    tournamentTeams: TournamentTeams;
  }>(
    gql`
      query ($id: ID!) {
        tournamentTeams(
          tournamentId: $id
          status: REGISTERED
          page: { first: 1 }
        ) {
          totalCount
        }
      }
    `,
    { variables: { id: tournamentId } },
  );
  const { data: validatedTeams, loading: validatedTeamsLoading } = useQuery<{
    tournamentTeams: TournamentTeams;
  }>(
    gql`
      query ($id: ID!) {
        tournamentTeams(
          tournamentId: $id
          status: AWAITING_FOR_PRESENCE_CONFIRMATION
          page: { first: 1 }
        ) {
          totalCount
        }
      }
    `,
    { variables: { id: tournamentId } },
  );

  return (
    <>
      <Group>
        <Title>Dashboard</Title>
      </Group>
      <SimpleGrid cols={2}>
        <Card pt={0}>
          {(loading && <Loader />) || (
            <>
              <Card.Section>
                <Group position="apart" p={10}>
                  <Group spacing={10}>
                    <IconTournament />
                    <Text size="md" weight={700}>
                      {data?.tournament?.title}
                    </Text>
                  </Group>
                  {(data?.tournament?.visibleAt &&
                    new Date(data?.tournament?.visibleAt) <= new Date() && (
                      <Badge>Visible</Badge>
                    )) || (
                    <Tooltip
                      label={
                        (!data?.tournament?.visibleAt && (
                          <Text size="sm">No visibility date set yet</Text>
                        )) || (
                          <Text size="sm">
                            Visible at{" "}
                            {new Date(
                              data?.tournament?.visibleAt,
                            ).toLocaleString()}
                          </Text>
                        )
                      }
                    >
                      <Badge color="red">Not visible</Badge>
                    </Tooltip>
                  )}
                </Group>
              </Card.Section>
              <SimpleGrid cols={2}>
                <Stack>
                  <Text weight={700}>Starting date</Text>
                  <Text>
                    {(data?.tournament?.startAt && (
                      <Text>
                        {new Date(data?.tournament?.startAt).toLocaleString()}
                      </Text>
                    )) || <Text>No starting date set yet</Text>}
                  </Text>
                </Stack>
                <Stack>
                  <Text weight={700}>Ending date</Text>
                  <Text>
                    {(data?.tournament?.endAt && (
                      <Text>
                        {new Date(data?.tournament?.endAt).toLocaleString()}
                      </Text>
                    )) || <Text>No ending date set yet</Text>}
                  </Text>
                </Stack>
              </SimpleGrid>
            </>
          )}
        </Card>
        <Card pt={0}>
          {(loading && <Loader />) || (
            <>
              <Card.Section>
                <Group position="apart" p={10}>
                  <Group spacing={10}>
                    <IconUserPlus />
                    <Text size="md" weight={700}>
                      Registrations
                    </Text>
                  </Group>
                  {(data?.tournament?.startRegistrationsAt &&
                    new Date(data?.tournament?.startRegistrationsAt) <=
                      new Date() &&
                    (!data?.tournament?.endRegistrationsAt ||
                      new Date(data?.tournament?.endRegistrationsAt) >
                        new Date()) && <Badge>Open</Badge>) ||
                    (data?.tournament?.endRegistrationsAt &&
                      new Date(data?.tournament?.endRegistrationsAt) <=
                        new Date() && <Badge color="red">Closed</Badge>) ||
                    (data?.tournament?.startRegistrationsAt && (
                      <Badge color="orange">Not opened yet</Badge>
                    )) || <Badge color="red">Not configured</Badge>}
                </Group>
              </Card.Section>
              <SimpleGrid cols={2}>
                <Stack>
                  <Text weight={700}>Registrations starting date</Text>
                  <Text>
                    {(data?.tournament?.startRegistrationsAt && (
                      <Text>
                        {new Date(
                          data?.tournament?.startRegistrationsAt,
                        ).toLocaleString()}
                      </Text>
                    )) || <Text>No registrations starting date set yet</Text>}
                  </Text>
                </Stack>
                <Stack>
                  <Text weight={700}>Registrations ending date</Text>
                  <Text>
                    {(data?.tournament?.endRegistrationsAt && (
                      <Text>
                        {new Date(
                          data?.tournament?.endRegistrationsAt,
                        ).toLocaleString()}
                      </Text>
                    )) || <Text>No registrations ending date set yet</Text>}
                  </Text>
                </Stack>
              </SimpleGrid>
            </>
          )}
        </Card>
        <Card pt={0}>
          {(loading && <Loader />) || (
            <>
              <Stack align="center" spacing={2}>
                <RingProgress
                  sections={[
                    {
                      value: data?.tournament?.teams?.totalCount ?? 0,
                      color: "blue",
                    },
                  ]}
                  size={100}
                  label={
                    <Center>
                      <AbbrevitatedNumber
                        value={data?.tournament?.teams?.totalCount ?? 0}
                      />
                    </Center>
                  }
                />
                <Text weight={700}>Confirmed Teams</Text>
                <Text>
                  <AbbrevitatedNumber
                    value={data?.tournament?.teams?.totalCount ?? 0}
                  />{" "}
                  /{" "}
                  <AbbrevitatedNumber
                    value={data?.tournament?.configuration?.teamsCount ?? 0}
                  />
                </Text>
              </Stack>
            </>
          )}
        </Card>
        <Card pt={0}>
          {(validatedTeamsLoading && registeredTeamsLoading && <Loader />) || (
            <>
              <Stack align="center" spacing={2}>
                <Title>
                  <AbbrevitatedNumber
                    value={validatedTeams?.tournamentTeams.totalCount ?? 0}
                  />
                </Title>
                <Text weight={700}>Validated Teams</Text>
                <Title>
                  <AbbrevitatedNumber
                    value={registeredTeams?.tournamentTeams.totalCount ?? 0}
                  />
                </Title>
                <Text weight={700}>Registered Teams</Text>
              </Stack>
            </>
          )}
        </Card>
      </SimpleGrid>
    </>
  );
};
