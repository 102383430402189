import { gql, useMutation, useQuery } from "@apollo/client";
import * as yup from "yup";
import { setSelectedOrganizationId } from "../../../../business/OrganizationId";
import {
  Anchor,
  Button,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useState } from "react";
import { DataTable } from "mantine-datatable";
import { Organization } from "../../../../gql/graphql";
import { useNavigate } from "react-router-dom";

export const OrganizationsList = () => {
  const navigate = useNavigate();
  const { loading, data, error } = useQuery<{
    userOrganizations: Organization[];
  }>(gql`
    query {
      userOrganizations {
        id
        name
      }
    }
  `);

  return (
    <>
      <Button
        onClick={() => {
          navigate(`../new`);
        }}
      >
        Create a new Organization
      </Button>
      <DataTable
        columns={[
          {
            title: "Name",
            accessor: "name",
            render: (item) => (
              <Anchor
                onClick={() => {
                  navigate(`../${item.id}`);
                }}
              >
                {item.name}
              </Anchor>
            ),
          },
        ]}
        records={data?.userOrganizations}
        fetching={loading}
      />
    </>
  );
};

export const CreateOrganization = () => {
  const [loading, setLoading] = useState(false);
  const schema = yup
    .object({
      name: yup
        .string()
        .min(3)
        .max(50)
        .matches(/^(?=.{3,50}$)[A-Za-z]( ?[A-Za-z] ?)*[^ ]$/)
        .required(),
      description: yup.string().min(0).max(256).required(),
    })
    .required();

  const form = useForm<{
    name: string;
    description: string;
  }>({
    validate: yupResolver(schema),
    initialValues: {
      name: "",
      description: "",
    },
  });

  const [createOrganizationMutation] = useMutation<{
    createOrganization: { id: string };
  }>(gql`
    mutation ($name: String!, $description: String!) {
      createOrganization(
        organization: {
          name: $name
          configuration: { customFields: [] }
          description: $description
        }
      ) {
        id
      }
    }
  `);

  const submit = async (data: { name: string; description: string }) => {
    setLoading(true);
    try {
      const response = await createOrganizationMutation({
        variables: data,
      });
      if (response.data?.createOrganization?.id) {
        setSelectedOrganizationId(response.data.createOrganization.id, false);
        window.location.replace("/");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title order={1} variant="h1">
        Create a new organization
      </Title>
      <form onSubmit={form.onSubmit(submit)}>
        <div>
          <TextInput
            withAsterisk
            label="Organization name"
            type="text"
            id="name"
            placeholder="Organization inc."
            {...form.getInputProps("name")}
          />
        </div>
        <div>
          <Textarea
            withAsterisk
            label="Description"
            id="description"
            placeholder="Description"
            {...form.getInputProps("description")}
          />
        </div>
        <Button type="submit" loading={loading}>
          Create Organization
        </Button>
      </form>
    </>
  );
};

export const OrganizationsHome = () => {
  return (
    <Stack>
      <Title>Organizations</Title>
      <Text>
        <Text weight={700} span>
          Organizations
        </Text>{" "}
        are the main entities in WellPlayed.
        <br />
        They represent your company or your tournament platform, and are the
        main way to organize your platform.
      </Text>
      <Text>
        <Text weight={700}>Data ownership</Text>
        The organization's data belongs to the organization, and the
        organization only.
        <br />
        That means that organization datas (including user datas) are only
        accessible through permissions and cannot be accessed from another
        organization.
      </Text>
      <Text>
        <Text weight={700}>Membership</Text>
        Each organization provide an in-depth user and permissions management
        system
        <br />
        This system allows the owner or permitted users to manage and adapt user
        permissions on the platform Permissions ranges from creating a
        tournament to managing them
      </Text>
      <Text>
        <Text weight={700}>API Access</Text>
        API Access is partitioned by Organizations, each Organization has its
        own data and is only accessible by them
        <br />
        API Limits are linked to the Organization's own requests, and are
        independent from other Organizations
      </Text>
    </Stack>
  );
};
