import { gql, useQuery } from "@apollo/client";
import {
  Limits as LimitsDto,
  LimitScope,
  OrganizationLimit,
  OrganizationLimits,
} from "../../../../gql/graphql";
import { AbbrevitatedNumber } from "../../components/AbbrevitatedNumber";
import { useState } from "react";
import { DataTable } from "mantine-datatable";
import { Anchor, Stack, Text, Title, Tooltip } from "@mantine/core";
import { NavLink } from "react-router-dom";

export const Limits = () => {
  const { loading } = useQuery<{
    limitDefinitions: LimitsDto;
    organizationLimits: OrganizationLimits;
  }>(
    gql`
      query {
        limitDefinitions {
          limits {
            id
            description
            name
            type
            default
            scope
          }
        }
        organizationLimits {
          limits {
            current
            id
            max
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setData({
          limitDefinitions: data.limitDefinitions,
          organizationLimits: data.organizationLimits.limits.reduce(
            (acc, limit) => {
              acc[limit.id] = limit;
              return acc;
            },
            {} as Record<string, OrganizationLimit>,
          ),
        });
      },
    },
  );
  const [data, setData] = useState<{
    limitDefinitions: LimitsDto;
    organizationLimits: Record<string, OrganizationLimit>;
  }>();

  return (
    <>
      <Text>
        Need more limits?{" "}
        <Anchor component={NavLink} to={"/limits/subscription"}>
          Click here
        </Anchor>
      </Text>
      <DataTable
        columns={[
          {
            accessor: "name",
            title: "Limit",
            render: (limit) => (
              <Tooltip label={limit.description}>
                <Text>{limit.name}</Text>
              </Tooltip>
            ),
          },
          {
            accessor: "current",
            title: "Current usage or value",
            render: (limit) =>
              (limit.scope === LimitScope.Organization &&
                limit.type !== "DAYS" && (
                  <>
                    {(limit.type === "NUMBER" ||
                      (limit.type as string) === "BYTES") && (
                      <AbbrevitatedNumber
                        value={
                          data?.organizationLimits[limit.id]?.current as number
                        }
                      />
                    )}
                    {limit.type === "BOOLEAN" && (
                      <>
                        {data?.organizationLimits[limit.id]?.current === 1
                          ? "Yes"
                          : "No"}
                      </>
                    )}
                  </>
                )) ||
              "-",
          },
          {
            accessor: "max",
            title: "Current limit",
            render: (limit) =>
              ((limit.type === "NUMBER" ||
                (limit.type as string) === "BYTES") && (
                <AbbrevitatedNumber
                  value={data?.organizationLimits[limit.id]?.max as number}
                />
              )) ||
              (limit.type === "BOOLEAN" && (
                <>
                  {data?.organizationLimits[limit.id]?.max === 1 ? "Yes" : "No"}
                </>
              )) ||
              (limit.type === "DAYS" && (
                <>
                  {data?.organizationLimits[limit.id]?.max ?? "Error"} day
                  {(data?.organizationLimits[limit.id]?.max ?? 0) > 1
                    ? "s"
                    : ""}
                </>
              )) ||
              "-",
          },
          {
            accessor: "default",
            title: "Default value",
            render: (limit) =>
              ((limit.type === "NUMBER" ||
                (limit.type as string) === "BYTES") && (
                <AbbrevitatedNumber value={limit.default} />
              )) ||
              (limit.type === "BOOLEAN" && (
                <>{limit.default === 1 ? "Yes" : "No"}</>
              )) ||
              (limit.type === "DAYS" && (
                <>
                  {limit.default} day{limit.default > 1 ? "s" : ""}
                </>
              )) ||
              "-",
          },
        ]}
        records={data?.limitDefinitions?.limits}
        fetching={loading}
      />
    </>
  );
};

export const LimitsHome = () => {
  return (
    <Stack>
      <Title>Limits and Subscriptions</Title>
      <Text>
        <Text weight={700}>Limits</Text> are the maximum number of features and
        items you can have in your organization. For example, if you have a
        limit of 1,000 users, you can only have 1,000 users in your
        organization.
      </Text>
      <Text>
        <Text weight={700}>Subscriptions</Text> allows you to increase your
        limits. For example, if you have a limit of 1,000 users, you can
        increase it to 10,000 users by subscribing to the 10,000 users plan.
      </Text>
    </Stack>
  );
};
