import * as yup from "yup";
import { AnySchema, ObjectShape } from "yup";
import { useForm, yupResolver } from "@mantine/form";
import { FC, Fragment, ReactElement, useEffect, useState } from "react";
import {
  Autocomplete as MantineAutocomplete,
  MultiSelect as MantineMultiSelect,
  AutocompleteItem,
  Button,
  Checkbox as MantineCheckbox,
  Group,
  Loader,
  NumberInput,
  Select as MantineSelect,
  Text,
  Textarea as MantineTextarea,
  TextInput,
  Tooltip,
  Stack,
} from "@mantine/core";
import { GetInputProps } from "@mantine/form/lib/types";
import { DateTimePicker } from "@mantine/dates";
import { useDebouncedValue } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";
import { useEditor } from "@tiptap/react";
import { RichTextEditor, Link } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import { Editor } from "@monaco-editor/react";
import { Prism } from "@mantine/prism";

interface BaseFormComponent {
  yupConfig: AnySchema;
  label: string;
  info?: string | ReactElement;
  subtext?: string | ReactElement;
  onChange?: (value: string) => void;
  dynamicSubtext?: (value: string) => string;
  defaultValue?: any;
  disabled?: boolean;
}

interface DynamicConfig extends BaseFormComponent {
  type: "DYNAMIC";
  element: (onChange: (...newValues: any[]) => void) => ReactElement;
}

interface TextareaConfig extends BaseFormComponent {
  type: "TEXTAREA";
}

interface RichTextEditorConfig extends BaseFormComponent {
  type: "RICH_TEXT_EDITOR";
}

interface CodeEditorConfig extends BaseFormComponent {
  type: "CODE_EDITOR";
  language?: string;
}

interface CheckboxConfig extends BaseFormComponent {
  type: "CHECKBOX";
}

interface InputConfig extends BaseFormComponent {
  type: "INPUT";
  inputType: "text" | "password" | "email" | "number" | "tel" | "url" | "date";
  placeholder?: string;
}

interface SelectConfig extends BaseFormComponent {
  type: "SELECT";
  options: { label: string; value: string }[];
}

interface MultiSelectConfig extends Omit<SelectConfig, "onChange" | "type"> {
  type: "MULTI_SELECT";
  options: { label: string; value: string }[];
  onChange?: (value: string[]) => void;
}

interface AutocompleteConfig extends BaseFormComponent {
  type: "AUTOCOMPLETE";
  populate: (value: string) => Promise<AutocompleteItem[]>;
  transformResult?: (result: any) => AutocompleteItem;
  itemComponent?: FC<any>;
}

type Schema = Record<
  string,
  | InputConfig
  | TextareaConfig
  | SelectConfig
  | AutocompleteConfig
  | CheckboxConfig
  | DynamicConfig
  | RichTextEditorConfig
  | CodeEditorConfig
  | MultiSelectConfig
>;

const Dynamic = ({
  id,
  getInputProps,
  element,
}: DynamicConfig & {
  id: string;
  getInputProps: GetInputProps<any>;
}) => {
  return (
    <Fragment>
      <DynamicComponent toRender={element(getInputProps(id).onChange)} />
      {getInputProps(id).error && (
        <Text color="red">{getInputProps(id).error}</Text>
      )}
    </Fragment>
  );
};

/* eslint-disable react/prop-types */
const Input = ({
  id,
  label,
  info,
  placeholder,
  defaultValue,
  getInputProps,
  inputType,
  disabled,
}: InputConfig & {
  id: string;
  getInputProps: GetInputProps<any>;
}) => {
  if (inputType === "date") {
    const date = defaultValue ? new Date(defaultValue) : undefined;
    const props = getInputProps(id);
    return (
      <DateTimePicker
        label={label}
        rightSection={
          info && (
            <Tooltip label={info}>
              <Text size="xs">
                <IconInfoCircle />
              </Text>
            </Tooltip>
          )
        }
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        {...props}
        defaultValue={date}
        value={props.value ? new Date(props.value) : undefined}
      />
    );
  }

  if (inputType === "number") {
    return (
      <NumberInput
        disabled={disabled}
        label={label}
        id={id}
        rightSection={
          info && (
            <Tooltip label={info}>
              <Text size="xs">
                <IconInfoCircle />
              </Text>
            </Tooltip>
          )
        }
        defaultValue={defaultValue}
        {...getInputProps(id)}
      />
    );
  }

  return (
    <TextInput
      disabled={disabled}
      type={inputType}
      label={label}
      rightSection={
        info && (
          <Tooltip label={info}>
            <Text size="xs">
              <IconInfoCircle />
            </Text>
          </Tooltip>
        )
      }
      id={id}
      defaultValue={defaultValue}
      {...getInputProps(id)}
    />
  );
};

const Textarea = ({
  id,
  label,
  info,
  defaultValue,
  getInputProps,
  disabled,
}: TextareaConfig & {
  id: string;
  getInputProps: GetInputProps<any>;
}) => (
  <div>
    <div className="relative">
      <MantineTextarea
        disabled={disabled}
        label={label}
        rightSection={
          info && (
            <Tooltip label={info}>
              <Text size="xs">
                <IconInfoCircle />
              </Text>
            </Tooltip>
          )
        }
        id={id}
        defaultValue={defaultValue}
        {...getInputProps(id)}
      />
    </div>
  </div>
);

const Checkbox = ({
  id,
  label,
  info,
  defaultValue,
  getInputProps,
  disabled,
}: CheckboxConfig & {
  id: string;
  getInputProps: GetInputProps<any>;
}) => {
  return (
    <MantineCheckbox
      disabled={disabled}
      label={
        <Group spacing={3}>
          <Text>{label}</Text>
          {info && (
            <Tooltip label={info}>
              <Text size="xs">
                <IconInfoCircle />
              </Text>
            </Tooltip>
          )}
        </Group>
      }
      id={id}
      {...getInputProps(id)}
      defaultChecked={defaultValue || false}
      defaultValue={defaultValue || false}
    />
  );
};

const Select = ({
  id,
  label,
  options,
  info,
  defaultValue,
  onChange,
  getInputProps,
  disabled,
}: SelectConfig & {
  id: string;
  getInputProps: GetInputProps<any>;
}) => {
  const registration = getInputProps(id);
  useEffect(() => {
    if (defaultValue) {
      onChange?.(defaultValue);
      registration.onChange(defaultValue);
    }
  }, []);

  return (
    <MantineSelect
      disabled={disabled}
      label={label}
      rightSection={
        info && (
          <Tooltip label={info}>
            <Text size="xs">
              <IconInfoCircle />
            </Text>
          </Tooltip>
        )
      }
      id={id}
      {...getInputProps(id)}
      data={options}
      defaultValue={defaultValue}
      onChange={(change) => {
        change && onChange?.(change);
        registration.onChange(change);
      }}
    />
  );
};

const MultiSelect = ({
  id,
  label,
  options,
  info,
  defaultValue,
  onChange,
  getInputProps,
  disabled,
}: MultiSelectConfig & {
  id: string;
  getInputProps: GetInputProps<any>;
}) => {
  const registration = getInputProps(id);
  useEffect(() => {
    if (defaultValue) {
      onChange?.(defaultValue);
      registration.onChange(defaultValue);
    }
  }, []);

  return (
    <MantineMultiSelect
      disabled={disabled}
      label={label}
      rightSection={
        info && (
          <Tooltip label={info}>
            <Text size="xs">
              <IconInfoCircle />
            </Text>
          </Tooltip>
        )
      }
      id={id}
      {...getInputProps(id)}
      data={options}
      defaultValue={defaultValue}
      onChange={(change) => {
        change && onChange?.(change);
        registration.onChange(change);
      }}
    />
  );
};

const Autocomplete = ({
  id,
  label,
  defaultValue,
  populate,
  info,
  getInputProps,
  itemComponent,
  transformResult,
  disabled,
}: AutocompleteConfig & {
  id: string;
  getInputProps: GetInputProps<any>;
  itemComponent?: FC<any>;
}) => {
  const [data, setData] = useState<AutocompleteItem[]>([]);
  const [value, setValue] = useState("");
  const [debouncedValue] = useDebouncedValue(value, 500, { leading: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (debouncedValue) {
      setLoading(true);
      populate(debouncedValue)
        .then(setData)
        .finally(() => setLoading(false));
    }
  }, [debouncedValue]);

  const props = getInputProps(id);
  return (
    <MantineAutocomplete
      disabled={disabled}
      label={
        <Group spacing={3}>
          <Text>{label}</Text>
          {info && (
            <Tooltip label={info}>
              <Text size="xs">
                <IconInfoCircle />
              </Text>
            </Tooltip>
          )}
        </Group>
      }
      data={data}
      defaultValue={defaultValue}
      filter={() => true}
      itemComponent={itemComponent}
      rightSection={loading && <Loader size="xs" />}
      {...props}
      onItemSubmit={(item) => {
        props.onChange(transformResult ? transformResult(item) : item);
      }}
      value={value}
      onChange={(data) => {
        setValue(data);
      }}
    />
  );
};

const RichTextEditorElement = ({
  id,
  label,
  info,
  defaultValue,
  getInputProps,
  disabled,
}: RichTextEditorConfig & {
  id: string;
  getInputProps: GetInputProps<any>;
}) => {
  const props = getInputProps(id);
  const editor = useEditor({
    editable: !disabled,
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],

    content: defaultValue,
    onUpdate: ({ editor }) => {
      props.onChange(editor.getHTML());
    },
  });

  return (
    <>
      <Text>{label}</Text>
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>

      {props.error && <Text color="red">{props.error}</Text>}
    </>
  );
};

const CodeEditor = ({
  id,
  label,
  info,
  defaultValue,
  getInputProps,
  disabled,
  language,
}: CodeEditorConfig & {
  id: string;
  getInputProps: GetInputProps<any>;
}) => {
  const props = getInputProps(id);
  return (
    <>
      <Editor
        language={language}
        height="70vh"
        theme="vs-dark"
        defaultValue={defaultValue}
        options={{
          selectOnLineNumbers: true,
        }}
        onChange={(value) => props.onChange(value)}
      />

      {props.error && <Text color="red">{props.error}</Text>}
    </>
  );
};

const COMPONENTS = {
  INPUT: Input,
  TEXTAREA: Textarea,
  SELECT: Select,
  MULTI_SELECT: MultiSelect,
  AUTOCOMPLETE: Autocomplete,
  CHECKBOX: Checkbox,
  DYNAMIC: Dynamic,
  RICH_TEXT_EDITOR: RichTextEditorElement,
  CODE_EDITOR: CodeEditor,
};

const DynamicComponent = ({ toRender }: { toRender: ReactElement }) => (
  <>{toRender}</>
);

export const CreationForm = <T extends Schema>({
  title,
  submitText,
  schemaDefinition,
  onSubmit,
}: {
  title?: string;
  submitText?: string;
  schemaDefinition: T;
  onSubmit: (datas: Record<keyof T, any>) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const schema = yup
    .object(
      Object.entries(schemaDefinition).reduce((acc, [key, value]) => {
        acc[key] = value.yupConfig;
        return acc;
      }, {} as ObjectShape),
    )
    .required();

  const {
    onSubmit: onSubmitForm,
    errors,
    getInputProps,
    values,
  } = useForm({
    validate: yupResolver(schema),
    initialValues: Object.entries(schemaDefinition).reduce(
      (acc, curr) => {
        acc[curr[0] as keyof T] = curr[1].defaultValue ?? "";
        return acc;
      },
      {} as Record<keyof T, string>,
    ),
  });

  Object.entries(schemaDefinition).forEach(([key, value]) => {
    if (value.dynamicSubtext) {
      const updatedValue = value.dynamicSubtext(values[key] as any);
      if (typeof updatedValue === "string") {
        value.subtext = updatedValue;
      }
    }
  });

  const submit = (data: any) => {
    setLoading(true);
    onSubmit(data).finally(() => !loading && setLoading(false));
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 w-full">
        <div
          className="w-full bg-white rounded-lg shadow dark:border xl:p-0 bg-card"
          style={{ maxHeight: "95%", overflowY: "scroll" }}
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            {title && (
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                {title}
              </h1>
            )}
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={onSubmitForm(submit)}
            >
              <Stack spacing={0}>
                {Object.entries(schemaDefinition).map(([key, value]) => (
                  <Fragment key={key}>
                    <DynamicComponent
                      toRender={COMPONENTS[value.type](
                        Object.assign(value, {
                          id: key,
                          getInputProps,
                          errors,
                        } as any),
                      )}
                    />
                    {value.subtext && <i>{value.subtext}</i>}
                  </Fragment>
                ))}
                <Button loading={loading} type="submit">
                  {submitText || "Send"}
                </Button>
              </Stack>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
