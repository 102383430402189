import { graphql } from "gql.tada";
import { useQuery } from "@apollo/client";

const GetMyAccountQuery = graphql(`
  query getMyAccount {
    getMyAccount {
      id
      email
      permissions {
        id
        resources
      }
    }
  }
`);

export const useCurrentAccount = () => {
  const { data, loading, error } = useQuery(GetMyAccountQuery, {
    fetchPolicy: "cache-and-network",
  });

  return { account: data, loading, error };
};
