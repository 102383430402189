import { gql, useQuery } from "@apollo/client";
import { Organization } from "../gql/graphql";
import { client } from "../apollo";
import {
  setSelectedOrganizationId,
  useSelectedOrganizationId,
} from "./OrganizationId";
import { graphql } from "gql.tada";

const OrganizationQuery = graphql(`
  query getOrganization($id: ID!) {
    organization(id: $id) {
      id
      identityProviders(login: true) {
        name
        id
      }
    }
  }
`);

export const useOrganization = () => {
  const organizationId = useSelectedOrganizationId();
  const { loading, error, data } = useQuery(OrganizationQuery, {
    variables: { id: organizationId ?? "" },
  });

  return { loading, error, data };
};

export const useOrganizationIdFromDomain = () => {
  const { loading, data, error } = useQuery<{
    findOrganizationFromDomain: Organization;
  }>(
    gql`
      query ($domain: String!) {
        findOrganizationFromDomain(domain: $domain) {
          id
        }
      }
    `,
    {
      client: client,
      variables: {
        domain: window.location.hostname,
      },
      onCompleted: (data) => {
        setSelectedOrganizationId(data.findOrganizationFromDomain.id);
      },
    },
  );

  return {
    id: data?.findOrganizationFromDomain?.id,
    loading,
    error,
  };
};
