export const TagDefinitions = [
  {
    tag: "favicon",
    description: "Change the favicon of the page",
    paramValue: {
      description: "The URL of the new favicon",
      required: true,
    },
  },
  {
    tag: "title",
    description: "Set the title of the page",
    paramValue: {
      description: "The new title of the page",
      required: true,
    },
  },
  {
    tag: "form",
    description: "Renders a dynamic form",
    paramValue: {
      description:
        "The type of form to render, available types: update-local-player-profile, register-tournament-team, confirm-tournament-team, confirm-tournament-attendance, delete-tournament-team",
      required: true,
    },
  },
  {
    tag: "stylesheet",
    description: "Injects a stylesheet into the head of the document",
    paramValue: {
      required: true,
      description: "The URL of the stylesheet to inject",
    },
  },
  {
    tag: "nav-link",
    description: "Create a link to a page",
    hashInputs: [
      {
        type: "string",
        name: "to",
        required: true,
        description: "The path to the page",
      },
    ],
  },
  {
    tag: "get-leaderboard",
    description: "Get a leaderboard by its ID",
    hashInputs: [
      {
        name: "id",
        type: "string",
        required: true,
        description: "Leaderboard id",
      },
    ],
    scopeOutputs: {
      leaderboard: {
        type: "object",
        description: "Retrieved leaderboard",
        required: true,
        items: {
          id: {
            type: "string",
            description: "Leaderboard id",
            required: true,
          },
          updatedAt: {
            type: "string",
            description: "Last update date",
            required: true,
          },
          createdAt: {
            type: "string",
            description: "Creation date",
            required: true,
          },
          description: {
            type: "string",
            description: "Leaderboard description",
            required: true,
          },
          name: {
            type: "string",
            description: "Leaderboard name",
            required: true,
          },
        },
      },
      currentLeaderboardSeason: {
        type: "object",
        description: "Current leaderboard season",
        required: true,
        items: {
          name: {
            type: "string",
            description: "Season name",
            required: true,
          },
          id: {
            type: "string",
            description: "Season id",
            required: true,
          },
          start: {
            type: "string",
            description: "Season start date",
            required: true,
          },
          end: {
            type: "string",
            description: "Season end date",
            required: true,
          },
          buckets: {
            type: "object",
            description: "Season buckets",
            required: true,
            items: {
              nodes: {
                type: "object",
                description: "Bucket nodes",
                required: true,
                isArray: true,
                items: {
                  id: {
                    type: "string",
                    description: "Bucket id",
                    required: true,
                  },
                  name: {
                    type: "string",
                    description: "Bucket name",
                    required: true,
                  },
                  minScore: {
                    type: "number",
                    description: "Minimum score",
                    required: true,
                  },
                  createdAt: {
                    type: "string",
                    description: "Creation date",
                    required: true,
                  },
                  updatedAt: {
                    type: "string",
                    description: "Last update date",
                    required: true,
                  },
                  scores: {
                    type: "object",
                    description: "Bucket scores",
                    required: true,
                    items: {
                      nodes: {
                        type: "object",
                        description: "Score nodes",
                        required: true,
                        isArray: true,
                        items: {
                          player: {
                            type: "object",
                            description: "Player",
                            required: true,
                            items: {
                              id: {
                                type: "string",
                                description: "Player id",
                                required: true,
                              },
                              username: {
                                type: "string",
                                description: "Player username",
                                required: true,
                              },
                              customFields: {
                                type: "object",
                                description: "Player custom fields",
                                required: true,
                                isArray: true,
                                items: {
                                  name: {
                                    type: "string",
                                    description: "Field name",
                                    required: true,
                                  },
                                  value: {
                                    type: "string",
                                    description: "Field value",
                                    required: true,
                                  },
                                  type: {
                                    type: "string",
                                    description: "Field type",
                                    required: true,
                                  },
                                },
                              },
                            },
                          },
                          rank: {
                            type: "number",
                            description: "Player rank",
                            required: true,
                          },
                          score: {
                            type: "number",
                            description: "Player score",
                            required: true,
                          },
                          lastRank: {
                            type: "number",
                            description: "Player last rank",
                            required: true,
                          },
                          lastScore: {
                            type: "number",
                            description: "Player last score",
                            required: true,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    tag: "list-tournaments",
    description: "List all tournaments",
    hashInputs: [
      {
        name: "status",
        description: "Filter by status",
        type: "string",
        required: false,
      },
      {
        name: "order-direction",
        description: "Order direction",
        type: "string",
        required: false,
      },
      {
        name: "order-by",
        description: "Order by",
        type: "string",
        required: false,
      },
      {
        name: "cursor",
        description: "Page cursor",
        type: "string",
        required: false,
      },
      {
        name: "count",
        description: "Count of items to return",
        type: "number",
        required: false,
      },
    ],
    scopeOutputs: {
      tournaments: {
        type: "object",
        required: true,
        description: "Tournament list info",
        items: {
          edges: {
            type: "object",
            required: true,
            description: "Edges",
            isArray: true,
            items: {
              cursor: {
                type: "string",
                required: true,
                description: "Cursor",
              },
            },
          },
          pageInfo: {
            type: "object",
            required: true,
            description: "Page info",
            items: {
              startCursor: {
                type: "string",
                required: true,
                description: "Start cursor",
              },
              endCursor: {
                type: "string",
                required: true,
                description: "End cursor",
              },
              hasNextPage: {
                type: "boolean",
                required: true,
                description: "Has next page",
              },
              hasPreviousPage: {
                type: "boolean",
                required: true,
                description: "Has previous page",
              },
            },
          },
          nodes: {
            type: "object",
            required: true,
            description: "Nodes",
            isArray: true,
            items: {
              id: {
                type: "string",
                required: true,
                description: "ID",
              },
              description: {
                type: "string",
                required: true,
                description: "Description",
              },
              title: {
                type: "string",
                required: true,
                description: "Title",
              },
              startAt: {
                type: "string",
                required: true,
                description: "Start at",
              },
              endAt: {
                type: "string",
                required: true,
                description: "End at",
              },
              startRegistrationsAt: {
                type: "string",
                required: true,
                description: "Start registrations at",
              },
              endRegistrationsAt: {
                type: "string",
                required: true,
                description: "End registrations at",
              },
              configuration: {
                type: "object",
                required: true,
                description: "Configuration",
                items: {
                  teamsCount: {
                    type: "number",
                    required: true,
                    description: "Teams count",
                  },
                  teamMinSize: {
                    type: "number",
                    required: true,
                    description: "Team min size",
                  },
                  teamMaxSize: {
                    type: "number",
                    required: true,
                    description: "Team max size",
                  },
                },
              },
              visibleAt: {
                type: "string",
                required: true,
                description: "Visible at",
              },
              createdAt: {
                type: "string",
                required: true,
                description: "Created at",
              },
              updatedAt: {
                type: "string",
                required: true,
                description: "Updated at",
              },
              createdBy: {
                type: "object",
                required: true,
                description: "Created by",
                items: {
                  profiles: {
                    type: "object",
                    required: true,
                    description: "Profiles",
                    isArray: true,
                    items: {
                      id: {
                        type: "string",
                        required: true,
                        description: "ID",
                      },
                      username: {
                        type: "string",
                        required: true,
                        description: "Username",
                      },
                      customFields: {
                        type: "object",
                        required: true,
                        isArray: true,
                        description: "Custom fields",
                        items: {
                          value: {
                            type: "string",
                            required: true,
                            description: "Value",
                          },
                          name: {
                            type: "string",
                            required: true,
                            description: "Name",
                          },
                          type: {
                            type: "string",
                            required: true,
                            description: "Type",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    tag: "get-tournament",
    hashInputs: [
      {
        name: "id",
        type: "string",
        required: true,
        description: "The id of the tournament to get",
      },
    ],
    description: "Get a tournament by id",
    scopeOutputs: {
      tournament: {
        type: "object",
        description: "The tournament",
        required: true,
        items: {
          id: {
            type: "string",
            description: "The id of the tournament",
            required: true,
          },
          title: {
            type: "string",
            description: "The title of the tournament",
            required: true,
          },
          startRegistrationsAt: {
            type: "string",
            description: "The date and time when registrations start",
            required: true,
          },
          endRegistrationsAt: {
            type: "string",
            description: "The date and time when registrations end",
            required: true,
          },
          startAt: {
            type: "string",
            description: "The date and time when the tournament starts",
            required: true,
          },
          endAt: {
            type: "string",
            description: "The date and time when the tournament ends",
            required: true,
          },
          visibleAt: {
            type: "string",
            description:
              "The date and time when the tournament becomes visible",
            required: true,
          },
          updatedAt: {
            type: "string",
            description:
              "The date and time when the tournament was last updated",
            required: true,
          },
          createdAt: {
            type: "string",
            description: "The date and time when the tournament was created",
            required: true,
          },
          description: {
            type: "string",
            description: "The description of the tournament",
            required: true,
          },
          createdBy: {
            type: "object",
            description: "The user who created the tournament",
            required: true,
            items: {
              profiles: {
                type: "object",
                description:
                  "The profiles of the user who created the tournament",
                required: true,
                isArray: true,
                items: {
                  id: {
                    type: "string",
                    description: "The id of the profile",
                    required: true,
                  },
                  username: {
                    type: "string",
                    description: "The username of the profile",
                    required: true,
                  },
                  customFields: {
                    type: "object",
                    description: "The custom fields of the profile",
                    required: true,
                    isArray: true,
                    items: {
                      name: {
                        type: "string",
                        description: "The name of the custom field",
                        required: true,
                      },
                      value: {
                        type: "string",
                        description: "The value of the custom field",
                        required: true,
                      },
                      type: {
                        type: "string",
                        description: "The type of the custom field",
                        required: true,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    tag: "list-tournament-teams",
    description: "List tournament teams",
    hashInputs: [
      {
        name: "tournament-id",
        required: true,
        type: "string",
        description: "Tournament id",
      },
      {
        name: "status",
        required: false,
        type: "string",
        description: "Tournament team status",
      },
      {
        name: "cursor",
        required: false,
        type: "string",
        description: "Pagination cursor",
      },
      {
        name: "count",
        required: false,
        type: "number",
        description: "Number of items to return",
      },
    ],
    scopeOutputs: {
      tournamentTeams: {
        type: "object",
        required: true,
        description: "Tournament teams",
        items: {
          pageInfo: {
            type: "object",
            required: true,
            description: "Page info",
            items: {
              startCursor: {
                type: "string",
                required: true,
                description: "Start cursor",
              },
              endCursor: {
                type: "string",
                required: true,
                description: "End cursor",
              },
              hasNextPage: {
                type: "boolean",
                required: true,
                description: "Has next page",
              },
              hasPreviousPage: {
                type: "boolean",
                required: true,
                description: "Has previous page",
              },
            },
          },
          edges: {
            type: "object",
            required: true,
            description: "Edges",
            isArray: true,
            items: {
              cursor: {
                type: "string",
                required: true,
                description: "Cursor",
              },
            },
          },
          nodes: {
            type: "object",
            required: true,
            description: "Nodes",
            isArray: true,
            items: {
              id: {
                type: "string",
                required: true,
                description: "Id",
              },
              name: {
                type: "string",
                required: true,
                description: "Name",
              },
              createdAt: {
                type: "string",
                required: true,
                description: "Created at",
              },
              updatedAt: {
                type: "string",
                required: true,
                description: "Updated at",
              },
              status: {
                type: "string",
                required: true,
                description: "Status",
              },
              customFields: {
                type: "object",
                required: true,
                description: "Custom fields",
                isArray: true,
                items: {
                  value: {
                    type: "string",
                    required: true,
                    description: "Value",
                  },
                  property: {
                    type: "string",
                    required: true,
                    description: "Property",
                  },
                  order: {
                    type: "number",
                    required: true,
                    description: "Order",
                  },
                  type: {
                    type: "string",
                    required: true,
                    description: "Type",
                  },
                  name: {
                    type: "string",
                    required: true,
                    description: "Name",
                  },
                },
              },
              members: {
                type: "object",
                required: true,
                description: "Members",
                isArray: true,
                items: {
                  player: {
                    type: "object",
                    required: true,
                    description: "Player",
                    items: {
                      id: {
                        type: "string",
                        required: true,
                        description: "Id",
                      },
                      username: {
                        type: "string",
                        required: true,
                        description: "Username",
                      },
                      customFields: {
                        type: "object",
                        required: true,
                        description: "Custom fields",
                        isArray: true,
                        items: {
                          value: {
                            type: "string",
                            required: true,
                            description: "Value",
                          },
                          name: {
                            type: "string",
                            required: true,
                            description: "Name",
                          },
                          type: {
                            type: "string",
                            required: true,
                            description: "Type",
                          },
                        },
                      },
                    },
                  },
                },
              },
              tag: {
                type: "string",
                required: true,
                description: "Tag",
              },
            },
          },
          totalCount: {
            type: "number",
            required: true,
            description: "Total count",
          },
        },
      },
    },
  },
  {
    tag: "list-tournament-steps",
    description: "List all steps of a tournament",
    hashInputs: [
      {
        name: "tournament-id",
        type: "string",
        required: true,
        description: "The id of the tournament",
      },
    ],
    scopeOutputs: {
      tournamentSteps: {
        type: "object",
        description: "The list of steps of the tournament",
        isArray: true,
        required: true,
        items: {
          id: {
            type: "string",
            description: "The id of the step",
            required: true,
          },
          name: {
            type: "string",
            description: "The name of the step",
            required: true,
          },
          status: {
            type: "string",
            description: "The status of the step",
            required: true,
          },
          type: {
            type: "string",
            description: "The type of the step",
            required: true,
          },
          order: {
            type: "number",
            description: "The order of the step",
            required: true,
          },
          description: {
            type: "string",
            description: "The description of the step",
            required: true,
          },
          createdAt: {
            type: "string",
            description: "The creation date of the step",
            required: true,
          },
          updatedAt: {
            type: "string",
            description: "The last update date of the step",
            required: true,
          },
        },
      },
    },
  },
  {
    tag: "get-tournament-step",
    description: "Get a tournament step by id",
    hashInputs: [
      {
        name: "id",
        required: true,
        type: "string",
        description: "The id of the tournament step",
      },
    ],
    scopeOutputs: {
      tournamentStep: {
        type: "object",
        description: "The tournament step",
        required: true,
        items: {
          id: {
            type: "string",
            description: "The id of the tournament step",
            required: true,
          },
          name: {
            type: "string",
            description: "The name of the tournament step",
            required: true,
          },
          status: {
            type: "string",
            description: "The status of the tournament step",
            required: true,
          },
          type: {
            type: "string",
            description: "The type of the tournament step",
            required: true,
          },
          order: {
            type: "number",
            description: "The order of the tournament step",
            required: true,
          },
          description: {
            type: "string",
            description: "The description of the tournament step",
            required: true,
          },
          createdAt: {
            type: "string",
            description: "The creation date of the tournament step",
            required: true,
          },
          updatedAt: {
            type: "string",
            description: "The update date of the tournament step",
            required: true,
          },
          configuration: {
            type: "object",
            description: "The configuration of the tournament step",
            required: true,
            items: {
              groups: {
                type: "object",
                description: "The groups of the tournament step",
                required: true,
                isArray: true,
                items: {
                  name: {
                    type: "string",
                    description: "The name of the group",
                    required: true,
                  },
                  description: {
                    type: "string",
                    description: "The description of the group",
                    required: true,
                  },
                  rounds: {
                    type: "object",
                    description: "The rounds of the group",
                    required: true,
                    isArray: true,
                    items: {
                      name: {
                        type: "string",
                        description: "The name of the round",
                        required: true,
                      },
                      order: {
                        type: "number",
                        description: "The order of the round",
                        required: true,
                      },
                      game: {
                        type: "object",
                        description: "The game of the round",
                        required: true,
                        items: {
                          teamsCount: {
                            type: "number",
                            description: "The count of teams in the game",
                            required: true,
                          },
                          useMatchScoresAsGameScore: {
                            type: "boolean",
                            description:
                              "The use of match scores as game score",
                            required: true,
                          },
                          wonMatchCountToWinGame: {
                            type: "number",
                            description:
                              "The count of won matches to win the game",
                            required: true,
                          },
                          matchConfiguration: {
                            type: "object",
                            description: "The configuration of the match",
                            required: true,
                            items: {
                              scoreFormula: {
                                type: "string",
                                description: "The formula of the score",
                                required: true,
                              },
                              variables: {
                                type: "object",
                                description: "The variables of the score",
                                required: true,
                                isArray: true,
                                items: {
                                  defaultValue: {
                                    type: "number",
                                    description:
                                      "The default value of the variable",
                                    required: true,
                                  },
                                  displayIcon: {
                                    type: "string",
                                    description:
                                      "The display icon of the variable",
                                    required: true,
                                  },
                                  displayName: {
                                    type: "string",
                                    description:
                                      "The display name of the variable",
                                    required: true,
                                  },
                                  formulaName: {
                                    type: "string",
                                    description:
                                      "The formula name of the variable",
                                    required: true,
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    tag: "get-player",
    description: "Get a player by id",
    hashInputs: [
      {
        name: "id",
        type: "string",
        required: true,
      },
    ],
    scopeOutputs: {
      player: {
        type: "object",
        description: "The player",
        required: true,
        items: {
          id: {
            type: "string",
            description: "The player id",
            required: true,
          },
          description: {
            type: "string",
            description: "The player description",
            required: true,
          },
          username: {
            type: "string",
            description: "The player username",
            required: true,
          },
          customFields: {
            type: "object",
            description: "The player custom fields",
            required: true,
            isArray: true,
            items: {
              type: {
                type: "string",
                description: "The custom field type",
                required: true,
              },
              name: {
                type: "string",
                description: "The custom field name",
                required: true,
              },
              property: {
                type: "string",
                description: "The custom field property",
                required: true,
              },
              value: {
                type: "string",
                description: "The custom field value",
                required: true,
              },
              order: {
                type: "number",
                description: "The custom field order",
                required: true,
              },
            },
          },
          updatedAt: {
            type: "string",
            description: "The player updated at",
            required: true,
          },
          createdAt: {
            type: "string",
            description: "The player created at",
            required: true,
          },
        },
      },
    },
  },
  {
    tag: "get-player-matches-history",
    description: "Get player matches history",
    hashInputs: [
      {
        name: "id",
        type: "string",
        description: "Player id",
        required: true,
      },
      {
        name: "cursor",
        type: "string",
        description: "Cursor",
        required: false,
      },
      {
        name: "count",
        type: "number",
        description: "Count",
        required: false,
      },
    ],
    scopeOutputs: {
      playerMatchesHistory: {
        type: "object",
        required: true,
        description: "Player matches history",
        items: {
          edges: {
            type: "object",
            required: true,
            description: "Edges",
            isArray: true,
            items: {
              cursor: {
                type: "string",
                required: true,
                description: "Cursor",
              },
            },
          },
          pageInfo: {
            type: "object",
            required: true,
            description: "Page info",
            items: {
              startCursor: {
                type: "string",
                required: true,
                description: "Start cursor",
              },
              endCursor: {
                type: "string",
                required: true,
                description: "End cursor",
              },
              hasNextPage: {
                type: "boolean",
                required: true,
                description: "Has next page",
              },
              hasPreviousPage: {
                type: "boolean",
                required: true,
                description: "Has previous page",
              },
            },
          },
          nodes: {
            type: "object",
            required: true,
            description: "Nodes",
            isArray: true,
            items: {
              id: {
                type: "string",
                required: true,
                description: "string",
              },
              status: {
                type: "string",
                required: true,
                description: "Status",
              },
              scores: {
                type: "object",
                required: true,
                description: "Scores",
                isArray: true,
                items: {
                  score: {
                    type: "number",
                    required: true,
                    description: "Score",
                  },
                  team: {
                    type: "object",
                    required: true,
                    description: "Team",
                    items: {
                      name: {
                        type: "string",
                        required: true,
                        description: "Name",
                      },
                      customFields: {
                        type: "object",
                        required: true,
                        description: "Custom fields",
                        isArray: true,
                        items: {
                          name: {
                            type: "string",
                            required: true,
                            description: "Name",
                          },
                          value: {
                            type: "string",
                            required: true,
                            description: "Value",
                          },
                          type: {
                            type: "string",
                            required: true,
                            description: "Type",
                          },
                          order: {
                            type: "number",
                            required: true,
                            description: "Order",
                          },
                        },
                      },
                    },
                  },
                  status: {
                    type: "string",
                    required: true,
                    description: "Status 2",
                  },
                },
              },
              game: {
                type: "object",
                required: true,
                description: "Game",
                items: {
                  configuration: {
                    type: "object",
                    required: true,
                    description: "Configuration",
                    items: {
                      wonMatchCountToWinGame: {
                        type: "number",
                        required: true,
                        description: "Won match count to win game",
                      },
                    },
                  },
                  round: {
                    type: "object",
                    required: true,
                    description: "Round",
                    items: {
                      group: {
                        type: "object",
                        required: true,
                        description: "Group",
                        items: {
                          step: {
                            type: "object",
                            required: true,
                            description: "Step",
                            items: {
                              tournament: {
                                type: "object",
                                required: true,
                                description: "Tournament",
                                items: {
                                  id: {
                                    type: "string",
                                    required: true,
                                    description: "Id",
                                  },
                                  title: {
                                    type: "string",
                                    required: true,
                                    description: "Title",
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  teamScores: {
                    type: "object",
                    required: true,
                    description: "Team scores",
                    isArray: true,
                    items: {
                      score: {
                        type: "number",
                        required: true,
                        description: "Score",
                      },
                      team: {
                        type: "object",
                        required: true,
                        description: "Team",
                        items: {
                          id: {
                            type: "string",
                            required: true,
                            description: "Id",
                          },
                          name: {
                            type: "string",
                            required: true,
                            description: "Name",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
];
