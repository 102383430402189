import { gql } from "@apollo/client";
import { AutoDataTable } from "../../components/AutoDataTable";
import { Checkbox, Stack, Switch, Text, Title } from "@mantine/core";
import { useState } from "react";

export const PlayersList = () => {
  const [showAnonmymous, setShowAnonymous] = useState(false);

  return (
    <>
      <Switch
        checked={showAnonmymous}
        onChange={(event) => setShowAnonymous(event.currentTarget.checked)}
        label="Anonymous players"
      />
      <AutoDataTable
        itemPath=".."
        createButtonText={false}
        queryVariables={{
          anonymous: showAnonmymous,
        }}
        query={gql`
          query ($cursor: String, $count: Float, $anonymous: Boolean) {
            players(
              page: { first: $count, after: $cursor }
              query: { anonymous: $anonymous }
              identityProviders: []
              identityProviderProperties: []
            ) {
              nodes {
                id
                username
              }
              totalCount
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        `}
        deleteMutation={gql`
          mutation ($id: ID!) {
            deletePlayer(id: $id)
          }
        `}
        columns={[
          {
            accessor: "username",
            title: "Username",
          },
        ]}
      />
    </>
  );
};

export const PlayersHome = () => {
  return (
    <Stack>
      <Title>Players</Title>
      <Text>
        <Text weight={700} span>
          Players profiles
        </Text>{" "}
        are the primary representation of your players.
        <br />
        They can either be created and linked to an account or have anonymous
        profiles (profiles that are not linked to an account).
        <br />
        They are useful to store metadata about the players, and it is through
        their IDs that players can interact with other systems.
        <br />
      </Text>
      <Text>
        <Title order={3}>Accounts and Player Profiles</Title>
        An account is created when a player uses the in-platform signin options.
        <br />
        Accounts are mainly used to authenticate users across WellPlayed
        endpoints, they are only used to provide permissions through the user
        and permissions management system.
        <br />
        An account can be linked to multiple Player Profiles and must have, at
        least, one player profile attached to be able to interact with most of
        WellPlayed systems.
        <br />
      </Text>
      <Text>
        <Title order={3}>Anonymous Player Profiles</Title>
        It is also possible to create Anonymous Player Profiles, meaning that
        the Player Profile is not linked to an account.
        <br />
        This is useful if you want to control (through the API for example, or
        the tournament management console) the creation of the Player Profile.
        <br />
        Player Profiles provide a field organizationCustomId which allows you to
        set an external custom ID to the Player Profile (if you are using
        external accounts for example) and provide a way to retrieve the Player
        Profile directly through the organizationCustomId variable.
        <br />
        Player profiles can be linked to multiple other platform resources, such
        as Identities, Skill Ratings, Tournament Teams, Achievements, etc.
      </Text>
      <Text>
        <Title>Identity Providers</Title>
        <Text weight={700} span>
          Identity Providers
        </Text>{" "}
        allows you to link OAuth2 or OpenID Connect providers to your
        organization.
        <br />
        This allows you to link your WellPlayed platform users to other accounts
        they may have on other platforms.
        <br />
        Identity Providers can be also used to authenticate your users.
        <br />
        For example, you can link your Steam account to your WellPlayed account
        to allow you to login with Steam.
      </Text>
    </Stack>
  );
};
