import {
  Anchor,
  Card,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconTrophy } from "@tabler/icons-react";
import { gql, useQuery } from "@apollo/client";
import { Leaderboard, LeaderboardSeason } from "../../../../gql/graphql";
import { Link, useParams } from "react-router-dom";

export const LeaderboardDashboard = () => {
  const leaderboardId = useParams().leaderboardId;
  const { data, loading } = useQuery<{
    leaderboard: Leaderboard;
  }>(
    gql`
      query ($id: ID!) {
        leaderboard(id: $id) {
          id
          name
          description
        }
      }
    `,
    { variables: { id: leaderboardId } },
  );
  const { data: currentLeaderboardSeason, loading: currentLeaderboardLoading } =
    useQuery<{
      currentLeaderboardSeason: LeaderboardSeason;
    }>(
      gql`
        query ($id: ID!) {
          currentLeaderboardSeason(leaderboardId: $id) {
            name
            start
            end
          }
        }
      `,
      { variables: { id: leaderboardId } },
    );

  return (
    <>
      <Group>
        <Title>Dashboard</Title>
      </Group>
      <SimpleGrid cols={2}>
        <Card pt={5} p={30}>
          {(loading && <Loader />) || (
            <>
              <Card.Section>
                <Group spacing={10}>
                  <IconTrophy />
                  <Text size="md" weight={700}>
                    {data?.leaderboard.name}
                  </Text>
                </Group>
              </Card.Section>
              <Text>{data?.leaderboard.description}</Text>
            </>
          )}
        </Card>
        <Card pt={5} p={30}>
          {(currentLeaderboardLoading && <Loader />) || (
            <>
              <Card.Section>
                <Group spacing={10}>
                  <Text size="md" weight={700}>
                    Active season
                  </Text>
                </Group>
              </Card.Section>
              {(currentLeaderboardSeason?.currentLeaderboardSeason && (
                <Stack spacing={10}>
                  <Anchor component={Link} to={"current"}>
                    {currentLeaderboardSeason?.currentLeaderboardSeason.name}
                  </Anchor>
                  <SimpleGrid cols={2}>
                    <Stack>
                      <Text weight={700}>Starting date</Text>
                      <Text>
                        {(currentLeaderboardSeason?.currentLeaderboardSeason
                          ?.start && (
                          <Text>
                            {new Date(
                              currentLeaderboardSeason?.currentLeaderboardSeason
                                ?.start,
                            ).toLocaleString()}
                          </Text>
                        )) || <Text>No starting date set yet</Text>}
                      </Text>
                    </Stack>
                    <Stack>
                      <Text weight={700}>Ending date</Text>
                      <Text>
                        {(currentLeaderboardSeason?.currentLeaderboardSeason
                          ?.end && (
                          <Text>
                            {new Date(
                              currentLeaderboardSeason?.currentLeaderboardSeason
                                ?.end,
                            ).toLocaleString()}
                          </Text>
                        )) || <Text>No ending date set yet</Text>}
                      </Text>
                    </Stack>
                  </SimpleGrid>
                </Stack>
              )) || (
                <Text>
                  No active season
                  <br />
                  <Anchor component={Link} to="seasons">
                    Create a new season
                  </Anchor>
                </Text>
              )}
            </>
          )}
        </Card>
      </SimpleGrid>
    </>
  );
};
