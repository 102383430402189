import { gql, useMutation, useQuery } from "@apollo/client";
import { OrganizationApp, OrganizationApps } from "../../../../../gql/graphql";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { CreationForm } from "../../../components/form/CreationForm";
import * as yup from "yup";
import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  Loader,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { IconCopy, IconKey, IconTrash } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { useClipboard } from "@mantine/hooks";

const URL_REGEX =
  /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

export const EditApp = () => {
  const navigate = useNavigate();
  const clipboard = useClipboard();
  const appId = useParams().appId;
  const [createApp] = useMutation<{
    createOrganizationApp: { id: string; secret: string };
  }>(gql`
    mutation (
      $name: String!
      $consentUrl: String!
      $logoutRedirectUrls: [String!]!
      $description: String!
      $loginUrl: String!
      $redirectUrls: [String!]!
      $requiresConsent: Boolean!
      $public: Boolean!
    ) {
      createOrganizationApp(
        input: {
          name: $name
          consentUrl: $consentUrl
          logoutRedirectUrls: $logoutRedirectUrls
          description: $description
          loginUrl: $loginUrl
          redirectUrls: $redirectUrls
          requiresConsent: $requiresConsent
          public: $public
        }
      ) {
        id
        secret
      }
    }
  `);
  const [updateApp] = useMutation(gql`
    mutation (
      $id: String!
      $name: String!
      $consentUrl: String!
      $logoutRedirectUrls: [String!]!
      $description: String!
      $loginUrl: String!
      $redirectUrls: [String!]!
      $requiresConsent: Boolean!
    ) {
      updateOrganizationApp(
        id: $id
        input: {
          name: $name
          consentUrl: $consentUrl
          logoutRedirectUrls: $logoutRedirectUrls
          description: $description
          loginUrl: $loginUrl
          redirectUrls: $redirectUrls
          requiresConsent: $requiresConsent
        }
      ) {
        id
      }
    }
  `);
  const { data, loading } = useQuery<{
    organizationApp: OrganizationApp;
  }>(
    gql`
      query ($id: String!) {
        organizationApp(id: $id) {
          id
          name
          description
          public
          configuration {
            logoutRedirectUrls
            metadata {
              requiresConsent
              loginUrl
              consentUrl
            }
            redirectUrls
          }
        }
      }
    `,
    {
      variables: {
        id: appId,
      },
      skip: !appId,
    },
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title={appId ? "Update app" : "Add app"}
      schemaDefinition={{
        name: {
          type: "INPUT",
          inputType: "text",
          label: "Name",
          yupConfig: yup.string().required(),
          defaultValue: data?.organizationApp.name,
          placeholder: "My app",
        },
        description: {
          type: "TEXTAREA",
          label: "Description",
          defaultValue: data?.organizationApp.description,
          yupConfig: yup.string().required(),
        },
        loginUrl: {
          type: "INPUT",
          inputType: "text",
          label: "Login URL",
          defaultValue: data?.organizationApp.configuration.metadata.loginUrl,
          yupConfig: yup
            .string()
            .matches(URL_REGEX)
            .required()
            .typeError("Must be a valid URL"),
          placeholder: "https://myapp.com/login",
        },
        consentUrl: {
          type: "INPUT",
          inputType: "text",
          label: "Consent URL",
          defaultValue: data?.organizationApp.configuration.metadata.consentUrl,
          yupConfig: yup
            .string()
            .matches(URL_REGEX)
            .required()
            .typeError("Must be a valid URL"),
          placeholder: "https://myapp.com/consent",
        },
        logoutRedirectUrls: {
          type: "INPUT",
          inputType: "text",
          label: "Logout redirect URL",
          defaultValue:
            data?.organizationApp.configuration.logoutRedirectUrls[0],
          yupConfig: yup
            .string()
            .matches(URL_REGEX)
            .required()
            .typeError("Must be a valid URL"),
          placeholder: "https://myapp.com/logout",
        },
        redirectUrls: {
          type: "INPUT",
          inputType: "text",
          label: "Redirect URL",
          subtext:
            "URL where users will be redirected after successful authentication.It is important to note that the redirect URL is called ONLY if there are more than 2 identity providers set as 'login allowed' to true, because we are not able to know which one to use for the login process, if only one is available, this URL redirect will be skipped. If you have more than 1 identity provider set to 'allowed login', you will need to manage the login page yourself to allow for the identity provider selection",
          defaultValue: data?.organizationApp.configuration.redirectUrls[0],
          yupConfig: yup
            .string()
            .matches(URL_REGEX)
            .required()
            .typeError("Must be a valid URL"),
          placeholder: "https://myapp.com/redirect",
        },
        requiresConsent: {
          type: "CHECKBOX",
          label: "Requires consent",
          defaultValue:
            data?.organizationApp.configuration.metadata.requiresConsent ??
            false,
          yupConfig: yup.boolean().default(false),
        },
        ...(!appId && {
          public: {
            type: "CHECKBOX",
            label: "Public app",
            defaultValue: data?.organizationApp.public ?? false,
            yupConfig: yup.boolean().default(false),
            info: (
              <>
                If checked, this app will allow for "Authorization Code Grant"
                <br />
                (i.e allows for your users to login), set to false if you want
                to
                <br />
                use "Client Credentials Grant" (i.e. allows for your app to
                login
                <br />
                as itself for machine to machine communication)
              </>
            ),
          },
        }),
      }}
      onSubmit={async (data) => {
        await new Promise(async (resolve) => {
          if (appId) {
            await updateApp({
              variables: {
                id: appId,
                name: data.name,
                description: data.description,
                loginUrl: data.loginUrl,
                consentUrl: data.consentUrl,
                logoutRedirectUrls: [data.logoutRedirectUrls],
                redirectUrls: [data.redirectUrls],
                requiresConsent: data.requiresConsent,
              },
            }).then((data) => {
              if (data?.errors && data.errors.length > 0) {
                throw new Error(data.errors[0].message);
              }
            });
            resolve(undefined);
          } else {
            const createdApp = await createApp({
              variables: {
                name: data.name,
                description: data.description,
                loginUrl: data.loginUrl,
                consentUrl: data.consentUrl,
                logoutRedirectUrls: [data.logoutRedirectUrls],
                redirectUrls: [data.redirectUrls],
                requiresConsent: data.requiresConsent,
                public: data.public,
              },
            }).then((data) => {
              if (data?.errors && data.errors.length > 0) {
                throw new Error(data.errors[0].message);
              }

              return data.data?.createOrganizationApp;
            });
            modals.open({
              title: "App created",
              onClose() {
                navigate("../" + createdApp?.id);
              },
              children: (
                <Stack>
                  <Text>Your app has been created!</Text>
                  <Group>
                    <Text weight={700}>App ID:</Text> {createdApp?.id}{" "}
                    <ActionIcon
                      onClick={() => {
                        clipboard.copy(createdApp?.id ?? "");
                      }}
                    >
                      <IconCopy />
                    </ActionIcon>
                  </Group>
                  <Text>
                    <Anchor
                      onClick={() => {
                        clipboard.copy(createdApp?.secret ?? "");
                      }}
                    >
                      Click here to copy your app secret
                    </Anchor>
                  </Text>
                  <Text color="red">
                    Please note that this is the only time you will be able to
                    see your app secret, so make sure to copy it somewhere safe.
                    <br />
                    You will be able to reset your app secret at any time
                    through the app settings.
                  </Text>
                </Stack>
              ),
            });
          }
        });
      }}
    />
  );
};

export const Apps = () => {
  const navigate = useNavigate();
  const clipboard = useClipboard();
  const { loading, data, refetch } = useQuery<{
    organizationApps: OrganizationApps;
  }>(gql`
    query {
      organizationApps(page: { first: 100 }) {
        nodes {
          id
          name
          public
          creator {
            id
            email
            profiles {
              username
            }
          }
        }
      }
    }
  `);
  const [deleteApp] = useMutation(gql`
    mutation ($id: String!) {
      deleteOrganizationApp(id: $id)
    }
  `);
  const [resetAppSecret] = useMutation(gql`
    mutation ($id: String!) {
      resetOrganizationAppSecret(id: $id)
    }
  `);

  const deleteItem = async (id: string) => {
    if (confirm("Are you sure you want to delete this app?")) {
      await deleteApp({ variables: { id } });
      await refetch();
    }
  };

  return (
    <>
      <Button onClick={() => navigate("../new")}>Create new application</Button>
      <DataTable
        columns={[
          {
            title: "Name",
            accessor: "name",
            render: (item) => (
              <Anchor component={NavLink} to={`../${item.id}`}>
                {item.name}
              </Anchor>
            ),
          },
          { title: "Client ID", accessor: "id" },
          {
            title: "Creator Name/ID",
            accessor: "creator",
            render: (item) => (
              <>
                {item.creator.profiles[0]?.username ??
                  item.creator.email ??
                  item.creator.id}
              </>
            ),
          },
          {
            title: "Public",
            accessor: "public",
            render: (item) => <>{item.public ? "Yes" : "No"}</>,
          },
          {
            title: "",
            accessor: "",
            render: (item) => (
              <Stack>
                <Tooltip label="Delete app">
                  <ActionIcon onClick={() => deleteItem(item.id)}>
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Reset app secret">
                  <ActionIcon
                    onClick={async () => {
                      if (
                        confirm(
                          "Are you sure you want to reset this app's secret?",
                        )
                      ) {
                        const newAppSecret = await resetAppSecret({
                          variables: { id: item.id },
                        }).then((data) => {
                          if (data?.errors && data.errors.length > 0) {
                            throw new Error(data.errors[0].message);
                          }

                          return data;
                        });
                        modals.open({
                          title: "App secret reset",
                          children: (
                            <Stack>
                              <Text>Your app secret has been reset!</Text>
                              <Group>
                                <Anchor
                                  onClick={() => {
                                    clipboard.copy(
                                      newAppSecret.data
                                        ?.resetOrganizationAppSecret ?? "",
                                    );
                                  }}
                                >
                                  <Text weight={700}>
                                    Click here to copy your new app secret
                                  </Text>
                                </Anchor>
                              </Group>
                              <Text color="red">
                                Please note that this is the only time you will
                                be able to see your app secret, so make sure to
                                copy it somewhere safe.
                                <br />
                                You will be able to reset your app secret at any
                                time through the app settings.
                              </Text>
                            </Stack>
                          ),
                        });
                      }
                    }}
                  >
                    <IconKey />
                  </ActionIcon>
                </Tooltip>
              </Stack>
            ),
          },
        ]}
        fetching={loading}
        records={data?.organizationApps.nodes}
      />
    </>
  );
};

export const AppsHome = () => {
  return (
    <Stack>
      <Title>Applications</Title>
      <Text>
        WellPlayed API requires{" "}
        <Text weight={700} span>
          access tokens
        </Text>{" "}
        to access resources.
        <br />
        Applications are{" "}
        <Anchor href="https://oauth.net/2/" target="_blank">
          OAuth2 Standard Applications
        </Anchor>{" "}
        that can be used to generate such tokens.
      </Text>
      <Text>
        Depending on the resource and the way you want to access it you'll need
        either a{" "}
        <Text weight={700} span>
          user token
        </Text>{" "}
        or an{" "}
        <Text weight={700} span>
          application token
        </Text>
        .
      </Text>
      <Text>
        <Text span weight={700}>
          User tokens
        </Text>{" "}
        are used to access resources on behalf of a user. They are obtained by
        authenticating a user through the{" "}
        <Anchor
          href="https://oauth.net/2/grant-types/authorization-code/"
          target="_blank"
        >
          Authorization Code Flow
        </Anchor>
        .<br />
        Depending on the scope of the token, and the user permissions it can
        access and update different resources.
        <br />
        User permissions can be managed through <Anchor href="/iam">IAM</Anchor>
        .<br />
        It is recommended to use{" "}
        <Anchor href="https://oauth.net/2/pkce/" target="_blank">
          PKCE
        </Anchor>{" "}
        when authenticating users.
      </Text>
      <Text>
        <Text span weight={700}>
          Application tokens
        </Text>{" "}
        are used to access resources on behalf of an application. They are
        obtained by authenticating an application by using the{" "}
        <Anchor
          href="https://oauth.net/2/grant-types/client-credentials/"
          target="_blank"
        >
          Client Credentials Flow
        </Anchor>
        .<br />
        Depending on the scope of the token, and the application permissions it
        can access and update different resources.
        <br />
        Application permissions can be managed through{" "}
        <Anchor href="/iam">IAM</Anchor>.
      </Text>
    </Stack>
  );
};
