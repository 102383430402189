import { AutoDataTable } from "../../components/AutoDataTable";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Platform } from "../../../../gql/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { graphql } from "gql.tada";
import { Anchor, Loader } from "@mantine/core";
import { CreationForm } from "../../components/form/CreationForm";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";

const GetPlatformQuery = graphql(`
  query platformWithDomains($id: ID!) {
    platform(platformId: $id) {
      id
      name
      configuration {
        whitelabel
      }
      customDomains {
        domain
      }
    }
  }
`);

const PlatformUpdateMutation = graphql(`
  mutation platformUpdate($id: ID!, $input: UpdatePlatformInput!) {
    platformUpdate(id: $id, input: $input) {
      id
    }
  }
`);

const PlatformCreateMutation = graphql(`
  mutation platformCreate($input: CreatePlatformInput!) {
    platformCreate(input: $input) {
      id
    }
  }
`);

export const CreateOrUpdatePlatform = () => {
  const navigate = useNavigate();
  let platformId = useParams().platformId as string;
  const { data, loading } = useQuery(GetPlatformQuery, {
    skip: !platformId,
    variables: {
      id: platformId,
    },
  });
  const [platformUpdate] = useMutation(PlatformUpdateMutation);
  const [platformCreate] = useMutation(PlatformCreateMutation);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title={platformId ? "Update platform" : "Create platform"}
      schemaDefinition={{
        name: {
          type: "INPUT",
          label: "Name",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.platform?.name,
          placeholder: "Enter the platform name",
        },
        whitelabel: {
          type: "CHECKBOX",
          label: "Whitelabel",
          yupConfig: yup.boolean().default(false),
          defaultValue: data?.platform?.configuration?.whitelabel ?? false,
          info: "Enable whitelabel to remove WellPlayed branding",
        },
        ...(!platformId && {
          subdomain: {
            type: "INPUT",
            label: "Subdomain",
            yupConfig: yup
              .string()
              .min(3)
              .max(25)
              .matches(/^(?=.{3,25}$)[A-Za-z][A-Za-z-]*[A-Za-z]$/)
              .required(),
            inputType: "text",
            defaultValue: data?.platform?.customDomains[0]?.domain,
            placeholder: "Enter the platform subdomain",
            dynamicSubtext: (domain: string) => `${
              domain?.length > 0 ? domain : "your-subdomain"
            }
            .well-played.gg`,
          },
        }),
      }}
      onSubmit={async (values) => {
        if (platformId) {
          await platformUpdate({
            variables: {
              id: platformId,
              input: {
                name: values.name,
                configuration: {
                  whitelabel: values.whitelabel,
                },
              },
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
          notifications.show({
            title: "Platform updated",
            message: "The platform has been updated",
            color: "green",
            autoClose: 3000,
          });
        } else {
          platformId = await platformCreate({
            variables: {
              input: {
                name: values.name,
                subdomain: values.subdomain,
                configuration: { whitelabel: values.whitelabel },
              },
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
            return data.data?.platformCreate.id as string;
          });
          navigate("/platform/" + platformId);
        }
      }}
    />
  );
};

export const Platforms = () => {
  return (
    <AutoDataTable
      createButtonText="New platform"
      itemPath=".."
      deleteMutation={gql`
        mutation ($id: ID!) {
          platformDelete(id: $id)
        }
      `}
      query={gql`
        query {
          platforms {
            id
            name
            createdAt
            updatedAt
            customDomains {
              domain
            }
          }
        }
      `}
      columns={[
        {
          accessor: "name",
          title: "Name",
        },
        {
          accessor: "domains",
          title: "Domains",
          rawElement: (platform: Platform) => (
            <>
              {platform.customDomains.map((domain, idx) => (
                <Anchor
                  target={"_blank"}
                  href={`https://${domain.domain}`}
                  key={idx}
                >
                  {domain.domain}
                </Anchor>
              ))}
            </>
          ),
        },
        {
          accessor: "createdAt",
          title: "Created at",
          rawElement: (platform: Platform) => (
            <>{new Date(platform.createdAt).toLocaleString()}</>
          ),
        },
        {
          accessor: "updatedAt",
          title: "Updated at",
          rawElement: (platform: Platform) => (
            <>{new Date(platform.updatedAt).toLocaleString()}</>
          ),
        },
      ]}
    />
  );
};
