import React from "react";
import { GeneratedLayer } from "./features/platform/GeneratedLayer";
import { Layout } from "./features/console/Layout";
import { IS_CONSOLE, IS_DEV } from "./utils/env";
import { Authenticator } from "@aws-amplify/ui-react";
import { theme } from "./features/console/theme";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";

function App() {
  return IS_CONSOLE || IS_DEV ? (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <ModalsProvider>
        <Authenticator.Provider>
          <Layout />
        </Authenticator.Provider>
      </ModalsProvider>
    </MantineProvider>
  ) : (
    <GeneratedLayer />
  );
}

export default App;
