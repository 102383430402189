import {
  Alert,
  Anchor,
  Center,
  Code,
  Image,
  List,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { Prism } from "@mantine/prism";
import { graphql } from "gql.tada";
import { useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { IconInfoCircle } from "@tabler/icons-react";

const WebhookAvailableEventsQuery = graphql(`
  query webhookAvailableEvents {
    webhookAvailableEvents {
      type
      description
      answerPayload {
        type
        description
        optional
        properties {
          optional
          description
          type
          name
          array
          example
        }
      }
      payload {
        type
        description
        optional
        properties {
          optional
          description
          type
          name
          array
          example
        }
      }
    }
  }
`);

const createJsonPayloadExampleFromSchema = (
  schema:
    | ResultOf<
        typeof WebhookAvailableEventsQuery
      >["webhookAvailableEvents"][0]["payload"]
    | ResultOf<
        typeof WebhookAvailableEventsQuery
      >["webhookAvailableEvents"][0]["answerPayload"],
) => {
  const payload = schema?.properties?.reduce(
    (acc, property) => {
      acc[property.name] = property.example;
      return acc;
    },
    {} as Record<string, any>,
  );
  return JSON.stringify(payload, null, 2);
};

export const AppsDocumentation = () => {
  const { data, loading, error } = useQuery(WebhookAvailableEventsQuery);

  if (loading) {
    return <Loader />;
  }

  const payloads = data?.webhookAvailableEvents.reduce(
    (acc, event) => {
      acc[event.type] = createJsonPayloadExampleFromSchema(event.payload);
      return acc;
    },
    {} as Record<string, string>,
  );

  return (
    <Stack>
      <Title>Authentication</Title>
      <Title order={2}>OAuth2 and OpenID</Title>
      <Text>Well-Played.gg uses OAuth2 as its authentication mechanism.</Text>
      <Title order={3}>Requests format</Title>
      <Text>
        To provide the authorization token, you will have to add an
        authorization header containing your generated OAuth2 token. example:
      </Text>
      <Prism language="bash">
        {`
curl - X POST -H 'organization-id: ORGANIZATION_ID_HERE'\\ -H
  'authorization: Bearer TOKEN_HERE'\\
   -d '{...}' https://api.warrior.well-played.gg
      `}
      </Prism>
      <Title order={3}>OpenID configuration</Title>
      <Text>
        You can find the OpenID configuration on the follwing endpoint:
      </Text>
      <Code>
        https://oauth.warrior.well-played.gg/.well-known/openid-configuration
      </Code>
      <Text>It can be used to automatically configure OpenID clients</Text>
      <Title order={3}>OAuth endpoints</Title>
      <List>
        <List.Item>
          <Text>
            Authorization endpoint:
            <Anchor href={"https://oauth.warrior.well-played.gg/oauth2/auth"}>
              https://oauth.warrior.well-played.gg/oauth2/auth
            </Anchor>
          </Text>
        </List.Item>
        <List.Item>
          <Text>
            Token endpoint:{" "}
            <Anchor href={"https://oauth.warrior.well-played.gg/oauth2/token"}>
              https://oauth.warrior.well-played.gg/oauth2/token
            </Anchor>
          </Text>
        </List.Item>
        <List.Item>
          <Text>
            User info endpoint:{" "}
            <Anchor href={"https://oauth.warrior.well-played.gg/userinfo"}>
              https://oauth.warrior.well-played.gg/userinfo
            </Anchor>
          </Text>
        </List.Item>
        <List.Item>
          <Text>
            End session endpoint:
            <Anchor
              href={
                "https://oauth.warrior.well-played.gg/oauth2/sessions/logout"
              }
            >
              https://oauth.warrior.well-played.gg/oauth2/sessions/logout
            </Anchor>
          </Text>
        </List.Item>
        <List.Item>
          <Text>
            Revocation endpoint:
            <Anchor href={"https://oauth.warrior.well-played.gg/oauth2/revoke"}>
              https://oauth.warrior.well-played.gg/oauth2/revoke
            </Anchor>
          </Text>
        </List.Item>
      </List>
      <Title order={3}>
        Example configuration with{" "}
        <Anchor
          href={"https://www.npmjs.com/package/@axa-fr/react-oidc"}
          target="_blank"
        >
          @axa-fr/react-oidc
        </Anchor>
      </Title>
      <Prism language="jsx">
        {`
<OidcProvider
    configuration={{
      client_id: "your-app-client-id",
      redirect_uri: "https://your-app.com/redirect_from_your_redirect_uri_configuration_in_your_well_played_app",
      scope: "openid offline_access",
      authority: "https://oauth.warrior.well-played.gg",
    }}
>
    {...}
</OidcProvider>
        `}
      </Prism>
      <Title order={3}>Login flow graph</Title>
      <Center>
        <Image src="/well-played-login.svg" bg="grey" width={800} />
      </Center>
      <Title>Webhooks</Title>
      <Text>
        Webhooks are a way to send real-time notifications to your server
        through HTTP POST requests.
        <br />
        You can create webhooks to be notified when a specific event occurs in
        the system.
        <br />
        Each webhook call carries the payload as the body of the request and the
        following headers:
        <br />
        - WP-Webhook-Signature: the signature of the event
        <br />
        - WP-Webhook-Message-Id: the id of the event
        <br />
        - WP-Webhook-Timestamp: the timestamp of the event
        <br />
        - WP-Webhook-Event: the event that triggered the webhook
        <br />
        The signature is a HMAC SHA256 signature of the event payload, the
        timestamp and the message id.
        <br />
        The signature is generated using the secret provided when creating the
        webhook and allows you to verify the authenticity of the event.
        <br />
        Here's an example in Node.js to verify the signature:
        <br />
        <Prism language={"typescript"}>
          {`
import crypto from 'crypto';

// The webhook secret can be found on the webhooks list page
const secret = 'your-webhook-secret';

// These variables should be retrieved from the headers that are sent with the webhook
const timestamp = 'timestamp';
const messageId = 'message-id';
const signature = 'signature';

// Retrieved through the webhook body
const payload = 'payload';

const expectedSignature = crypto
    .createHmac('sha256', secret)
    .update(messageId + timestamp + payload)
    .digest('hex');
    
const isValid = signature === expectedSignature;
console.log(isValid);
`}
        </Prism>
        <br />
        It is important to check the signature to ensure that the webhook call
        is legitimate.
        <br />
        If the signature is not valid, you should not process the webhook call.
        <br />
      </Text>
      <Alert icon={<IconInfoCircle />} color="indigo" variant="outline">
        Info: webhooks that contains an Answer Payload are webhooks that are
        expecting an answer from the client.
        <br />
        The answer payload is the payload that the client should send back to
        the server.
        <br />
        They are not treated as classical webhooks (no retries) and mostly used
        as RPC calls.
      </Alert>
      <Text fw={700}>Available webhooks:</Text>
      {data?.webhookAvailableEvents.map((event) => (
        <Stack key={event.type} spacing="xs">
          <Title order={2}>{event.type}</Title>
          <Text>{event.description}</Text>
          <Stack>
            <Title order={3}>Payload</Title>
            <Prism language="json">
              {/* Create json example from fields definitions */}
              {payloads?.[event.type] ?? "none"}
            </Prism>
            <Text fw={700}>Fields:</Text>
            <List>
              {event.payload?.properties?.map((property) => (
                <List.Item key={property.name}>
                  <Text>
                    {property.name} - {property.type}: {property.description}
                  </Text>
                </List.Item>
              ))}
            </List>
          </Stack>
          {event.answerPayload && (
            <Stack>
              <Title order={3}>Answer payload</Title>
              <Prism language="json">
                {JSON.stringify(event.answerPayload, null, 2)}
              </Prism>
              <Text fw={700}>Fields:</Text>
              <List>
                {event.answerPayload?.properties?.map((property) => (
                  <List.Item key={property.name}>
                    <Text>
                      {property.name} - {property.type}: {property.description}
                    </Text>
                  </List.Item>
                ))}
              </List>
            </Stack>
          )}
        </Stack>
      ))}
    </Stack>
  );
};
