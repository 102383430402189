import { gql, useMutation, useQuery } from "@apollo/client";
import { CreationForm } from "../../components/form/CreationForm";
import * as yup from "yup";
import { Organization } from "../../../../gql/graphql";
import { Loader } from "@mantine/core";
import { useParams } from "react-router-dom";
import { constructCustomFieldsForm } from "../../components/form/CustomFieldsForm";
import { notifications } from "@mantine/notifications";

export const OrganizationConfiguration = () => {
  const organizationId = useParams().organizationId;
  const { data, loading } = useQuery<{
    organization: Organization;
  }>(
    gql`
      query ($id: ID!) {
        organization(id: $id) {
          id
          configuration {
            customFields {
              name
              order
              property
              public
              required
              type
              unique
              visibility
              editability
            }
          }
          name
          description
        }
      }
    `,
    {
      variables: {
        id: organizationId,
      },
    },
  );
  const [updateOrganization] = useMutation(
    gql`
      mutation (
        $name: String!
        $description: String!
        $configuration: OrganizationConfigurationInput!
      ) {
        updateOrganization(
          organization: {
            description: $description
            name: $name
            configuration: $configuration
          }
        ) {
          id
        }
      }
    `,
    {
      context: {
        headers: {
          "organization-id": organizationId,
        },
      },
    },
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title="Update organization"
      schemaDefinition={{
        name: {
          type: "INPUT",
          inputType: "text",
          label: "Name",
          yupConfig: yup.string().required(),
          defaultValue: data?.organization.name,
        },
        description: {
          type: "INPUT",
          inputType: "text",
          label: "Description",
          yupConfig: yup.string().required(),
          defaultValue: data?.organization.description,
        },
        customFields: constructCustomFieldsForm({
          defaultValue: data?.organization?.configuration?.customFields,
          label: "Custom fields",
        }),
      }}
      onSubmit={async (data) => {
        const { name, description, customFields } = data;

        await updateOrganization({
          variables: {
            name,
            description,
            configuration: {
              customFields,
            },
          },
        }).then((data) => {
          if (data?.errors && data.errors.length > 0) {
            throw new Error(data.errors[0].message);
          }
        });

        notifications.show({
          title: "Organization updated",
          message: "Organization updated successfully",
          color: "green",
        });
      }}
    />
  );
};
