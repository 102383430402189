import { useNavigate, useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Webhook, WebhookEventType } from "../../../../../../gql/graphql";
import { Loader } from "@mantine/core";
import { CreationForm } from "../../../../components/form/CreationForm";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";
import { map } from "lodash";

export const WebhookUpdate = () => {
  const navigate = useNavigate();
  const webhookId = useParams().webhookId;
  const { loading, data } = useQuery<{ webhook: Webhook }>(
    gql`
      query ($id: ID!) {
        webhook(id: $id) {
          id
          description
          url
          active
          secret
          events
        }
      }
    `,
    { variables: { id: webhookId }, skip: !webhookId },
  );
  const [createWebhook] = useMutation<{
    createWebhook: { id: string };
  }>(gql`
    mutation (
      $active: Boolean!
      $url: String!
      $description: String!
      $events: [WebhookEventType!]!
    ) {
      createWebhook(
        input: {
          active: $active
          url: $url
          description: $description
          events: $events
        }
      ) {
        id
      }
    }
  `);
  const [updateWebhook] = useMutation(gql`
    mutation (
      $id: ID!
      $active: Boolean!
      $url: String!
      $description: String!
      $events: [WebhookEventType!]!
    ) {
      updateWebhook(
        id: $id
        input: {
          description: $description
          active: $active
          url: $url
          events: $events
        }
      ) {
        id
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title={webhookId ? "Update webhook" : "Create webhook"}
      schemaDefinition={{
        description: {
          type: "TEXTAREA",
          label: "Description",
          yupConfig: yup.string().required(),
          defaultValue: data?.webhook?.description,
        },
        url: {
          type: "INPUT",
          label: "Callback URL",
          inputType: "url",
          yupConfig: yup.string().required(),
          defaultValue: data?.webhook?.url,
          info: "The URL to which the webhook will send the data.",
        },
        active: {
          type: "CHECKBOX",
          label: "Enabled",
          yupConfig: yup.boolean().required(),
          defaultValue: data?.webhook?.active ?? false,
          info: "If enabled, the webhook will send data to the URL.",
        },
        events: {
          type: "MULTI_SELECT",
          label: "Events",
          yupConfig: yup.array().min(1).required(),
          defaultValue: data?.webhook?.events ?? [],
          subtext: "The events that will trigger the webhook.",
          options: map(WebhookEventType, (value, key) => ({
            label: key,
            value: value,
          })),
        },
      }}
      onSubmit={async (values) => {
        if (webhookId) {
          await updateWebhook({
            variables: {
              id: webhookId,
              ...values,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
          notifications.show({
            title: "Tournament updated",
            message: "The webhook has been updated",
            color: "green",
            autoClose: 3000,
          });
        } else {
          navigate(
            "/apps/webhooks/" +
              (await createWebhook({
                variables: values,
              }).then((data) => {
                if (data?.errors && data.errors.length > 0) {
                  throw new Error(data.errors[0].message);
                }
                return data.data?.createWebhook.id;
              })),
          );
        }
      }}
    />
  );
};
