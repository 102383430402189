import { Checkbox, Group, Text, TextInput } from "@mantine/core";
import { EditableField } from "./EditableField";
import { DateTimePicker } from "@mantine/dates";

export const CustomFieldsWithEditableValue = ({
  fields,
  loading,
  defaultValues,
  onSubmit,
}: {
  fields: {
    name: string;
    type: string;
    property: string;
  }[];
  loading?: boolean;
  defaultValues?: Record<string, any>;
  onSubmit: (property: string, value: any) => void;
}) => {
  return fields.map((field, idx) => (
    <Group key={idx} spacing={5}>
      <Text span fw={700}>
        {field.name}:
      </Text>{" "}
      <EditableField
        loading={loading}
        readingElement={({ value }) =>
          (field.type === "BOOLEAN" && (
            <>{value === "true" ? "Yes" : "No"}</>
          )) || <Text span>{JSON.stringify(value)}</Text>
        }
        editingElement={({ value, onChange, disabled }) =>
          field.type === "STRING" ? (
            <TextInput
              disabled={disabled}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          ) : field.type === "DATE" ? (
            <DateTimePicker
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          ) : field.type === "BOOLEAN" ? (
            <Checkbox
              disabled={disabled}
              checked={value === "true"}
              onChange={(event) =>
                onChange(event.currentTarget.checked ? "true" : "false")
              }
            />
          ) : (
            // TODO Other types
            <TextInput
              disabled={disabled}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          )
        }
        defaultValue={defaultValues?.[field.property]}
        onSubmit={async (value) => onSubmit(field.property, value)}
      />
    </Group>
  ));
};
