import { Anchor, Stack, Text, Title } from "@mantine/core";

export const IAMHome = () => {
  return (
    <Stack>
      <Title>IAM - Identity and Access Management</Title>
      <Text>
        <Text weight={700} span>
          IAM
        </Text>{" "}
        is a service provided by WellPlayed to manage users and their
        permissions.
      </Text>
      <Text>
        It is composed of three parts:{" "}
        <Anchor weight={700} href="/iam/members">
          Members
        </Anchor>
        ,{" "}
        <Anchor weight={700} href="/iam/groups">
          Groups
        </Anchor>{" "}
        and{" "}
        <Anchor weight={700} href="/iam/applications">
          Applications
        </Anchor>
      </Text>
      <Text>
        <Text weight={700} span>
          Members
        </Text>{" "}
        are users that have access to your organization.
        <br />
        They can be invited by email or via their platform player account.
        <br />
        By inviting a member through their email, they will be able to access
        the WellPlayed Console.
        <br />
        Members can be assigned to groups to grant them permissions.
      </Text>
      <Text>
        <Text weight={700} span>
          Groups
        </Text>{" "}
        are a collection of permissions.
        <br />
        They can be assigned to members to grant them permissions.
      </Text>
      <Text>
        <Text weight={700} span>
          Applications
        </Text>{" "}
        are OAuth2 Clients that uses Client Credentials Flow to access resources
        on behalf of your organization.
        <br />
        They can be assigned to groups to grant them permissions.
      </Text>
    </Stack>
  );
};
