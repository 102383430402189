import { useNavigate, useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AutoDataTable } from "../../../components/AutoDataTable";
import { graphql } from "gql.tada";
import { Anchor, Loader, Select } from "@mantine/core";
import { CreationForm } from "../../../components/form/CreationForm";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";
import { useSelectedOrganizationId } from "../../../../../business/OrganizationId";
import { IS_STG } from "../../../../../apollo";
import { IS_DEV } from "../../../../../utils/env";
import { useState } from "react";
import { PlatformCdnFileType } from "../../../../../gql/graphql";

export const PlatformFileQuery = graphql(`
  query platformFile($id: ID!) {
    platformFile(id: $id) {
      id
      name
      platformId
      contents
      type
      config {
        pageConfig {
          path
        }
      }
    }
  }
`);

const PlatformFileCreateMutation = graphql(`
  mutation platformFileCreate(
    $platformId: ID!
    $input: PlatformCdnFileCreateInput!
  ) {
    platformFileCreate(platformId: $platformId, input: $input) {
      id
    }
  }
`);

const PlatformFileUpdateMutation = graphql(`
  mutation platformFileUpdateData($id: ID!, $input: PlatformFileUpdateInput!) {
    platformFileUpdate(id: $id, input: $input) {
      id
    }
  }
`);

export const PlatformFileUpdate = () => {
  const navigate = useNavigate();
  const platformId = useParams().platformId as string;
  const organizationId = useSelectedOrganizationId();
  let fileId = useParams().fileId as string;
  const [fileType, setFileType] = useState("PAGE" as const);
  const { data, loading } = useQuery(PlatformFileQuery, {
    variables: {
      id: fileId,
    },
    skip: !fileId,
  });
  const [fileUpdate] = useMutation(PlatformFileUpdateMutation);
  const [fileCreate] = useMutation(PlatformFileCreateMutation);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!fileId && (
        <Select
          label={"File type"}
          data={Object.values(PlatformCdnFileType).map((v) => ({
            label: v,
            value: v,
          }))}
          value={fileType}
          onChange={(value) => setFileType(value as any)}
        />
      )}
      <CreationForm
        title={fileId ? "Update file" : "Create file"}
        schemaDefinition={{
          ...(!fileId && {
            name: {
              type: "INPUT",
              label: "Name",
              yupConfig: yup.string().required(),
              inputType: "text",
              defaultValue: data?.platformFile?.name,
              placeholder: "Enter the file name",
            },
          }),
          ...(fileType === "PAGE" && {
            path: {
              type: "INPUT",
              label: "Path",
              info: (
                <>
                  The path of the page, must start with /,
                  <br />
                  and can contain alphanumeric characters and : for parameters
                  <br />
                  Example: /users/:userId
                </>
              ),
              yupConfig: yup
                .string()
                .matches(
                  /^\/(?:[a-zA-Z0-9]*|:[a-zA-Z0-9]+)*(?:\/(?:[a-zA-Z0-9]*|:[a-zA-Z0-9]+)*)*$/,
                )
                .required(),
              inputType: "text",
              defaultValue: data?.platformFile?.config?.pageConfig?.path,
              placeholder: "Enter the file path",
            },
          }),
        }}
        onSubmit={async (values) => {
          if (fileId) {
            await fileUpdate({
              variables: {
                id: fileId,
                input:
                  (values.path && {
                    config: { pageConfig: { path: values.path } },
                  }) ??
                  {},
              },
            }).then((data) => {
              if (data?.errors && data.errors.length > 0) {
                throw new Error(data.errors[0].message);
              }
            });
            notifications.show({
              title: "File updated",
              message: "The file has been updated successfully",
              color: "green",
              autoClose: 3000,
            });
          } else {
            fileId = await fileCreate({
              variables: {
                platformId,
                input: {
                  contents: "",
                  name: values.name,
                  config: values.path
                    ? { pageConfig: { path: values.path } }
                    : {},
                  type: fileType,
                },
              },
            }).then((data) => {
              if (data?.errors && data.errors.length > 0) {
                throw new Error(data.errors[0].message);
              }
              return data.data?.platformFileCreate.id as string;
            });
            navigate(("../files/" + fileId) as string);
          }
        }}
      />
    </>
  );
};

export const PlatformFiles = () => {
  const organizationId = useSelectedOrganizationId();
  const platformId = useParams().platformId;
  const [filterByType, setFilterByType] = useState("PAGE" as const);

  return (
    <>
      <Select
        label={"Filter by type"}
        data={Object.values(PlatformCdnFileType).map((v) => ({
          label: v,
          value: v,
        }))}
        value={filterByType}
        onChange={(value) => setFilterByType(value as any)}
      />
      <AutoDataTable
        createButtonText="Add new file"
        deleteMutation={gql`
          mutation ($id: ID!) {
            platformFileDelete(id: $id)
          }
        `}
        query={gql`
          query ($platformId: ID!, $type: PlatformCdnFileType!) {
            platformFiles(platformId: $platformId, type: $type) {
              id
              name
              type
              config {
                pageConfig {
                  path
                }
              }
            }
          }
        `}
        queryVariables={{
          platformId,
          type: filterByType,
        }}
        columns={[
          {
            accessor: "name",
            title: "Name",
          },
          ...(filterByType === "PAGE"
            ? [
                {
                  accessor: "path",
                  title: "Path",
                  rawElement: (file: {
                    name: string;
                    type: string;
                    config: {
                      pageConfig: {
                        path: string;
                      };
                    };
                  }) => (
                    <Anchor
                      target="_blank"
                      href={`https://cdn.well-played.gg/${
                        IS_STG || IS_DEV ? "stg" : "prod"
                      }/${organizationId}/${platformId}/${file.name}.liquid`}
                    >
                      {file.config?.pageConfig?.path}
                    </Anchor>
                  ),
                },
              ]
            : []),
          {
            accessor: "cdn",
            title: "CDN URL",
            rawElement: (file: {
              name: string;
              type: string;
              config: {
                pageConfig: {
                  path: string;
                };
              };
            }) => {
              const fileUrl = `https://cdn.well-played.gg/${
                IS_STG || IS_DEV ? "stg" : "prod"
              }/${organizationId}/${platformId}/${file.name}${
                ((file.type === "PAGE" || file.type === "COMPONENT") &&
                  ".liquid") ??
                ""
              }`;
              return (
                <Anchor target="_blank" href={fileUrl}>
                  {fileUrl}
                </Anchor>
              );
            },
          },
        ]}
      />
    </>
  );
};
