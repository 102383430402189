import { AutoDataTable } from "../../components/AutoDataTable";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Leaderboard,
  LeaderboardSeason,
  Player,
} from "../../../../gql/graphql";
import {
  Accordion,
  Anchor,
  Button,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { CreationForm } from "../../components/form/CreationForm";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { forwardRef } from "react";

const SearchResult = forwardRef<
  HTMLDivElement,
  { value: string; username: string }
>(({ value, username, ...props }, ref) => (
  <div ref={ref} {...props}>
    <Text>
      {username} ({value})
    </Text>
  </div>
));

const UpdateScoreModal = ({ leaderboardId }: { leaderboardId: string }) => {
  const [updatePlayerScore] = useMutation(gql`
    mutation ($input: LeaderboardRegisterPlayerScoreInput!) {
      leaderboardRegisterPlayerScore(input: $input) {
        updatedAt
      }
    }
  `);
  const { refetch } = useQuery<{ searchPlayer: Player[] }>(
    gql`
      query ($username: String!) {
        searchPlayer(username: $username) {
          id
          username
          ownerId
        }
      }
    `,
    {
      skip: true,
    },
  );

  return (
    <CreationForm
      schemaDefinition={{
        playerId: {
          type: "AUTOCOMPLETE",
          label: "Player username",
          itemComponent: SearchResult,
          yupConfig: yup.string().required(),
          transformResult: (result: any) => result.email ?? result.id,
          populate: async (value) => {
            const result = await refetch({
              username: value,
            });
            return result.data.searchPlayer.map((player) => ({
              username: player.username,
              value: player.username,
              id: player.id,
            }));
          },
        },
        score: {
          type: "INPUT",
          label: "Score",
          yupConfig: yup.number().required(),
          inputType: "number",
        },
      }}
      onSubmit={async (values) => {
        await updatePlayerScore({
          variables: {
            input: {
              leaderboardId,
              playerId: values.playerId,
              score: values.score,
            },
          },
        }).then((data) => {
          if (data?.errors && data.errors.length > 0) {
            throw new Error(data.errors[0].message);
          }
        });
        notifications.show({
          title: "Player Score updated",
          message: "The player score has been updated",
          color: "green",
          autoClose: 3000,
        });
      }}
    />
  );
};

export const CurrentLeaderboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const leaderboardId = useParams().leaderboardId!;
  const { loading, data } = useQuery<{
    currentLeaderboardSeason: LeaderboardSeason;
  }>(
    gql`
      query ($id: ID!) {
        currentLeaderboardSeason(leaderboardId: $id) {
          id
          name
          start
          end
          buckets(page: { first: 100 }) {
            nodes {
              id
              name
              minScore
              scoreCalculationType
              scores {
                nodes {
                  score
                  rank
                  lastRank
                  lastScore
                  player {
                    id
                    username
                  }
                }
              }
            }
          }
        }
      }
    `,
    { variables: { id: leaderboardId }, skip: !leaderboardId },
  );

  if (loading) {
    return <Loader />;
  }

  if (!data?.currentLeaderboardSeason) {
    return (
      <>
        <p>There is no current season for this leaderboard.</p>
        <p>
          <Anchor component={Link} to="../seasons">
            Create a season
          </Anchor>{" "}
          which dates includes today to make it the current season.
        </p>
      </>
    );
  }

  return (
    <>
      <Title order={1}>Current season's Leaderboard</Title>
      <Group spacing={5}>
        <Text weight={700}>{data.currentLeaderboardSeason.name}</Text>
        <Text>
          ({new Date(data.currentLeaderboardSeason.start).toLocaleString()} -{" "}
          {new Date(data.currentLeaderboardSeason.end).toLocaleString()})
        </Text>
      </Group>
      <Button
        onClick={() => {
          modals.open({
            title: "Register Player Score",
            children: <UpdateScoreModal leaderboardId={leaderboardId} />,
            onClose() {
              window.location.reload();
            },
            centered: true,
          });
        }}
      >
        Register a score
      </Button>
      <Text>
        <Accordion>
          {data.currentLeaderboardSeason.buckets.nodes.map((bucket) => (
            <Accordion.Item key={bucket.id} value={bucket.id}>
              <Accordion.Control>{bucket.name}</Accordion.Control>
              <Accordion.Panel>
                <Group>
                  <Text>
                    <Text weight={700}>Min score:</Text> {bucket.minScore}
                  </Text>
                  <Text>
                    <Text weight={700}>Score calculation type:</Text>{" "}
                    {bucket.scoreCalculationType}
                  </Text>
                </Group>
                <Text>
                  <Text weight={700}>Scores:</Text>
                </Text>
                <Accordion>
                  {bucket.scores.nodes.map((score) => (
                    <Accordion.Item
                      key={score.player.id}
                      value={score.player.id}
                    >
                      <Accordion.Control>
                        #{score.rank} - {score.player.username}
                      </Accordion.Control>
                      <Accordion.Panel>
                        <Group>
                          <Text>
                            <Text weight={700}>Score:</Text> {score.score}
                          </Text>
                          <Text>
                            <Text weight={700}>Rank:</Text> {score.rank}
                          </Text>
                          <Text>
                            <Text weight={700}>Last rank:</Text>{" "}
                            {score.lastRank}
                          </Text>
                          <Text>
                            <Text weight={700}>Last score:</Text>{" "}
                            {score.lastScore}
                          </Text>
                        </Group>
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Text>
    </>
  );
};

export const UpdateLeaderboard = () => {
  const navigate = useNavigate();
  let leaderboardId = useParams().leaderboardId;
  const { loading, data } = useQuery<{ leaderboard: Leaderboard }>(
    gql`
      query ($id: ID!) {
        leaderboard(id: $id) {
          id
          name
          description
        }
      }
    `,
    { variables: { id: leaderboardId }, skip: !leaderboardId },
  );
  const [create] = useMutation<{
    createLeaderboard: { id: string };
  }>(gql`
    mutation ($name: String!, $description: String!) {
      createLeaderboard(input: { name: $name, description: $description }) {
        id
      }
    }
  `);
  const [update] = useMutation(gql`
    mutation ($id: ID!, $name: String!, $description: String!) {
      updateLeaderboard(
        id: $id
        input: { name: $name, description: $description }
      ) {
        id
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title={leaderboardId ? "Update leaderboard" : "Create leaderboard"}
      schemaDefinition={{
        name: {
          type: "INPUT",
          label: "Name",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.leaderboard?.name,
          placeholder: "Enter the leaderboard name",
        },
        description: {
          type: "TEXTAREA",
          label: "Description",
          yupConfig: yup.string().required(),
          defaultValue: data?.leaderboard?.description,
        },
      }}
      onSubmit={async (values) => {
        if (leaderboardId) {
          await update({
            variables: {
              id: leaderboardId,
              name: values.name,
              description: values.description,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
          notifications.show({
            title: "Leaderboard updated",
            message: "The leaderboard has been updated",
            color: "green",
            autoClose: 3000,
          });
        } else {
          leaderboardId = await create({
            variables: {
              name: values.name,
              description: values.description,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
            return data.data?.createLeaderboard.id;
          });
          navigate("/leaderboards/" + leaderboardId);
        }
      }}
    />
  );
};

export const Leaderboards = () => (
  <AutoDataTable
    itemPath=".."
    query={gql`
      query ($cursor: String, $count: Float) {
        leaderboards(page: { first: $count, after: $cursor }) {
          nodes {
            id
            name
            description
          }
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `}
    columns={[
      {
        accessor: "name",
        title: "Name",
      },
      {
        accessor: "description",
        title: "Description",
      },
    ]}
    deleteMutation={gql`
      mutation ($id: ID!) {
        deleteLeaderboard(id: $id)
      }
    `}
  />
);

export const LeaderboardsHome = () => {
  return (
    <Stack>
      <Title>Leaderboards</Title>
      <Text>
        <Text weight={700} span>
          Leaderboards
        </Text>{" "}
        are a way to rank players based on their score, SkillRating or other
        metrics.
      </Text>
      <Text>
        Each Leaderboard is composed of{" "}
        <Text weight={700} span>
          Leaderboard Seasons
        </Text>
        .
        <br />
        They are used to group players based on their score at a given time.
        <br />
        The{" "}
        <Text weight={700} span>
          Current Season
        </Text>{" "}
        is the season that is currently active during the current date (meaning
        that the current date is between the season's start and end dates).
      </Text>
      <Text>
        <Text weight={700} span>
          Season Buckets
        </Text>{" "}
        are a way to group players based on their score.
        <br />
        They can be used to create a ranking system with multiple tiers, or to
        group players based on their score range.
        <br />
        An example would be the League of Legends ranking system, which has
        multiple tiers (Bronze, Silver, Gold, etc.). Each tier is a bucket, and
        each bucket has a minimum score.
      </Text>
    </Stack>
  );
};
