import { usePages } from "../../business/Page";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GeneratedPage } from "./GeneratedPage";

export const GeneratedLayer = () => {
  const { pages, loading, error } = usePages();

  if (loading) return <></>;

  if (error) {
    // TODO handle error
    return <></>;
  }

  if (!pages) return <></>;

  const router = createBrowserRouter(
    Object.values(pages).map((page) => ({
      path: page.config?.pageConfig?.path,
      element: <GeneratedPage pageId={page.id} />,
    })),
  );

  return <RouterProvider router={router} />;
};
