import {
  Button,
  Card,
  Center,
  Flex,
  Group,
  Loader,
  NumberInput,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useMutation, useQuery } from "@apollo/client";
import { graphql } from "gql.tada";
import { useSelectedOrganizationId } from "../../../../../business/OrganizationId";
import { useState } from "react";
import { AbbrevitatedNumber } from "../../../components/AbbrevitatedNumber";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { DataTable } from "mantine-datatable";

const GetOrganizationStripeStatus = graphql(`
  query organization($organizationId: ID!) {
    organization(id: $organizationId) {
      stripeConfigured
    }
  }
`);

const OrganizationSubscriptionItemsUpdate = graphql(`
  mutation organizationSubscriptionItemsUpdate(
    $items: [OrganizationSubscriptionItemUpdateInput!]!
    $coupon: String
  ) {
    organizationSubscriptionItemsUpdate(items: $items, coupon: $coupon)
  }
`);

const CreatePortalSession = graphql(`
  mutation stripeCreatePortalSession {
    stripeCreatePortalSession
  }
`);

const GetSubscriptionItems = graphql(`
  query organizationCurrentSubscriptionItems {
    subscriptionItems {
      name
      description
      id
      limitsOverride {
        value
        limit
      }
      maxCount
      price
    }

    organizationCurrentSubscriptionItems {
      count
      subscriptionItemId
    }

    limitDefinitions {
      limits {
        id
        name
        max
      }
    }

    organizationLimits {
      limits {
        current
        id
      }
    }
  }
`);

export const Subscription = () => {
  const organizationId = useSelectedOrganizationId();
  const [btnLoading, setBtnLoading] = useState(false);
  const [coupon, setCoupon] = useState<string | null>(null);
  const [updatedSubscriptionItems, setUpdatedSubscriptionItems] = useState<
    Record<string, number>
  >({});
  const { loading, data } = useQuery(GetOrganizationStripeStatus, {
    variables: {
      organizationId: organizationId ?? "",
    },
    onCompleted: (data) => {
      if (!data.organization.stripeConfigured) {
        modals.open({
          closeOnEscape: false,
          closeOnClickOutside: false,
          withCloseButton: false,
          children: (
            <Stack>
              <Title order={2}>Stripe not configured</Title>
              <Text>
                You need to configure your Billing account before you can manage
                your subscription.
              </Text>
              <Button
                onClick={() => {
                  setBtnLoading(true);
                  stripeCreatePortalSession()
                    .then((res) => {
                      if (res?.errors && res.errors.length > 0) {
                        throw new Error(res.errors[0].message);
                      }

                      if (res.data?.stripeCreatePortalSession) {
                        window.location.href =
                          res.data.stripeCreatePortalSession;
                      }
                    })
                    .catch(() => {
                      setBtnLoading(false);
                    });
                }}
                loading={btnLoading}
              >
                Click here to configure your billing account
              </Button>
            </Stack>
          ),
        });
      }
    },
  });
  const { loading: subscriptionItemsLoading, data: subscriptionItemsData } =
    useQuery(GetSubscriptionItems);
  const [stripeCreatePortalSession] = useMutation(CreatePortalSession);
  const [organizationSubscriptionItemsUpdate] = useMutation(
    OrganizationSubscriptionItemsUpdate,
  );

  if (loading || subscriptionItemsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Title>Subscription</Title>
      <Button
        onClick={() => {
          setBtnLoading(true);
          stripeCreatePortalSession()
            .then((res) => {
              if (res?.errors && res.errors.length > 0) {
                throw new Error(res.errors[0].message);
              }

              if (res.data?.stripeCreatePortalSession) {
                window.location.href = res.data.stripeCreatePortalSession;
              }
            })
            .catch(() => {
              setBtnLoading(false);
            });
        }}
        loading={btnLoading}
      >
        Manage your billing account
      </Button>
      {
        /*data?.organization.stripeConfigured && */ <>
          <Title order={2}>Your subscription</Title>
          <DataTable
            columns={[
              {
                accessor: "name",
                title: "Package",
              },
              {
                accessor: "description",
                title: "Description",
              },
              {
                accessor: "limits",
                title: "Limits",
                render: (value) => (
                  <>
                    {value.limitsOverride.map((limit) => (
                      <Text>
                        {
                          subscriptionItemsData?.limitDefinitions.limits.find(
                            (l) => l.id === limit.limit,
                          )?.name
                        }{" "}
                        +
                        <AbbrevitatedNumber
                          value={Math.min(
                            limit.value *
                              (updatedSubscriptionItems[value.id as string] ??
                                subscriptionItemsData?.organizationCurrentSubscriptionItems.find(
                                  (i) => i.subscriptionItemId === value.id,
                                )?.count ??
                                0),
                            subscriptionItemsData?.limitDefinitions.limits.find(
                              (l) => l.id === limit.limit,
                            )?.max ?? Number.MAX_VALUE,
                          )}
                        />{" "}
                        (max:{" "}
                        {((subscriptionItemsData?.limitDefinitions.limits.find(
                          (l) => l.id === limit.limit,
                        )?.max ??
                          0) && (
                          <AbbrevitatedNumber
                            value={
                              subscriptionItemsData?.limitDefinitions.limits.find(
                                (l) => l.id === limit.limit,
                              )?.max ?? 0
                            }
                          />
                        )) ||
                          "unlimited"}
                        )
                      </Text>
                    ))}
                  </>
                ),
              },
              {
                accessor: "quantity",
                title: "Quantity",
                render: (item) => (
                  <Group spacing={10}>
                    <NumberInput
                      onChange={(value) => {
                        setUpdatedSubscriptionItems({
                          ...updatedSubscriptionItems,
                          [item.id as string]: value as number,
                        });
                      }}
                      defaultValue={
                        subscriptionItemsData?.organizationCurrentSubscriptionItems.find(
                          (i) => i.subscriptionItemId === item.id,
                        )?.count ?? 0
                      }
                      min={0}
                      max={item.maxCount ?? undefined}
                    />{" "}
                    {item.maxCount && <>(max: {item.maxCount})</>}
                  </Group>
                ),
              },
              {
                accessor: "price",
                title: "Price",
                render: (item) => (
                  <Text>
                    {(item.price / 100) *
                      (updatedSubscriptionItems[item.id as string] ??
                        subscriptionItemsData?.organizationCurrentSubscriptionItems.find(
                          (i) => i.subscriptionItemId === item.id,
                        )?.count ??
                        0)}
                    €/month
                    <br />
                    (unit: {item.price / 100}€/month)
                  </Text>
                ),
              },
            ]}
            records={subscriptionItemsData?.subscriptionItems}
          />
          <Center>
            <Stack align="center" mt={10}>
              <Text>
                Subscription cost:{" "}
                {subscriptionItemsData?.subscriptionItems.reduce(
                  (acc, item) =>
                    acc +
                    ((updatedSubscriptionItems[item.id as string] ??
                      subscriptionItemsData?.organizationCurrentSubscriptionItems.find(
                        (i) => i.subscriptionItemId === item.id,
                      )?.count ??
                      0) *
                      item.price) /
                      100,
                  0,
                )}
                €/month (VAT excluded)
              </Text>
              <TextInput
                value={coupon ?? ""}
                onChange={(e) => setCoupon(e.currentTarget.value)}
                placeholder="Coupon"
              />
              <Button
                onClick={() => {
                  setBtnLoading(true);
                  organizationSubscriptionItemsUpdate({
                    variables: {
                      coupon,
                      items:
                        subscriptionItemsData?.subscriptionItems.map(
                          (item) => ({
                            subscriptionItemId: item.id,
                            count:
                              updatedSubscriptionItems[item.id as string] ??
                              subscriptionItemsData?.organizationCurrentSubscriptionItems.find(
                                (i) => i.subscriptionItemId === item.id,
                              )?.count ??
                              0,
                          }),
                        ) ?? [],
                    },
                  })
                    .then((data) => {
                      if (data?.errors && data.errors.length > 0) {
                        throw new Error(data.errors[0].message);
                      }

                      notifications.show({
                        title: "Subscription updated",
                        message:
                          "Your subscription has been updated successfully!",
                        color: "green",
                        autoClose: 3000,
                      });

                      return stripeCreatePortalSession().then((res) => {
                        if (res?.errors && res.errors.length > 0) {
                          throw new Error(res.errors[0].message);
                        }

                        if (res.data?.stripeCreatePortalSession) {
                          window.location.href =
                            res.data.stripeCreatePortalSession;
                        }
                      });
                    })
                    .catch(() => {
                      setBtnLoading(false);
                    });
                }}
                loading={btnLoading}
              >
                Update subscription
              </Button>
            </Stack>
          </Center>
        </>
      }
    </>
  );
};
