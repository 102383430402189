import { gql, useMutation, useQuery } from "@apollo/client";
import {
  GroupPermission,
  OrganizationGroup,
  OrganizationGroups,
  OrganizationGroupType,
  Permissions,
} from "../../../../../../gql/graphql";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { CreationForm } from "../../../../components/form/CreationForm";
import * as yup from "yup";
import { useState } from "react";
import { ActionIcon, Anchor, Button, Loader, Text, Title } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { IconTrash } from "@tabler/icons-react";

export const UpdateGroup = () => {
  const navigate = useNavigate();
  const groupId = useParams().groupId;
  const { loading: permsLoading, data: perms } = useQuery<{
    availablePermissions: Permissions;
  }>(gql`
    query {
      availablePermissions {
        organizationPermissions {
          id
          description
          availableVariables
          resources
        }
      }
    }
  `);
  const { loading, data } = useQuery<{ organizationGroup: OrganizationGroup }>(
    gql`
      query ($id: ID!) {
        organizationGroup(id: $id) {
          id
          name
          permissions {
            id
            resources
          }
        }
      }
    `,
    { variables: { id: groupId }, skip: !groupId },
  );
  const [createGroup] = useMutation(gql`
    mutation ($name: String!, $permissions: [GroupPermissionInput!]!) {
      createOrganizationGroup(
        input: { name: $name, permissions: $permissions }
      ) {
        id
      }
    }
  `);
  const [updateGroup] = useMutation(gql`
    mutation (
      $id: ID!
      $name: String!
      $permissions: [GroupPermissionInput!]!
    ) {
      updateOrganizationGroup(
        id: $id
        input: { name: $name, permissions: $permissions }
      ) {
        id
      }
    }
  `);

  if (permsLoading || loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title={groupId ? "Update group" : "Add group"}
      schemaDefinition={{
        name: {
          type: "INPUT",
          label: "Name",
          yupConfig: yup.string().required(),
          defaultValue: data?.organizationGroup?.name,
          inputType: "text",
        },
        permissions: {
          type: "DYNAMIC",
          label: "Permissions",
          element: (onChange) => {
            const [permissions, setData] = useState<GroupPermission[]>(
              data?.organizationGroup?.permissions?.map((value) => {
                return {
                  id: value.id,
                  resources: value.resources,
                };
              }) ?? [],
            );

            return (
              <>
                <Button
                  onClick={() => {
                    setData(
                      ([] as GroupPermission[]).concat(permissions, [
                        {
                          id:
                            perms?.availablePermissions
                              .organizationPermissions[0].id ?? "",
                          resources: ["*"],
                        },
                      ]),
                    );
                  }}
                >
                  Add permission
                </Button>
                <DataTable
                  columns={[
                    {
                      title: "Permission",
                      accessor: "",
                      render: (permission, index) => (
                        <>
                          <select
                            value={permission.id}
                            onChange={(e) => {
                              const newPermissions = (
                                [] as GroupPermission[]
                              ).concat(permissions);
                              newPermissions[index].id = e.target.value;
                              setData(newPermissions);
                              onChange(newPermissions);
                            }}
                          >
                            {perms?.availablePermissions.organizationPermissions.map(
                              (perm) => (
                                <option key={perm.id} value={perm.id}>
                                  {perm.id}
                                </option>
                              ),
                            )}
                          </select>
                          <p>
                            <i>
                              {
                                perms?.availablePermissions.organizationPermissions.find(
                                  (perm) => perm.id === permission.id,
                                )?.description
                              }
                            </i>
                          </p>
                        </>
                      ),
                    },
                    {
                      title: "Resources",
                      accessor: "",
                      render: (permission) => <>All resources</>,
                    },
                    {
                      title: "",
                      accessor: "",
                      render: (permission, index) => {
                        return (
                          <ActionIcon
                            variant="transparent"
                            color="red"
                            onClick={() => {
                              const newPermissions = (
                                [] as GroupPermission[]
                              ).concat(permissions);
                              newPermissions.splice(index, 1);
                              setData(newPermissions);
                              onChange(newPermissions);
                            }}
                          >
                            <IconTrash />
                          </ActionIcon>
                        );
                      },
                    },
                  ]}
                  records={permissions}
                />
              </>
            );
          },
          defaultValue: data?.organizationGroup?.permissions?.map((value) => {
            return {
              id: value.id,
              resources: value.resources,
            };
          }),
          yupConfig: yup
            .array()
            .of(
              yup
                .object({
                  id: yup.string().required(),
                  resources: yup.array().of(yup.string().required()).required(),
                })
                .required(),
            )
            .required(),
        },
      }}
      onSubmit={async (values) => {
        if (groupId) {
          await updateGroup({
            variables: {
              id: groupId,
              name: values.name,
              permissions: values.permissions,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
        } else {
          await createGroup({
            variables: {
              name: values.name,
              permissions: values.permissions,
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
        }
        navigate(-1);
      }}
    />
  );
};

export const Groups = () => {
  const navigate = useNavigate();
  const { loading, data, error, fetchMore, refetch } = useQuery<{
    organizationGroups: OrganizationGroups;
  }>(
    gql`
      query ($cursor: String, $count: Float) {
        organizationGroups(page: { after: $cursor, first: $count }) {
          nodes {
            name
            type
            id
          }
          edges {
            cursor
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    },
  );
  const [deleteGroup] = useMutation(gql`
    mutation ($id: ID!) {
      deleteOrganizationGroup(id: $id)
    }
  `);

  const loadNext = async (cursor: string) => {
    await fetchMore({
      variables: {
        cursor,
      },
    });
  };

  const deleteItem = async (id: string) => {
    if (!confirm("Are you sure you want to delete this group?")) {
      return;
    }
    await deleteGroup({
      variables: {
        id,
      },
    });
    await refetch();
  };

  return (
    <>
      <Title>Groups</Title>
      <Text>
        Here you can manage the groups of your organization. A group can have
        different permissions and can be used to manage attach a set of
        permissions to organization members or attached applications.
        <br />
        There are three default groups that cannot be deleted:
        <br />- <b>Admin</b>: group that has all permissions
        <br />- <b>Connected</b>: this group is automatically assigned to
        connected users
        <br />- <b>Anonymous</b>: this group is automatically assigned to
        anonymous users, that means users that are not connected
      </Text>
      <Button
        onClick={() => {
          navigate("new");
        }}
      >
        Add group
      </Button>
      <DataTable
        columns={[
          {
            title: "Name",
            accessor: "name",
            render: (item) => (
              <Anchor component={NavLink} to={item.id}>
                {item.name}
              </Anchor>
            ),
          },
          {
            title: "Type",
            accessor: "type",
          },
          {
            title: "",
            accessor: "",
            render: (item) => (
              <ActionIcon
                onClick={() => deleteItem(item.id)}
                disabled={item.type !== OrganizationGroupType.Member}
              >
                <IconTrash />
              </ActionIcon>
            ),
          },
        ]}
        fetching={loading}
        records={data?.organizationGroups.nodes}
      />
    </>
  );
};
