import * as yup from "yup";
import {
  Property,
  PropertyType,
  PropertyVisibility,
  ProperyEditability,
} from "../../../../gql/graphql";
import { useEffect, useState } from "react";
import { ActionIcon, Button } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { IconTrash } from "@tabler/icons-react";

export const yupConfig = yup
  .array()
  .of(
    yup.object().shape({
      name: yup.string().required(),
      order: yup.number().required(),
      property: yup.string().required(),
      editability: yup
        .mixed()
        .oneOf(Object.values(ProperyEditability))
        .required(),
      visibility: yup
        .mixed()
        .oneOf(Object.values(PropertyVisibility))
        .required(),

      required: yup.boolean().required(),
      type: yup.string().required(),
      unique: yup.boolean().required(),
    }),
  )
  .required();

export const CustomFieldsForm = ({
  onChange,
  defaultValue,
  buttonLabel = "Add a custom field",
}: {
  onChange: (...newValues: any[]) => void;
  defaultValue?: Array<Property> | null;
  buttonLabel?: string;
}) => {
  const [customFields, setCustomFields] = useState<Property[]>(
    defaultValue?.map((data) => {
      const newObj = Object.assign({}, data);
      delete newObj.__typename;
      !newObj.visibility && (newObj.visibility = PropertyVisibility.Public);
      !newObj.editability && (newObj.editability = ProperyEditability.Always);
      if (newObj.public === false) {
        newObj.visibility = PropertyVisibility.OwnerOrPermission;
        delete newObj.public;
      }
      return newObj;
    }) || [],
  );

  useEffect(() => {
    onChange(customFields);
  }, [customFields]);

  return (
    <>
      <Button
        onClick={() => {
          setCustomFields(
            customFields.concat({
              name: "",
              order: 0,
              property: "",
              visibility: PropertyVisibility.Public,
              editability: ProperyEditability.Always,
              required: false,
              type: PropertyType.String,
              unique: false,
            }),
          );
        }}
      >
        {buttonLabel}
      </Button>
      <DataTable
        records={customFields.sort((a, b) => a.order - b.order)}
        columns={[
          {
            title: "Order",
            accessor: "order",
            render: (customField, index) => (
              <input
                type="number"
                value={customField.order}
                onChange={(e) => {
                  const newCustomFields = ([] as Property[]).concat(
                    customFields,
                  );
                  newCustomFields[index].order = parseInt(e.target.value);
                  setCustomFields(newCustomFields);
                }}
              />
            ),
          },
          {
            title: "Name",
            accessor: "name",
            render: (customField, index) => (
              <input
                type="text"
                placeholder="MyFieldName"
                value={customField.name}
                onChange={(e) => {
                  const newCustomFields = ([] as Property[]).concat(
                    customFields,
                  );
                  newCustomFields[index].name = e.target.value;
                  setCustomFields(newCustomFields);
                }}
              />
            ),
          },
          {
            title: "Property",
            accessor: "property",
            render: (customField, index) => (
              <input
                type="text"
                placeholder="my_field.property"
                value={customField.property}
                onChange={(e) => {
                  const newCustomFields = ([] as Property[]).concat(
                    customFields,
                  );
                  newCustomFields[index].property = e.target.value;
                  setCustomFields(newCustomFields);
                }}
              />
            ),
          },
          {
            title: "Type",
            accessor: "type",
            render: (customField, index) => (
              <select
                value={customField.type}
                onChange={(e) => {
                  const newCustomFields = ([] as Property[]).concat(
                    customFields,
                  );
                  newCustomFields[index].type = e.target.value as PropertyType;
                  setCustomFields(newCustomFields);
                }}
              >
                {Object.values(PropertyType).map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            ),
          },
          {
            title: "Visibility",
            accessor: "visibility",
            render: (customField, index) => (
              <select
                value={customField.visibility ?? PropertyVisibility.Public}
                onChange={(e) => {
                  const newCustomFields = ([] as Property[]).concat(
                    customFields,
                  );
                  newCustomFields[index].visibility = e.target
                    .value as PropertyVisibility;
                  setCustomFields(newCustomFields);
                }}
              >
                {Object.values(PropertyVisibility).map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            ),
          },
          {
            title: "Editability",
            accessor: "editability",
            render: (customField, index) => (
              <select
                value={customField.editability ?? ProperyEditability.Always}
                onChange={(e) => {
                  const newCustomFields = ([] as Property[]).concat(
                    customFields,
                  );
                  newCustomFields[index].editability = e.target
                    .value as ProperyEditability;
                  setCustomFields(newCustomFields);
                }}
              >
                {Object.values(ProperyEditability).map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            ),
          },
          {
            title: "Required",
            accessor: "required",
            render: (customField, index) => (
              <input
                type="checkbox"
                checked={customField.required}
                onChange={(e) => {
                  const newCustomFields = ([] as Property[]).concat(
                    customFields,
                  );
                  newCustomFields[index].required = e.target.checked;
                  setCustomFields(newCustomFields);
                }}
              />
            ),
          },
          {
            title: "Unique",
            accessor: "unique",
            render: (customField, index) => (
              <input
                type="checkbox"
                checked={customField.unique}
                onChange={(e) => {
                  const newCustomFields = ([] as Property[]).concat(
                    customFields,
                  );
                  newCustomFields[index].unique = e.target.checked;
                  setCustomFields(newCustomFields);
                }}
              />
            ),
          },
          {
            title: "",
            accessor: "actions",
            render: (customField, index) => (
              <ActionIcon
                onClick={() => {
                  const newCustomFields = ([] as Property[]).concat(
                    customFields,
                  );
                  newCustomFields.splice(index, 1);
                  setCustomFields(newCustomFields);
                }}
              >
                <IconTrash />
              </ActionIcon>
            ),
          },
        ]}
      />
    </>
  );
};

export const constructCustomFieldsForm = ({
  defaultValue,
  label,
}: {
  defaultValue?: Array<Property> | null;
  label: string;
}) => ({
  type: "DYNAMIC" as const,
  label,
  yupConfig,
  defaultValue: defaultValue ?? [],
  element: (onChange: (...newValues: any[]) => void) => (
    <CustomFieldsForm onChange={onChange} defaultValue={defaultValue} />
  ),
});
