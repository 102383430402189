import { Code, List, Stack, Text, Title } from "@mantine/core";
import { Prism } from "@mantine/prism";

export const PlayerDocumentation = () => {
  return (
    <Stack>
      <Title>Players</Title>
      <Title order={2}>Manage Player Profiles through the API</Title>
      <Title order={3}>Create or update the current user Profile</Title>
      <Text>
        Using the following GraphQL mutation, it is possible to create or update
        the Player Profile of the current authenticated user (using
        Authorization token)
      </Text>
      <Text>
        Requires the following permission:{" "}
        <Code>organization:players:update:me</Code>
      </Text>
      <Prism language="graphql">
        {`
mutation {
  createOrUpdateMyPlayerProfile(
    input: {
      username: "test"
      description: "Test"
      customFields: [
          {
              value: "veryUsefulField@gmail.com",
              property: "email",
          }
      ]
    }
  ) {
    id
    username
    description
  }
}
            `}
      </Prism>
      <Text>Fields:</Text>
      <List>
        <List.Item>
          username: The username of the Player, it will be used to find the user
          through the searchPlayer endpoint
        </List.Item>
        <List.Item>
          description: The description that will be shown on the Player Profile
          page
        </List.Item>
        <List.Item>
          customFields: Custom fields are custom key-value set that you can
          attach to an account. They can be used through permissions to define
          advanced rules and through Tournament registration rules
        </List.Item>
      </List>

      <Title order={3}>Create a Player Profile</Title>
      <Text>
        Using the following GraphQL mutation, it is possible to create a new
        Player Profile named Jeff
      </Text>
      <Text>
        Requires the following permission:{" "}
        <Code>organization:players:create</Code>
      </Text>
      <Prism language="graphql">
        {`
mutation {
  createPlayer(
    input: {
      username: "test"
      description: "Test"
      ownerId: "optional"
      organizationCustomId: "optional"
      customFields: [
          {
              value: "veryUsefulField@gmail.com",
              property: "email",
          }
      ]
    }
  ) {
    id
    username
    description
    ownerId
    organizationCustomId
  }
}
            `}
      </Prism>
      <Text>Fields:</Text>
      <List>
        <List.Item>
          ownerId: Set the value of this field to the Account ID you want to
          link this Player Profile to. If you remove this field, the Profile
          will be set to Anonymous
        </List.Item>
        <List.Item>
          organizationCustomId: This field is optional and is used to attach the
          Player Profile to an external ID
        </List.Item>
      </List>

      <Title order={3}>Update a Player Profile</Title>
      <Text>
        Using the following GraphQL mutation, it is possible to update a Player
        Profile
      </Text>
      <Text>
        Requires the following permission:
        <Code>organization:players:update</Code>
      </Text>
      <Prism language="graphql">
        {`
mutation {
    updatePlayer(id: "playerId", customId: "organizationCustomId", input: {
        description: "newDesc"
        customFields: [{
            property: "updateAField"
            value: "updatedValue"
        }]
        username: "a new username for a cool person"
    }) {
        username
        description
        id
        organizationCustomId
        ownerId
    }
}
            `}
      </Prism>
      <Text>Fields: Same as Create</Text>
      <Title order={3}>Retrieve a Player Profile</Title>
      <Text>
        You can use two methods to retrieve users: Either through the player
        query, which will allow you to retrieve a player either through its id,
        organizationCustomId or ownerId.
      </Text>
      <Text>
        The following query allows you to retrieve the user Requires the
        following permission: <Code>organization:players:view</Code>
      </Text>
      <Prism language="graphql">
        {`
query {
    player(id: "playerId", ownerId: "or OwnerId", customId: "or organizationCustomId") {
        username
        ownerId
        organizationCustomId
        id
        description
    }
}`}
      </Prism>
      <Title order={3}>Search for a Player Profile</Title>
      <Text>
        The following query allows you to retrieve a list of 20 Player Profiles
        through a search
      </Text>
      <Text>
        The search system uses the first letters of the username to find the
        Player Profile. For example, using alex as an input will be able to find
        AlexMog and AlexLuke but not Mark
      </Text>
      <Text>The search mechanism is not case-sensitive</Text>
      <Text>
        Requires the following permission:{" "}
        <Code>organization:players:view</Code>
      </Text>
      <Prism language="graphql">
        {`
query {
    searchPlayer(username: "anExampleUsername") {
        username
        ownerId
        organizationCustomId
        id
        description
        profilePicUrl
    }
}`}
      </Prism>

      <Title order={2}>
        Register a new Match to recompute the Player Skill Rating
      </Title>
      <Text>
        The following query allows you to register a new match and recompute the
        Player Skill Rating of the players defined in the teams that plays the
        match
      </Text>
      <Text>
        Team order matters: The first team will be considered as the winner, the
        2nd is 2nd place and so on
      </Text>
      <Text>
        Requires permission:{" "}
        <Code>organization:skill-rating:register-match</Code>
      </Text>
      <Prism language={"graphql"}>
        {`
mutation: {
  organizationSkillRatingsRegisterMatch(
    organizationSkillRatingId: "theIdOfTheSkillRating"
    input: {
      teams: [
        {
          players: [
            "player_id_1",
            "player_id_2",
            "player_id_3",
          ],
        },
        {
          players: [
            "player_id_4",
            "player_id_5",
            "player_id_6",
          ],
        }
      ]
    }
  )
}
            `}
      </Prism>
    </Stack>
  );
};
