import { HeaderLink } from "./components/app-shell/header/Header";
import {
  IconApps,
  IconBrandPagekit,
  IconCalendarEvent,
  IconCreditCard,
  IconDeviceGamepad,
  IconReportAnalytics,
  IconSchema,
  IconSwords,
  IconTournament,
  IconTower,
  IconTrophy,
  IconUserCircle,
  IconUserShield,
} from "@tabler/icons-react";

export const ServiceLinks: HeaderLink[] = [
  {
    title: "Dashboard",
    path: "/",
  },
  {
    title: "Services",
    childrens: [
      {
        icon: IconUserCircle,
        title: "Organizations",
        description: "Organizations management",
        path: "/organizations",
      },
      {
        icon: IconUserShield,
        title: "IAM",
        description: "Organization Identity and Access management",
        path: "/iam",
      },
      {
        icon: IconApps,
        title: "Apps",
        description: "Apps & Webhooks management",
        path: "/apps",
      },
      {
        icon: IconCreditCard,
        title: "Limits & Subscriptions",
        description: "Limits and Subscriptions management",
        path: "/limits",
      },
      {
        icon: IconBrandPagekit,
        title: "Platform",
        description: "Platform content & pages management",
        path: "/platform",
      },
      {
        icon: IconSwords,
        title: "Players",
        description: "Players management",
        path: "/players",
      },
      {
        icon: IconCalendarEvent,
        title: "Events",
        description: "Events and ticketing management",
        path: "/events",
        disabled: true,
      },
      {
        icon: IconTournament,
        title: "Tournaments",
        description: "Tournaments management",
        path: "/tournaments",
      },
      {
        icon: IconTrophy,
        title: "Leaderboards",
        description: "Leaderboards management",
        path: "/leaderboards",
      },
      {
        icon: IconTower,
        title: "Clans",
        description: "Clans management",
        path: "/clans",
        disabled: true,
      },
      {
        icon: IconDeviceGamepad,
        title: "Gaming",
        description: "Gaming services",
        path: "/gaming",
        disabled: true,
      },
      {
        icon: IconReportAnalytics,
        title: "Analytics",
        description: "Analytics and Stats",
        path: "/analytics",
        disabled: true,
      },
      {
        icon: IconSchema,
        title: "Workflows",
        description: "Workflows and Automation",
        path: "/workflows",
        disabled: true,
      },
    ].sort((a, b) => a.title.localeCompare(b.title)),
  },
];
