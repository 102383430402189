import { createSubjectHook } from "../utils/observers";

const selectedOrganizationIdHook = createSubjectHook<string | null>(
  localStorage.getItem("organization-id") ?? null
);

export const useSelectedOrganizationId = selectedOrganizationIdHook[0];
export const setSelectedOrganizationId = (id: string | null, reload = true) => {
  if (localStorage.getItem("organization-id") === id) {
    return;
  }

  if (id === null) {
    localStorage.removeItem("organization-id");
  } else {
    localStorage.setItem("organization-id", id);
  }

  reload && window.location.reload();
  return selectedOrganizationIdHook[1](id);
};
export const selectedOrganizationIdSubject = selectedOrganizationIdHook[2];
