import { Code, Stack, Text, Title } from "@mantine/core";
import { Prism } from "@mantine/prism";

export const LeaderboardDocumentation = () => {
  return (
    <Stack>
      <Title>Leaderboards</Title>
      <Title order={2}>Register a player score through the API</Title>
      <Text>
        Using the following GraphQL mutation, it is possible to register a new
        score for a player on a leaderboard.
        <br />
        This method will automatically register the score on the current active
        season.
        <br />
        If no season is active, an error will be returned.
        <br />
        If a score already exists for the player on the leaderboard, it will be
        updated.
      </Text>
      <Text>
        Requires the following permission:{" "}
        <Code>organization:leaderboard:register_score</Code>
      </Text>
      <Prism language="graphql">
        {`
mutation {
    leaderboardRegisterPlayerScore(
      input: {
        score: 100
        playerId: "playerId"
        leaderboardId: "leaderboardId"
      }
    ) {
      rank
    }
}
            `}
      </Prism>
    </Stack>
  );
};
