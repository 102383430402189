import { ActionIcon, Button, Loader, Title, Tooltip } from "@mantine/core";
import { AutoDataTable } from "../../components/AutoDataTable";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { modals } from "@mantine/modals";
import { CreationForm } from "../../components/form/CreationForm";
import { LeaderboardSeason } from "../../../../gql/graphql";
import * as yup from "yup";
import { IconBucket } from "@tabler/icons-react";

const LeaderboardSeasonUpdateModal = ({
  seasonId,
  leaderboardId,
}: {
  seasonId?: string;
  leaderboardId: string;
}) => {
  const { loading, data } = useQuery<{
    leaderboardSeason: LeaderboardSeason;
  }>(
    gql`
      query ($id: ID!) {
        leaderboardSeason(id: $id) {
          id
          name
          start
          end
          startingScore
        }
      }
    `,
    { variables: { id: seasonId }, skip: !seasonId },
  );
  const [update] = useMutation(gql`
    mutation ($id: ID!, $input: UpdateLeaderboardSeasonInput!) {
      updateLeaderboardSeason(id: $id, input: $input) {
        id
      }
    }
  `);
  const [create] = useMutation(gql`
    mutation ($leaderboardId: ID!, $input: CreateLeaderboardSeasonInput!) {
      createLeaderboardSeason(leaderboardId: $leaderboardId, input: $input) {
        id
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      schemaDefinition={{
        name: {
          label: "Name",
          type: "INPUT",
          inputType: "text",
          yupConfig: yup.string().required(),
          defaultValue: data?.leaderboardSeason?.name,
        },
        start: {
          label: "Start",
          type: "INPUT",
          inputType: "date",
          yupConfig: yup.string().required(),
          defaultValue: data?.leaderboardSeason?.start,
        },
        end: {
          label: "End",
          type: "INPUT",
          inputType: "date",
          yupConfig: yup.string().required(),
          defaultValue: data?.leaderboardSeason?.end,
        },
        startingScore: {
          label: "Starting Score",
          type: "INPUT",
          inputType: "number",
          yupConfig: yup.number().required(),
          defaultValue: data?.leaderboardSeason?.startingScore,
        },
      }}
      onSubmit={async (datas) => {
        if (seasonId) {
          await update({
            variables: {
              id: seasonId,
              input: {
                name: datas.name,
                start: datas.start,
                end: datas.end,
                startingScore: datas.startingScore,
              },
            },
          });
        } else {
          await create({
            variables: {
              leaderboardId,
              input: {
                name: datas.name,
                start: datas.start,
                end: datas.end,
                startingScore: datas.startingScore,
              },
            },
          });
        }
        modals.closeAll();
      }}
    />
  );
};

export const LeaderboardSeasons = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const leaderboardId = useParams().leaderboardId!;

  return (
    <>
      <Title order={1}>Leaderboard Seasons</Title>
      <Button
        onClick={() => {
          modals.open({
            title: "Create new Season",
            children: (
              <LeaderboardSeasonUpdateModal leaderboardId={leaderboardId} />
            ),
            centered: true,
            onClose() {
              window.location.reload();
            },
          });
        }}
      >
        Create new Season
      </Button>
      <AutoDataTable
        deleteMutation={gql`
          mutation ($id: ID!) {
            deleteLeaderboardSeason(id: $id)
          }
        `}
        linkClick={(item) => {
          modals.open({
            title: "Update Season",
            children: (
              <LeaderboardSeasonUpdateModal
                leaderboardId={leaderboardId}
                seasonId={item.id}
              />
            ),
            onClose() {
              window.location.reload();
            },
            centered: true,
          });
        }}
        createButtonText={false}
        query={gql`
          query ($leaderboardId: ID!, $cursor: String, $count: Float) {
            leaderboardSeasons(
              leaderboardId: $leaderboardId
              page: { after: $cursor, first: $count }
            ) {
              nodes {
                id
                name
                start
                end
              }
              totalCount
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        `}
        queryVariables={{
          leaderboardId: leaderboardId,
        }}
        columns={[
          {
            title: "Name",
            accessor: "name",
          },
          {
            title: "Start",
            accessor: "",
            rawElement: (item: LeaderboardSeason) =>
              new Date(item.start).toLocaleString(),
          },
          {
            title: "End",
            accessor: "",
            rawElement: (item: LeaderboardSeason) =>
              new Date(item.end).toLocaleString(),
          },
          {
            title: "",
            accessor: "",
            rawElement: (item: LeaderboardSeason) => (
              <Tooltip
                label="Manage buckets"
                onClick={() => navigate(`${item.id}/buckets`)}
              >
                <ActionIcon>
                  <IconBucket />
                </ActionIcon>
              </Tooltip>
            ),
          },
        ]}
      />
    </>
  );
};
