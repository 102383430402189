import { gql, useMutation, useQuery } from "@apollo/client";
import {
  OrganizationAttachedApp,
  OrganizationGroups,
} from "../../../../../../gql/graphql";
import { CreationForm } from "../../../../components/form/CreationForm";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Loader, Text, Title } from "@mantine/core";
import { AutoDataTable } from "../../../../components/AutoDataTable";

export const AttachApplication = () => {
  const navigate = useNavigate();
  const [setOrganizationAttachedApp] = useMutation(gql`
    mutation (
      $groupId: ID!
      $appId: String!
      $permissions: [GroupPermissionInput!]
    ) {
      setOrganizationAttachedApp(
        input: { appId: $appId, groupId: $groupId, permissions: $permissions }
      ) {
        appId
      }
    }
  `);
  const { data, loading } = useQuery<{
    organizationGroups: OrganizationGroups;
  }>(gql`
    query {
      organizationGroups(page: { first: 100 }) {
        nodes {
          id
          name
        }
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title="Attach an application"
      schemaDefinition={{
        appId: {
          label: "Application ID",
          type: "INPUT",
          inputType: "text",
          subtext: "The application ID can be found in the 'apps' section",
          yupConfig: yup.string().required(),
        },
        groupId: {
          type: "SELECT",
          label: "Group",
          yupConfig: yup.string().required(),
          options:
            data?.organizationGroups.nodes.map((group) => ({
              label: group.name,
              value: group.id,
            })) ?? [],
        },
      }}
      onSubmit={async (datas) => {
        await setOrganizationAttachedApp({
          variables: {
            groupId: datas.groupId,
            appId: datas.appId,
          },
        }).then((data) => {
          if (data?.errors && data.errors.length > 0) {
            throw new Error(data.errors[0].message);
          }
        });
        navigate("..");
      }}
    />
  );
};

export const AttachedApplications = () => {
  return (
    <>
      <Title>Attached apps</Title>
      <Text>
        Here you can see the applications that are attached to your
        organization.
        <br />
        Attached applications are applications that are allowed to access your
        organization's resources depending on the permissions you have set.
        <br />
        This is useful is you want to authorize an application to access your
        organization's resources programmatically, for server-to-server
        communication for example.
      </Text>
      <AutoDataTable
        columns={[
          {
            accessor: "app.name",
            title: "Application",
            rawElement: (row: OrganizationAttachedApp) => (
              <>
                {row.app.name} ({row.appId})
              </>
            ),
          },
          {
            accessor: "group.name",
            title: "Group",
            rawElement: (row: OrganizationAttachedApp) => row.group?.name,
          },
        ]}
        linkClick={() => {}}
        idField={"appId"}
        createButtonText={"Attach an application"}
        deleteMutation={gql`
          mutation ($id: String!) {
            deleteOrganizationAttachedApp(input: { appId: $id })
          }
        `}
        query={gql`
          query ($count: Float!, $cursor: String) {
            organizationAttachedApps(page: { first: $count, after: $cursor }) {
              nodes {
                appId
                app {
                  name
                }
                group {
                  name
                }
              }
              totalCount
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        `}
      />
    </>
  );
};
