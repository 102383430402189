import {
  Badge,
  Card,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { gql, useQuery } from "@apollo/client";
import { Webhook } from "../../../../../../gql/graphql";
import { graphql } from "gql.tada";
import { useParams } from "react-router-dom";
import { IconFileText, IconTournament } from "@tabler/icons-react";

const WebhookQuery = graphql(`
  query webhookDashboard($id: ID!) {
    webhook(id: $id) {
      id
      description
      events
      url
      active
    }

    logs(types: [WEBHOOK_CALL_ERROR], page: { first: 10 }) {
      nodes {
        id
        createdAt
      }
    }
  }
`);

export const WebhookHome = () => {
  const webhookId = useParams().webhookId ?? "";
  const { data, loading } = useQuery(WebhookQuery, {
    variables: {
      id: webhookId,
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Stack spacing={0}>
      <Title>Webhook - {data?.webhook.url}</Title>
      <SimpleGrid cols={2}>
        <Card>
          <Card.Section>
            <Group position="apart" p={10}>
              <Group spacing={10}>
                <IconTournament />
                <Text size="md" weight={700}>
                  General
                </Text>
              </Group>
              <Badge color={data?.webhook.active ? "green" : "red"}>
                {data?.webhook.active ? "Enabled" : "Disabled"}
              </Badge>
            </Group>
          </Card.Section>
          <Text>{data?.webhook.description}</Text>
          <Text fw={700}>Events</Text>
          <Group spacing={0}>
            {data?.webhook.events.map((event: string) => (
              <Badge key={event}>{event}</Badge>
            ))}
          </Group>
        </Card>
        <Card>
          <Card.Section>
            <Group position="apart" p={10}>
              <Group spacing={10}>
                <IconFileText />
                <Text size="md" weight={700}>
                  Last errors
                </Text>
              </Group>
            </Group>
          </Card.Section>
          <Stack spacing={0}>
            {data?.logs.nodes.map((log) => (
              <Text color={"red"}>
                {new Date(log.createdAt as string).toLocaleString()}
              </Text>
            ))}
          </Stack>
        </Card>
      </SimpleGrid>
    </Stack>
  );
};
