import { BehaviorSubject, map, Observable } from "rxjs";
import { useEffect, useState } from "react";

export const useObservable = <T>(
  observable: Observable<T>,
  defaultValue: T
) => {
  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    const sub = observable.subscribe((newValue) => setValue(newValue));

    return () => sub.unsubscribe();
  }, [observable]);

  return value;
};

/**
 * Creates a subject hook which keep a value in memory.
 *
 * @param defaultValue
 * @return [hook, valueSetter]
 * hook: the hook to use to listen to the value.
 * valueSetter: a function to set the current value held by the subject.
 */
export const createSubjectHook = <T>(
  defaultValue: T
): [() => T, (newValue: T) => void, BehaviorSubject<T>] => {
  const subject = new BehaviorSubject<T>(defaultValue);
  const valueSetter = (newValue: T) => {
    subject.next(newValue);
  };

  /**
   * A hook keeping an internal state with the current value of the subject.
   *
   * @return [value, valueSetter]
   * value: the value held by the hook.
   * valueSetter: a function to set the current value held by the subject.
   */
  const useSubject = () => useObservable(subject, subject.getValue());
  return [useSubject as () => T, valueSetter, subject];
};

/**
 * Create a hook to manage a list
 * @param defaultValue
 * @param itemIdProvider a function to retrieve the ID from the object
 */
export const createListSubjectHook = <T, I extends string | number | symbol>(
  defaultValue: T[],
  itemIdProvider: (item: T) => I
) => {
  const transformValue = (value: T[]) => {
    return value.reduce((record, item) => {
      record[itemIdProvider(item)] = new BehaviorSubject(item);
      return record;
    }, {} as Record<I, BehaviorSubject<T>>);
  };

  const recordItems = new BehaviorSubject<Record<I, BehaviorSubject<T>>>(
    transformValue(defaultValue)
  );
  const listSetter = (newValue: T[]) => {
    recordItems.next(transformValue(newValue));
  };

  const addItem = (item: T) => {
    recordItems.next(
      Object.assign({}, recordItems.getValue(), {
        [itemIdProvider(item)]: item,
      })
    );
  };

  const updateItem = (item: Partial<T>, id?: I) => {
    if (id === undefined || id === null) {
      id = itemIdProvider(item as T);

      if (id === undefined || id === null) {
        throw new TypeError("Id not set");
      }
    }
    const itemSubject = recordItems.getValue()[id];
    if (!itemSubject) {
      throw new Error("Item not found");
    }

    itemSubject.next(Object.assign({}, itemSubject.getValue(), item));
  };

  const removeItem = (itemId: I) => {
    const record = recordItems.getValue();
    if (!record[itemId]) {
      throw new Error("Item not found");
    }

    delete record[itemId];
    recordItems.next(Object.assign({}, record));
  };

  const useList = () => {
    const [value, setValue] = useState<T[]>();

    useEffect(() => {
      const sub = recordItems
        .pipe(map((newValue) => Object.values(newValue) as T[]))
        .subscribe((newValue) => setValue(newValue));

      return () => sub.unsubscribe();
    }, []);

    return [value, listSetter];
  };

  const useRecord = () => useObservable(recordItems, recordItems.getValue());

  const useItem = (itemId: I) => {
    const item = recordItems.getValue()[itemId];
    if (!item) {
      throw new Error("Item not found");
    }

    const itemUpdate = (values: Partial<T>) => {
      updateItem(values, itemId);
    };

    const useSubject = () => useObservable(item, item.getValue());
    return [useSubject as () => T, itemUpdate];
  };

  return {
    useList,
    useRecord,
    useItem,
    setList: listSetter,
    addItem,
    updateItem,
    removeItem,
  };
};
