import { Button, Loader, Title } from "@mantine/core";
import { AutoDataTable } from "../../components/AutoDataTable";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { modals } from "@mantine/modals";
import { CreationForm } from "../../components/form/CreationForm";
import {
  LeaderboardScoreCalculationType,
  LeaderboardSeasonBucket,
} from "../../../../gql/graphql";
import * as yup from "yup";

const LeaderboardSeasonBucketUpdateModal = ({
  bucketId,
  seasonId,
}: {
  seasonId: string;
  bucketId?: string;
}) => {
  const { loading, data } = useQuery<{
    leaderboardSeasonBucket: LeaderboardSeasonBucket;
  }>(
    gql`
      query ($id: ID!) {
        leaderboardSeasonBucket(id: $id) {
          id
          name
          minScore
          scoreCalculationType
        }
      }
    `,
    { variables: { id: bucketId }, skip: !bucketId },
  );
  const [update] = useMutation(gql`
    mutation ($id: ID!, $input: UpdateLeaderboardSeasonBucketInput!) {
      updateLeaderboardSeasonBucket(id: $id, input: $input) {
        id
      }
    }
  `);
  const [create] = useMutation(gql`
    mutation ($seasonId: ID!, $input: CreateLeaderboardSeasonBucketInput!) {
      createLeaderboardSeasonBucket(seasonId: $seasonId, input: $input) {
        id
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  return (
    <CreationForm
      schemaDefinition={{
        name: {
          label: "Name",
          type: "INPUT",
          inputType: "text",
          yupConfig: yup.string().required(),
          defaultValue: data?.leaderboardSeasonBucket?.name,
        },
        minScore: {
          label: "Min Score",
          type: "INPUT",
          inputType: "number",
          yupConfig: yup.number().required(),
          defaultValue: data?.leaderboardSeasonBucket?.minScore,
        },
        scoreCalculationType: {
          label: "Score calculation type",
          type: "SELECT",
          options: Object.values(LeaderboardScoreCalculationType).map((v) => ({
            label: v,
            value: v,
          })),
          yupConfig: yup.string(),
          defaultValue: data?.leaderboardSeasonBucket?.scoreCalculationType,
        },
      }}
      onSubmit={async (datas) => {
        if (bucketId) {
          await update({
            variables: {
              id: bucketId,
              input: {
                name: datas.name,
                minScore: datas.minScore,
                scoreCalculationType: datas.scoreCalculationType,
              },
            },
          });
        } else {
          await create({
            variables: {
              seasonId,
              input: {
                name: datas.name,
                minScore: datas.minScore,
                scoreCalculationType: datas.scoreCalculationType,
              },
            },
          });
        }
        modals.closeAll();
      }}
    />
  );
};

export const LeaderboardSeasonBuckets = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const seasonId = useParams().seasonId!;

  return (
    <>
      <Title order={1}>Leaderboard Season Buckets</Title>
      <Button
        onClick={() => {
          modals.open({
            title: "Create new Season Bucket",
            children: (
              <LeaderboardSeasonBucketUpdateModal seasonId={seasonId} />
            ),
            centered: true,
            onClose() {
              window.location.reload();
            },
          });
        }}
      >
        Create new Season Bucket
      </Button>
      <AutoDataTable
        deleteMutation={gql`
          mutation ($id: ID!) {
            deleteLeaderboardSeasonBucket(id: $id)
          }
        `}
        linkClick={(item: LeaderboardSeasonBucket) => {
          modals.open({
            title: "Update Season Bucket",
            children: (
              <LeaderboardSeasonBucketUpdateModal
                seasonId={seasonId}
                bucketId={item.id}
              />
            ),
            onClose() {
              window.location.reload();
            },
            centered: true,
          });
        }}
        createButtonText={false}
        query={gql`
          query ($seasonId: ID!, $cursor: String, $count: Float) {
            leaderboardSeasonBuckets(
              seasonId: $seasonId
              page: { after: $cursor, first: $count }
            ) {
              nodes {
                id
                name
                minScore
                scoreCalculationType
              }
              totalCount
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        `}
        queryVariables={{
          seasonId,
        }}
        columns={[
          {
            title: "Name",
            accessor: "name",
          },
          {
            title: "Min score",
            accessor: "minScore",
            rawElement: (item) => <>{item.minScore}</>,
          },
          {
            title: "Score calculation type",
            accessor: "scoreCalculationType",
            rawElement: (item) => <>{item.scoreCalculationType}</>,
          },
        ]}
      />
    </>
  );
};
