import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ApolloProvider } from "@apollo/client";
import { client, IS_LOCAL } from "./apollo";
import { Notifications } from "@mantine/notifications";

Sentry.init({
  dsn: "https://08900a48197f4bd1ad3b50b823ed3267@o99030.ingest.sentry.io/4504865976680448",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: !IS_LOCAL,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ApolloProvider client={client}>
    <Notifications />
    <App />
  </ApolloProvider>
);
