import { useParams } from "react-router-dom";
import { Stack, Title } from "@mantine/core";
import { gql } from "@apollo/client";
import { Log } from "../../../../../../gql/graphql";
import { AutoDataTable } from "../../../../components/AutoDataTable";
import { Prism } from "@mantine/prism";

export const WebhookLogs = () => {
  const webhookId = useParams().webhookId ?? "";

  return (
    <Stack spacing={0}>
      <Title>Webhook Logs</Title>
      <AutoDataTable
        linkClick={(item: Log) => {}}
        createButtonText={false}
        query={gql`
          query ($webhookId: ID!, $cursor: String, $count: Float) {
            logs(
              types: [WEBHOOK_CALL, WEBHOOK_CALL_ERROR]
              page: { after: $cursor, first: $count }
              resourceId: $webhookId
            ) {
              nodes {
                id
                type
                createdAt
                data {
                  newValue
                }
              }
              totalCount
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        `}
        queryVariables={{
          webhookId,
        }}
        columns={[
          {
            title: "Id",
            accessor: "id",
          },
          {
            title: "Date",
            accessor: "date",
            rawElement: (item) => <>{new Date(item.createdAt).toISOString()}</>,
          },
          {
            title: "Entry",
            accessor: "log",
            rawElement: (item) => (
              <Prism language={"json"}>{JSON.stringify(item.data)}</Prism>
            ),
          },
        ]}
      />
    </Stack>
  );
};
